import { useQuery } from '@tanstack/react-query';

import { QueryKeys, useAuth } from 'queries';
import { StaleTime } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { TraitorGuess } from 'types/game.types';

const getTraitorGuesses = async (userId: string) => {
  const { data: response } = await apiService.getTraitorGuesses(userId);
  return response;
};

export const useTraitorGuesses = (
  config: UseCommonQueryOptions<TraitorGuess[]> = { enabled: true },
) => {
  const { userId } = useAuth();

  const id = userId;

  const { data, status, isInitialLoading, error } = useQuery(
    QueryKeys.traitorGuess.byId(id ?? ''),
    // We can disable it because the query isn't enabled without a valid userId
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => getTraitorGuesses(id!),
    {
      ...config,
      enabled: !!id && config.enabled,
      staleTime: config?.staleTime ?? StaleTime.FOREVER,
    },
  );

  return {
    traitorGuesses: data ?? [],
    status,
    isLoading: isInitialLoading,
    error,
  };
};
