/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import _reject from 'lodash/reject';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { EventAction } from 'constants/analytics';
import { useAnalytics } from 'hooks';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { gameActions } from 'store/game/game.slice';
import {
  Prediction,
  PredictionPostData,
  PredictionType,
} from 'types/game.types';

import { useAuth } from './useAuth';
import { useDeletePrediction } from './useDeletePrediction';
import { usePredictions } from './usePredictions';

const SEDUCED_PARTICIPANT_ID = 'SEDUCED_PARTICIPANT';

const postPrediction = async (userId: string, data: PredictionPostData) => {
  const { data: response } = await apiService.postPrediction(userId, data);
  return response;
};

export const useSeducedPrediction = (
  config: UseMutationOptions<
    Prediction,
    AxiosError,
    PredictionPostData,
    { previousPredictions: Prediction[] }
  > = {},
) => {
  const { trackEvent } = useAnalytics();
  const { userId } = useAuth();
  const { predictions } = usePredictions();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const isSeduced = !!predictions?.find(
    (p) => p.participantId === SEDUCED_PARTICIPANT_ID && p.isCorrect === null,
  );

  const { deletePrediction } = useDeletePrediction();

  const { mutate } = useMutation((data) => postPrediction(userId!, data), {
    ...config,
    onMutate: async (data) => {
      await queryClient.cancelQueries(QueryKeys.predictions.byId(userId!));

      const previousPredictions =
        queryClient.getQueryData<Prediction[]>(
          QueryKeys.predictions.byId(userId!),
        ) ?? [];

      queryClient.setQueryData<Prediction[] | undefined>(
        QueryKeys.predictions.byId(userId!),
        (old) => {
          const newPrediction = {
            ...data,
            isCorrect: null,
            userId: userId!,
            createdAt: new Date().toISOString(),
          };
          let finalData: Prediction[];

          if (!old || old.length === 0) {
            finalData = [newPrediction];
          } else {
            const filteredPredictions = _reject(
              old,
              (p) =>
                p.predictionType === data.predictionType &&
                (p.position === data.position ||
                  p.participantId === data.participantId),
            ).filter((p) => p.predictionType !== PredictionType.Murdered);

            finalData = [...filteredPredictions, newPrediction];
          }

          trackEvent({ eventAction: EventAction.SelectPredictionNoMurder });
          dispatch(gameActions.SET_PREDICTIONS(finalData));

          return finalData;
        },
      );

      return { previousPredictions };
    },
    onError: (_err, _id, ctx) => {
      if (ctx?.previousPredictions) {
        queryClient.setQueryData(
          QueryKeys.predictions.byId(userId!),
          ctx.previousPredictions,
        );
      }
    },
  });

  const setSeduced = useCallback(
    (seduced: boolean) => {
      if (userId) {
        if (seduced) {
          mutate({
            participantId: SEDUCED_PARTICIPANT_ID,
            position: 1,
            predictionType: PredictionType.Seduced,
          });
        } else {
          deletePrediction({
            participantId: SEDUCED_PARTICIPANT_ID,
            position: 1,
            predictionType: PredictionType.Seduced,
          });
        }
      }
    },
    [deletePrediction, mutate, userId],
  );

  return {
    isSeduced,
    setSeduced,
  };
};
