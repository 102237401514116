export enum EventAction {
  Play = 'dv-gamecenter',
  MoreInfo = 'dv-tutorial',
  ShareScore = 'dv-share-score',
  SharePredictions = 'dv-share-predictions',
  MiniCompetitionsShow = 'dv-mini-compo',
  ScoreBreakdown = 'dv-leaderboard-score-breakdown',
  TopPredictions = 'dv-top-predictions',
  TopPredictionsMurdered = 'dv-top-predictions-murder',
  TopPredictionsBanished = 'dv-top-predictions-banished',
  TopPredictionsSurvived = 'dv-top-predictions-survivor',

  StartChangeSuspects = 'dv-select-predictions',
  Leaderboard = 'dv-leaderboard',
  SelectPredictionMurder = 'dv-predictions-murder',
  SelectPredictionBanish = 'dv-predictions-banished',
  SelectPredictionSurvive = 'dv-predictions-survivor',
  SelectPredictionNoMurder = 'dv-predictions-no-murder',

  MiniCompetitionsCreate = 'dv-mini-compo-create',
  MiniCompetitionsConfirm = 'dv-mini-compo-confirm',
  MiniCompetitionsCopyLink = 'dv-mini-compo-link-copy',
  MiniCompetitionsShareLink = 'dv-mini-compo-link-share',
  MiniCompetitionsView = 'dv-mini-compo-view',
  MiniCompetitionsPlayerView = 'dv-mini-compo-player-view',
  MiniCompetitionsDeletePlayer = 'dv-mini-compo-player-delete',
  MiniCompetitionsJoin = 'dv-mini-compo-player-accept',

  BonusQuestion = 'dv-bonus-question',
  BonusQuestionAnswer = 'dv-bonus-question-confirm',

  VtmGoWatch = 'vtm-go-cpbd',
  VtmGoAddList = 'vtm-go-cpbd-add-to-my-list',
}

export const DATALAYER_NAME = 'dataLayer';
