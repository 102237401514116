import { darken } from 'polished';
import styled from 'styled-components';

export const SvgStyled20 = styled.svg`
  .cls-1 {
    fill: url(#linear-gradient);
  }
  .cls-2 {
    fill: #002e64;
  }
  .cls-3 {
    fill: #695632;
  }
  .cls-4,
  .cls-5,
  .cls-6 {
    fill: #fff;
  }
  .cls-7 {
    fill: #a9811b;
  }
  .cls-8 {
    fill: #dad1bf;
  }
  .cls-9 {
    fill: #beaa78;
  }
  .cls-10,
  .cls-11 {
    fill: #ddc895;
  }
  .cls-12 {
    fill: #ac9353;
  }
  .cls-13 {
    fill: #d62e00;
  }
  .cls-14 {
    fill: #836c3f;
  }
  .cls-15 {
    fill: #756038;
  }
  .cls-16,
  .cls-17 {
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  .cls-16,
  .cls-17,
  .cls-18,
  .cls-19,
  .cls-20 {
    fill: none;
  }
  .cls-16,
  .cls-17,
  .cls-19,
  .cls-20 {
    stroke-width: 0.62px;
  }
  .cls-16,
  .cls-20 {
    stroke: #fff;
  }
  .cls-17,
  .cls-19 {
    stroke: #beaa78;
  }
  .cls-18 {
    opacity: 0.1;
    stroke: #000;
    stroke-width: 0.75px;
  }
  .cls-18,
  .cls-19,
  .cls-20 {
    stroke-miterlimit: 10;
  }
  .cls-5 {
    font-family: Montserrat-Bold, Montserrat;
    font-size: 16px;
    font-weight: 700;
  }
  .cls-11,
  .cls-6 {
    fill-rule: evenodd;
  }
  .cls-21 {
    fill: #0d1a2a;
    opacity: 0.5;
  }

  .banished-bg {
    fill: #002e64;

    /* &:hover {
      fill: ${darken(0.1, '#002e64')};
    } */
  }

  .murderer-bg {
    fill: #d62e00;
  }

  .rank {
    fill: #fff;
    font-size: 16px;
    font-family: Montserrat, sans-serif;
    font-weight: 700;
  }
`;

export const SvgStyled18 = styled.svg`
  .cls-1,
  .cls-26 {
    fill: #fff;
  }

  .cls-2 {
    mask: url(#mask);
  }

  .cls-10,
  .cls-11,
  .cls-13,
  .cls-15,
  .cls-16,
  .cls-3,
  .cls-4,
  .cls-9 {
    fill: none;
  }

  .cls-3 {
    stroke: #836c3f;
    stroke-width: 204px;
  }

  .cls-5 {
    fill: #836c3f;
  }

  .cls-6 {
    mask: url(#mask-3);
  }

  .cls-7 {
    fill: #ac9353;
  }

  .cls-8 {
    fill: #beaa78;
  }

  .cls-10,
  .cls-11,
  .cls-9 {
    stroke: #beaa78;
  }

  .cls-11,
  .cls-13,
  .cls-15,
  .cls-9 {
    stroke-miterlimit: 10;
  }

  .cls-10,
  .cls-15,
  .cls-16,
  .cls-9 {
    stroke-width: 0.62px;
  }

  .cls-10,
  .cls-16 {
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .cls-11 {
    stroke-width: 0.62px;
  }

  .cls-12 {
    fill: rgb(35 30 30);
  }

  .cls-13 {
    stroke: #000;
    stroke-width: 0.75px;
    opacity: 0.1;
  }

  .cls-14 {
    fill: #dad1bf;
  }

  .cls-15,
  .cls-16 {
    stroke: #fff;
  }

  .cls-17 {
    fill: url(#linear-gradient);
  }

  .cls-18 {
    fill: #a9811b;
  }

  .cls-19 {
    fill: #756038;
  }

  .cls-20 {
    fill: #ddc895;
  }

  .rank {
    fill: #fff;
    font-size: 16px;
    font-family: Montserrat, sans-serif;
    font-weight: 700;
  }

  .banished-bg {
    fill: #002e64;

    /* &:hover {
      fill: ${darken(0.1, '#002e64')};
    } */
  }

  .banished-rank {
  }

  .cls-23 {
    clip-path: url(#clip-path);
  }

  .murderer-bg {
    fill: #d62e00;

    /* &:hover {
      fill: ${darken(0.1, '#d62e00')};
    } */
  }

  .murderer-rank {
  }

  .cls-25 {
    fill: #0d1a2a;
    opacity: 0.5;
  }

  .cls-26 {
    fill-rule: evenodd;
  }
`;
