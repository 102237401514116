import * as React from 'react';

function SvgIcoSurviveInactive(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 44" fill="none" {...props}>
      <g filter="url(#ico-survive-inactive_svg__filter0_d_131_196509)">
        <path d="M2 .811h28v28l-14.5 11-13.5-11v-28z" fill="#F3F3F3" />
        <path
          d="M14.741 15.068l1.879-3.884 1.879 3.884 4.643-3.776-2.06 7.967h-8.924l-2.06-7.967 4.643 3.776zM22.166 19.8H11.092v1.085h11.074V19.8z"
          fill="#A9811B"
        />
      </g>
      <defs>
        <filter
          id="ico-survive-inactive_svg__filter0_d_131_196509"
          x={0}
          y={0.811}
          width={32}
          height={43}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={1} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.667795 0 0 0 0 0.637751 0 0 0 0 0.590538 0 0 0 0.5 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_131_196509"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_131_196509"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
const MemoSvgIcoSurviveInactive = React.memo(SvgIcoSurviveInactive);
export default MemoSvgIcoSurviveInactive;
