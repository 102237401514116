import {
  Box,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import { FormattedMessage } from 'react-intl-phraseapp';

import imgPositionBronze from 'assets/png/img-position-bronze.png';
import imgPositionGold from 'assets/png/img-position-gold.png';
import imgPositionSilver from 'assets/png/img-position-silver.png';

import Loader from 'components/@common/Loader';
import { IcoArrowRight } from 'components/@icons';

import { Position } from './CompetitionUserListItem.style';

interface Props {
  name: string;
  score: number;
  position: number;
  currentUser?: boolean;
  onClick?: () => void;
  loading?: boolean;
}

const CompetitionUserListItem: React.VFC<Props> = ({
  name,
  score,
  position,
  currentUser = false,
  onClick,
  loading = false,
}) => {
  let positionImg = imgPositionSilver;
  if (position === 1) {
    positionImg = imgPositionGold;
  } else if (position === 2) {
    positionImg = imgPositionSilver;
  } else if (position === 3) {
    positionImg = imgPositionBronze;
  }

  return (
    <ListItem
      // Can't pass a true boolean - issue: https://github.com/mui/material-ui/issues/14971
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      button={!currentUser as any}
      onClick={!currentUser ? onClick : undefined}
    >
      <ListItemAvatar>
        <Position $position={position} $currentUser={currentUser}>
          <img
            alt={`positie ${position}`}
            src={positionImg}
            width="100%"
            height="100%"
          />
          <Box
            position="absolute"
            top={0}
            right={0}
            bottom={0}
            left={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h4">{position}</Typography>
          </Box>
        </Position>
      </ListItemAvatar>
      <ListItemText>
        <Typography
          variant="h3"
          color={currentUser ? 'primary' : 'textPrimary'}
        >
          {name}
        </Typography>
        <Box display="flex" color="global01.600" clone>
          <Typography variant="h6">
            <FormattedMessage
              id="mini_competition.detail.list.points.label"
              values={{ score: <FormattedNumber value={score} /> }}
            />
          </Typography>
        </Box>
      </ListItemText>
      {!currentUser && (
        <ListItemSecondaryAction>
          {loading ? (
            <Loader fontSize={32} variant="beige" />
          ) : (
            <IconButton color="primary" onClick={onClick} disabled={!!!onClick}>
              <IcoArrowRight fontSize={32} />
            </IconButton>
          )}
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default CompetitionUserListItem;
