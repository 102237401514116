import {
  Box,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import React from 'react';

import { IcoArrowRight } from 'components/@icons';

interface Props {
  title: string;
  members: string[];
  onClick?: () => void;
}

const CompetitionListItem: React.VFC<Props> = ({ title, members, onClick }) => {
  return (
    <ListItem button onClick={onClick}>
      <ListItemText>
        <Typography variant="h3" color="textPrimary">
          {title}
        </Typography>
        <Box display="flex" color="global01.600" clone>
          <Typography variant="h6">
            {members.length > 6
              ? `${members.slice(0, 5).join(', ')}, ...`
              : members.join(', ')}
          </Typography>
        </Box>
      </ListItemText>
      <ListItemSecondaryAction>
        <IconButton color="primary" onClick={onClick}>
          <IcoArrowRight fontSize={32} />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default CompetitionListItem;
