import { all, takeLatest } from 'redux-saga/effects';

import config from 'config/env';

import { inIframe } from 'utils/window.utils';

import { authActions } from './auth.slice';

function loginStart() {
  const loginUrl = `${config.LOGIN_URL}?redirect_uri=${
    inIframe() ? config.IFRAME_CLIENT_URL : config.CLIENT_URL
  }`;

  if (window.top && inIframe()) {
    window.top.location.href = loginUrl;
  } else {
    window.location.href = loginUrl;
  }
}

export default function* () {
  yield all([takeLatest(authActions.LOGIN_START, loginStart)]);
}
