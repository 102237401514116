import { Box } from '@material-ui/core';
import React from 'react';

import { ContentWrap, Line, TipSvg, Wrap } from './Tooltip.style';

interface Props {
  className?: string;
}

const Tooltip: React.FC<Props> = ({ className, children }) => {
  return (
    <Box boxShadow="0px 4px 8px rgba(0, 0, 0, 0.5)" clone>
      <Wrap className={className}>
        <TipSvg viewBox="0 0 100 45">
          <polygon points="50 0, 100 45, 0 45" />
        </TipSvg>
        <ContentWrap>{children}</ContentWrap>
        <Line />
      </Wrap>
    </Box>
  );
};

export default Tooltip;
