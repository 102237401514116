import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { QueryKeys } from 'queries';
import { StaleTime } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { queryClient } from 'services/react-query';
import { authActions } from 'store/auth/auth.slice';
import { gameActions } from 'store/game/game.slice';
import { isSystemReady } from 'store/system/system.selectors';
import { User } from 'types/user.types';

import { Routes } from 'pages/routes.constants';

const getUser = async () => {
  const { data: response } = await apiService.getUser();
  return response;
};

export const useAuth = (config: UseCommonQueryOptions<User | false> = {}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const systemReady = useSelector(isSystemReady);

  const { data, status, isInitialLoading, error } = useQuery(
    QueryKeys.user.me(),
    () => getUser(),
    {
      ...config,
      enabled: systemReady,
      onError: (e) => {
        if (e.response?.status === 401) {
          dispatch(gameActions.CLEAR_PREDICTIONS());
        }
        queryClient.setQueryData(QueryKeys.user.me(), false);
      },
      retry: false,
      onSuccess: (data) => {
        if (data && !data.firstName?.length) {
          history.replace(`${Routes.LoginCallback}?error=missing_first_name`);
        }
      },
      staleTime: StaleTime.FOREVER,
      cacheTime: StaleTime.FOREVER,
    },
  );

  const login = useCallback(() => {
    dispatch(authActions.LOGIN_START());
  }, [dispatch]);

  return {
    user: !!data ? data : undefined,
    isLoggedIn: !!data && !!data?.id,
    userId: !!data ? data?.id : undefined,
    login,
    status,
    isLoading: isInitialLoading,
    error,
  };
};
