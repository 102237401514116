import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from 'queries';
import { StaleTime } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { GameData } from 'types/game.types';

const getGameData = async () => {
  const { data: response } = await apiService.getGameData();
  return response;
};

export const useGameData = (config: UseCommonQueryOptions<GameData> = {}) => {
  const { data, status, isInitialLoading, error } = useQuery(
    QueryKeys.gameData.default(),
    () => getGameData(),
    {
      ...config,
      staleTime: config?.staleTime ?? StaleTime.FOREVER,
    },
  );

  return {
    gameData: data,
    status,
    isLoading: isInitialLoading,
    error,
  };
};
