import { Theme } from '@material-ui/core';

import { createCustomMuiTheme } from '../utils/create-mui-theme';
import * as colors from './colors';
import { createOverrides } from './overrides';

const CONFIG_TYPOGRAPHY = {
  BASE_FONT_SIZE: 16,
  RATIO_FACTOR: 1.15,
};

let THEME = createCustomMuiTheme({
  breakpoints: {
    values: {
      xs: 300,
      sm: 768,
      md: 908,
      lg: 1200,
      xl: 1200,
    },
  },

  mixins: {},

  palette: {
    primary: {
      main: colors.global01[500],
    },
    secondary: {
      main: colors.global01[400],
    },
    text: {
      primary: colors.neutral02[500],
      secondary: colors.neutral01[500],
    },
    ...colors,
  },

  shadows: [
    'none',
    ...new Array(24).fill('0px 2px 2px rgba(58, 58, 65, 0.15)'),
  ] as unknown as Theme['shadows'], // why: https://github.com/mui-org/material-ui/issues/8209

  shape: {
    borderRadius: 4,
    radius: (factor) => 4 * factor,
  },

  spacing: 4,

  typography: {
    fontSize: CONFIG_TYPOGRAPHY.BASE_FONT_SIZE,
    htmlFontSize: CONFIG_TYPOGRAPHY.BASE_FONT_SIZE,

    fontFamily: '"Butler", sans-serif',

    h1: {
      fontSize: 52,
      fontWeight: 700,
    },

    h2: {
      fontSize: 32,
      fontWeight: 700,
    },

    h3: {
      fontSize: 24,
      fontWeight: 700,
    },

    h4: {
      fontSize: 16,
      fontWeight: 700,
    },

    h5: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: 16,
      fontWeight: 700,
    },

    h6: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: 12,
      fontWeight: 700,
    },

    body1: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: 16,
      fontWeight: 400,
    },

    body2: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: 14,
      fontWeight: 400,
    },

    button: {
      fontWeight: 'normal',
    },
  },

  props: {
    MuiButtonBase: {
      disableRipple: true,
      disableTouchRipple: true,
    },
    MuiContainer: {
      disableGutters: true,
    },
    MuiLink: {
      underline: 'always',
    },
    MuiNativeSelect: {
      variant: 'filled',
    },
    MuiInput: {
      disableUnderline: true,
    },
    MuiTextField: {
      InputProps: {
        disableUnderline: true,
      },
      InputLabelProps: {
        disableAnimation: true,
        shrink: true,
      },
    },
  },
});

THEME.overrides = createOverrides(THEME);

export default THEME;
