import { Box } from '@material-ui/core';
import React from 'react';

interface Props {
  disableGutters?: boolean;
  innerRef?: React.RefObject<HTMLDivElement>;
}

const ModalScrollContent: React.FC<Props> = ({
  children,
  disableGutters = false,
  innerRef,
}) => (
  <Box
    ref={innerRef}
    overflow="auto"
    flex={1}
    py={5}
    px={disableGutters ? undefined : { xs: 6, sm: 14 }}
  >
    {children}
  </Box>
);

export default ModalScrollContent;
