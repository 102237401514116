import { Box, List } from '@material-ui/core';
import { ParentSize } from '@visx/responsive';
import _sortBy from 'lodash/sortBy';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { isEndOfGame } from 'utils/game.utils';

import { useAuth } from 'queries';
import { getCurrentSelecting, getViewType } from 'store/game/game.selectors';
import { Participant, ViewType } from 'types/game.types';

import BoardFrame from 'components/@board/BoardFrame';
import ScoreCard from 'components/@cards/ScoreCard';
import UserListItem from 'components/@list/UserListItem';
import Loading from 'components/@states/LoadingState';

import BoardModal from '../BoardModal';
import BoardUserPiece from '../BoardUserPiece';
import { RenderState, useRenderState } from '../useRenderState';
import { BoardContainer, RotatingBox } from './Desktop.style';

interface Props {
  participants?: Participant[];
  isLoading?: boolean;
}

const MobileBoard: React.VFC<Props> = ({ participants, isLoading = false }) => {
  const { isLoggedIn } = useAuth();
  const viewType = useSelector(getViewType);

  const endOfGame = isEndOfGame(participants);

  const sortedParticipants = useMemo(
    () =>
      participants
        ? _sortBy(
            participants,
            (p) => p.isBanished || p.isDisqualified || p.isMurdered,
          )
        : undefined,
    [participants],
  );
  const selecting = useSelector(getCurrentSelecting);

  const { component: renderStateComponent, state: renderState } =
    useRenderState(true);

  const renderBoard = renderState !== RenderState.Selecting;

  const renderList =
    isLoggedIn &&
    !!sortedParticipants &&
    renderState !== RenderState.Selecting &&
    renderState !== RenderState.GuessTraitors;

  const renderScoreCard =
    isLoggedIn &&
    renderState !== RenderState.Selecting &&
    renderState !== RenderState.GuessTraitors &&
    viewType !== ViewType.MemberGame &&
    !endOfGame;

  if (isLoading) {
    return (
      <Box ml={-3} mr={-3}>
        <BoardFrame
          hideFlag={isLoggedIn}
          variant={viewType === ViewType.MemberGame ? 'green' : 'blue'}
        >
          <Loading />
        </BoardFrame>
      </Box>
    );
  }

  return (
    <Box ml={-3} mr={-3}>
      <BoardFrame
        hideFlag={isLoggedIn}
        variant={viewType === ViewType.MemberGame ? 'green' : 'blue'}
      >
        {viewType === ViewType.Game || viewType === ViewType.MemberGame ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            mt={6}
            mb={isLoggedIn && !!selecting ? 0 : 6}
          >
            <Box
              px={isLoggedIn && !!selecting ? 0 : 4}
              width="100%"
              position="relative"
              zIndex={10}
              display="flex"
              flexDirection="column"
            >
              {renderScoreCard && (
                <Box
                  mt={-14}
                  mb={6}
                  display="flex"
                  maxWidth="95%"
                  mx="auto"
                  justifyContent="center"
                >
                  <ScoreCard />
                </Box>
              )}
              {renderBoard && participants ? (
                <ParentSize>
                  {({ width }) => (
                    <>
                      <Box
                        pt={1}
                        height={width}
                        width={width * 2}
                        overflow="hidden"
                        style={{ transform: `translateX(-${50 - 50 / 2}%)` }}
                      >
                        <RotatingBox>
                          <BoardContainer
                            $itemCount={participants.length}
                            $size={width * 2}
                          >
                            {participants.map((p, index) => (
                              <BoardUserPiece
                                key={index}
                                idx={index}
                                participant={p}
                                disableRotate
                                disableInteraction
                                disableAnimation
                              />
                            ))}
                          </BoardContainer>
                        </RotatingBox>
                      </Box>
                      <Box mt={`-${width / 2 - 40}px`}>
                        {renderStateComponent}
                      </Box>
                    </>
                  )}
                </ParentSize>
              ) : (
                <>{renderStateComponent}</>
              )}
            </Box>
            {renderList && (
              <Box width="100%" mt={5} position="relative">
                <List>
                  {sortedParticipants.map((p) => (
                    <UserListItem key={p.id} participant={p} />
                  ))}
                </List>
              </Box>
            )}
          </Box>
        ) : (
          <Box py={10} width="100%">
            <BoardModal />
          </Box>
        )}
      </BoardFrame>
    </Box>
  );
};

export default MobileBoard;
