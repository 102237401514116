import { ButtonBase } from '@material-ui/core';
import styled from 'styled-components';

export const ButtonBaseStyled = styled(ButtonBase)`
  position: relative;
  background: linear-gradient(151.46deg, #8aa327 7.75%, #9eb932 61.83%);
  box-shadow: 0px 0px 2.99899px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  min-height: 40px;
  width: 100%;
  transition: transform 0.2s ease-in-out;
  text-align: left;

  &:hover {
    transform: scale(1.02);
  }
`;
