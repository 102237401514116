import { Box, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';
import { useDispatch, useSelector } from 'react-redux';

import { useEditCompetitionName } from 'queries';
import { getViewParams } from 'store/game/game.selectors';
import { gameActions } from 'store/game/game.slice';
import { Store } from 'store/reducers';
import { ViewType } from 'types/game.types';

import Button from 'components/@common/Button';

import { ModalContainer, ModalScrollContent, ModalTitle } from '../Modal';

const MiniCompetitionEditNameModal: React.VFC = () => {
  const dispatch = useDispatch();
  const viewParams = useSelector((state: Store) =>
    getViewParams<{ id: string; name: string }>(state),
  );

  const { editName, isLoading } = useEditCompetitionName({
    onSuccess: () => handleBack(),
  });

  const [name, setName] = useState(viewParams?.name ?? '');

  const handleCreate = () => {
    if (viewParams?.id && name.length) {
      editName({ competitionId: viewParams.id, name });
    }
  };

  const handleBack = () => {
    dispatch(
      gameActions.SET_VIEW({
        type: ViewType.MiniCompetitionEdit,
        params: { id: viewParams?.id },
      }),
    );
  };

  return (
    <ModalContainer onBack={handleBack}>
      <ModalTitle>
        <FormattedMessage id="mini_competition.create.title" />
      </ModalTitle>
      <ModalScrollContent>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          maxWidth={300}
          mx="auto"
          mt={10}
        >
          <TextField
            label={<FormattedMessage id="mini_competition.edit.name.input" />}
            autoFocus
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            disabled={isLoading}
          />
          <Box mt={4} clone>
            <Button
              disabled={!!!name.length || name === viewParams?.name}
              variant="secondary"
              fullWidth
              onClick={handleCreate}
              loading={isLoading}
            >
              <FormattedMessage id="mini_competition.create.confirm.button" />
            </Button>
          </Box>
        </Box>
      </ModalScrollContent>
    </ModalContainer>
  );
};

export default MiniCompetitionEditNameModal;
