import { Box, Fade } from '@material-ui/core';
import React from 'react';

import imgFlag from 'assets/png/img-flag.png';

import { Background, Border, Wrap } from './BoardFrame.style';

interface Props {
  hideFlag?: boolean;
  variant?: 'blue' | 'green';
}

const BoardFrame: React.FC<Props> = ({
  children,
  hideFlag = false,
  variant = 'blue',
}) => {
  return (
    <Border>
      <Fade in={!hideFlag} timeout={hideFlag ? 500 : 0}>
        <Box
          position="absolute"
          zIndex={5}
          top={{ xs: -8, sm: -14 }}
          width={{ xs: 50, sm: 75 }}
          height={{ xs: 120, sm: 181 }}
          left="5%"
        >
          <img
            alt="de verraders vlag"
            src={imgFlag}
            width="100%"
            height="100%"
          />
        </Box>
      </Fade>
      <Wrap>
        <Background $variant={variant} />
        {children}
      </Wrap>
    </Border>
  );
};

export default BoardFrame;
