import * as React from 'react';

function SvgLogoVtmGo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 78 23" fill="none" {...props}>
      <path
        d="M49.46 8.888c-1.256-5.66-8.332-5.375-10.776-1.845-1.968-3.224-6.642-3.173-9.247-1.052V4.41h-5.184v9.563h-1.72c-1.545 0-2.556-.9-2.556-2.76v-1.79h2.66V4.41h-2.652V.47l-2.31 3.94-6.434-.006-2.445 4.171a.558.558 0 01-.953 0l-2.445-4.17H1.425c-.25 0-.496.064-.712.187-.217.123-.397.3-.522.514a1.387 1.387 0 000 1.404l6.891 11.757c.125.214.305.391.522.514a1.442 1.442 0 001.947-.514l5.184-8.844v2.33c0 4.19 2.529 7.22 7.123 7.22l7.572-.01v-7.148c0-2.536 4.615-3.357 4.615-.502v7.647h5.676v-7.145c0-2.481 4.174-3.319 4.36-.686v7.84h5.64V11.39a9.909 9.909 0 00-.26-2.502z"
        fill="#fff"
      />
      <path
        d="M64.376 5.005A7.651 7.651 0 0060.584 4C56.424 4 53 7.385 53 11.5c0 4.114 3.423 7.5 7.584 7.5a7.588 7.588 0 004.913-1.808c2.377 1.997 5.882 2.424 8.722.803 3.602-2.057 4.855-6.682 2.775-10.245-2.08-3.563-6.756-4.803-10.36-2.745l1.773 3.035a4.05 4.05 0 011.99-.538c2.233-.016 4.056 1.76 4.072 3.969.016 2.208-1.78 4.01-4.013 4.027-2.232.016-4.056-1.76-4.072-3.969V9.873h-6.831v3.255h3.415v1.595c-.66.48-1.472.768-2.355.775-2.232.016-4.056-1.76-4.072-3.968-.016-2.208 1.78-4.012 4.013-4.028a4.054 4.054 0 012.05.54l1.772-3.037z"
        fill="#F0F"
      />
    </svg>
  );
}
const MemoSvgLogoVtmGo = React.memo(SvgLogoVtmGo);
export default MemoSvgLogoVtmGo;
