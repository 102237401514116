/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import _reject from 'lodash/reject';
import { useCallback } from 'react';

import { QueryKeys, useAuth } from 'queries';
import { apiService } from 'services';
import { TraitorGuess, TraitorGuessPostData } from 'types/game.types';

const postTraitorGuess = async (userId: string, data: TraitorGuessPostData) => {
  const { data: response } = await apiService.postTraitorGuess(userId, data);
  return response;
};

export const useCreateTraitorGuess = (
  config: UseMutationOptions<
    TraitorGuess,
    AxiosError,
    TraitorGuessPostData,
    { previousGuesses: TraitorGuess[] }
  > = {},
) => {
  const queryClient = useQueryClient();
  const { userId } = useAuth();

  const { mutate, isLoading } = useMutation(
    (data) => postTraitorGuess(userId!, data),
    {
      ...config,
      onMutate: async (data) => {
        await queryClient.cancelQueries(QueryKeys.traitorGuess.byId(userId!));

        const previousGuesses =
          queryClient.getQueryData<TraitorGuess[]>(
            QueryKeys.traitorGuess.byId(userId!),
          ) ?? [];

        queryClient.setQueryData<TraitorGuess[] | undefined>(
          QueryKeys.traitorGuess.byId(userId!),
          (old) => {
            const newGuess = {
              ...data,
            };
            let finalData: TraitorGuess[];

            if (!old || old.length === 0) {
              finalData = [newGuess];
            } else {
              let filteredGuesses = _reject(
                old,
                (p) =>
                  p.position === data.position ||
                  p.participantId === data.participantId,
              );

              finalData = [...filteredGuesses, newGuess];
            }

            return finalData;
          },
        );

        return { previousGuesses };
      },
      onError: (_err, _id, ctx) => {
        if (ctx?.previousGuesses) {
          queryClient.setQueryData(
            QueryKeys.traitorGuess.byId(userId!),
            ctx.previousGuesses,
          );
        }
      },
    },
  );

  const handleCreateGuess = useCallback(
    (data: TraitorGuessPostData) => {
      if (userId) {
        mutate(data);
      }
    },
    [mutate, userId],
  );

  return {
    createTraitorGuess: handleCreateGuess,
    isLoading,
  };
};
