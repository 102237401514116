import * as React from 'react';

function SvgIcoSurvive(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 36 47" fill="none" {...props}>
      <g filter="url(#ico-survive_svg__filter0_d_110_155018)">
        <path
          d="M4 4h28v28L17.5 43 4 32V4z"
          fill="url(#ico-survive_svg__paint0_linear_110_155018)"
        />
        <path
          d="M4 4h28v28L17.5 43 4 32V4z"
          fill="url(#ico-survive_svg__paint1_linear_110_155018)"
        />
      </g>
      <path
        d="M16.252 17.939l1.879-3.884 1.879 3.884 4.642-3.776-2.06 7.967H13.67l-2.06-7.967 4.643 3.776zM23.678 22.672H12.604v1.084h11.074v-1.084z"
        fill="#BEAA78"
      />
      <defs>
        <linearGradient
          id="ico-survive_svg__paint0_linear_110_155018"
          x1={12.303}
          y1={8.43}
          x2={11.008}
          y2={6.615}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D7B55D" />
          <stop offset={1} stopColor="#A9811B" />
        </linearGradient>
        <linearGradient
          id="ico-survive_svg__paint1_linear_110_155018"
          x1={10.956}
          y1={6.617}
          x2={30.918}
          y2={35.534}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#FEEDC0" />
        </linearGradient>
        <filter
          id="ico-survive_svg__filter0_d_110_155018"
          x={0}
          y={0}
          width={36}
          height={47}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.65 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_110_155018"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_110_155018"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
const MemoSvgIcoSurvive = React.memo(SvgIcoSurvive);
export default MemoSvgIcoSurvive;
