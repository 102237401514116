import { QueryKey } from '@tanstack/react-query';

enum Keys {
  User = 'user',
  Participants = 'participants',
  GameData = 'game-data',
  Predictions = 'predictions',
  ScoreBreakdown = 'score-breakdown',
  Leaderboard = 'leaderboard',
  BonusQuestion = 'bonus-question',
  Competition = 'competition',
  Stats = 'stats',
  TraitorGuess = 'traitor-guess',
}

export const QueryKeys = {
  user: {
    me: (): QueryKey => [Keys.User],
  },
  participants: {
    default: (): QueryKey => [Keys.Participants],
  },
  stats: {
    default: (): QueryKey => [Keys.Stats],
  },
  gameData: {
    default: (): QueryKey => [Keys.GameData],
  },
  predictions: {
    all: (): QueryKey => [Keys.Predictions],
    byId: (id: string): QueryKey => [Keys.Predictions, id],
  },
  traitorGuess: {
    all: (): QueryKey => [Keys.TraitorGuess],
    byId: (id: string): QueryKey => [Keys.TraitorGuess, id],
  },
  scoreBreakDown: {
    all: (): QueryKey => [Keys.ScoreBreakdown],
    byId: (id: string): QueryKey => [Keys.ScoreBreakdown, id],
  },
  leaderboard: {
    all: (): QueryKey => [Keys.Leaderboard],
    user: (userId: string): QueryKey => [Keys.Leaderboard, userId],
  },
  competitions: {
    all: (): QueryKey => [Keys.Competition],
    byId: (id: string): QueryKey => [Keys.Competition, id],
  },
  bonus: {
    default: (): QueryKey => [Keys.BonusQuestion],
  },
};
