import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  Prediction,
  PredictionType,
  TraitorGuess,
  ViewType,
} from 'types/game.types';

import { getNextSelecting, getPrevSelecting } from './game.utils';

export interface GameState {
  predictions: {
    banished: Prediction[];
    murdered: Prediction[];
    survivor: Prediction[];
  };
  memberPredictions: {
    [key: string]: {
      banished: Prediction[];
      murdered: Prediction[];
      survivor: Prediction[];
    };
  };
  selecting?: {
    type: PredictionType;
    position: number;
  };
  traitorsGuesses?: TraitorGuess[];
  selectingTraitors?: {
    position: number;
  };
  view?: {
    type: ViewType;
    params?: { [key: string]: unknown };
  };
  surivorConfirm?: {
    participantId: string;
    position: number;
    delete: boolean;
  };
}

const initialState: GameState = {
  memberPredictions: {},
  predictions: {
    banished: [],
    murdered: [],
    survivor: [],
  },
  view: { type: ViewType.Game },
};

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    SET_TRAITORS_SELECTING: (
      state,
      { payload }: PayloadAction<GameState['selectingTraitors']>,
    ) => {
      state.view = { type: ViewType.Game };
      state.selectingTraitors = payload;
    },
    REQUEST_SURVIVOR_CONFIRM: (
      state,
      { payload }: PayloadAction<GameState['surivorConfirm']>,
    ) => {
      state.surivorConfirm = payload;
    },
    COMPLETE_SURVIVOR_CONFIRM: (state) => {
      state.surivorConfirm = undefined;
    },
    SET_VIEW: (state, { payload }: PayloadAction<GameState['view']>) => {
      state.view = payload;
    },
    SET_SELECTING: (
      state,
      { payload }: PayloadAction<GameState['selecting']>,
    ) => {
      state.view = { type: ViewType.Game };
      state.selecting = payload;
    },
    SELECT_NEXT: (state) => {
      state.selecting = getNextSelecting(state.selecting);
    },
    SELECT_PREV: (state) => {
      state.selecting = getPrevSelecting(state.selecting);
    },
    SET_PREDICTIONS: (state, { payload }: PayloadAction<Prediction[]>) => {
      state.predictions = {
        banished: payload.filter(
          (p) => p.predictionType === PredictionType.Banished,
        ),
        murdered: payload.filter(
          (p) => p.predictionType === PredictionType.Murdered,
        ),
        survivor: payload.filter(
          (p) => p.predictionType === PredictionType.Survivor,
        ),
      };
    },
    CLEAR_PREDICTIONS: (state) => {
      state.predictions = {
        banished: [],
        murdered: [],
        survivor: [],
      };
    },
    SET_MEMBER_PREDICTIONS: (
      state,
      {
        payload,
      }: PayloadAction<{ memberId: string; predictions: Prediction[] }>,
    ) => {
      state.memberPredictions = {
        ...state.memberPredictions,
        [payload.memberId]: {
          banished: payload.predictions.filter(
            (p) => p.predictionType === PredictionType.Banished,
          ),
          murdered: payload.predictions.filter(
            (p) => p.predictionType === PredictionType.Murdered,
          ),
          survivor: payload.predictions.filter(
            (p) => p.predictionType === PredictionType.Survivor,
          ),
        },
      };
    },
  },
});

export const { actions: gameActions } = gameSlice;

export default gameSlice.reducer;
