import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';
import { useDispatch, useSelector } from 'react-redux';

import { EventAction } from 'constants/analytics';
import { useAnalytics } from 'hooks';
import { getFallbackSelecting } from 'store/game/game.selectors';
import { gameActions } from 'store/game/game.slice';

import Button from 'components/@common/Button';
import StatsButton from 'components/@common/StatsButton';
import { IcoBanish, IcoMurder, IcoSurvive } from 'components/@icons';

const NoSelectionState: React.VFC = () => {
  const { trackEvent } = useAnalytics();
  const dispatch = useDispatch();
  const selection = useSelector(getFallbackSelecting);

  const handleStartSelecting = () => {
    trackEvent({ eventAction: EventAction.StartChangeSuspects });
    dispatch(gameActions.SET_SELECTING(selection));
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="100%"
      width="100%"
    >
      <Typography variant="h3" color="textSecondary">
        <FormattedMessage id="game.no_selection.title" />
      </Typography>
      <Box display="flex" my={4}>
        <Box display="flex" flexDirection="column" mr={4}>
          <IcoMurder fontSize={40} />
          <Box clone my={4}>
            <IcoBanish fontSize={40} />
          </Box>
          <IcoSurvive fontSize={44} />
        </Box>
        <Box
          height="auto"
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          textAlign="left"
        >
          <Typography variant="body2" color="textSecondary">
            <FormattedMessage
              id="game.start.who.murder"
              values={{
                b: (v: string) => <b>{v}</b>,
              }}
            />
          </Typography>
          <Box my={4}>
            <Typography variant="body2" color="textSecondary">
              <FormattedMessage
                id="game.start.who.banish"
                values={{
                  b: (v: string) => <b>{v}</b>,
                }}
              />
            </Typography>
          </Box>
          <Typography variant="body2" color="textSecondary">
            <FormattedMessage
              id="game.start.who.survive"
              values={{
                b: (v: string) => <b>{v}</b>,
              }}
            />
          </Typography>
        </Box>
      </Box>
      <Box mb={4}>
        <StatsButton />
      </Box>
      <Button variant="primary" onClick={handleStartSelecting}>
        <FormattedMessage id="game.no_selection.play.button" />
      </Button>
    </Box>
  );
};

export default NoSelectionState;
