import { Box, Link, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';
import { useTheme } from 'styled-components';

import config from 'config';

import { inIframe } from 'utils/window.utils';

import { EventAction } from 'constants/analytics';
import { useAnalytics } from 'hooks';

import { IcoLink, IcoShare } from 'components/@icons';
import ShareLinkButton from 'components/@share/ShareLinkButton';

interface Props {
  id: string;
}

const ShareLinkInfo: React.VFC<Props> = ({ id }) => {
  const { trackEvent } = useAnalytics();
  const [open, setOpen] = React.useState(false);

  const theme = useTheme();
  const url = `${
    inIframe() ? config.IFRAME_CLIENT_URL : config.CLIENT_URL
  }?id=${id}`;

  const handleCopyClipboard = () => {
    trackEvent({ eventAction: EventAction.MiniCompetitionsCopyLink });
    navigator.clipboard.writeText(url);
    setOpen(true);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      width="100%"
    >
      <Typography variant="h3">
        <FormattedMessage id="mini_competition.create.share.title" />
      </Typography>
      <Box pt={3} pb={6}>
        <Typography variant="body1">
          <FormattedMessage id="mini_competition.create.share.description" />
        </Typography>
      </Box>
      <Box
        p={3}
        border={`1px solid ${theme.palette.global01[600]}`}
        maxWidth="100%"
        color="global01.600"
      >
        <Typography variant="body1">
          <b>{url}</b>
        </Typography>
      </Box>
      <Box mt={6} color="global01.500" display="flex">
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={() => setOpen(false)}
          open={open}
          title={
            <FormattedMessage id="mini_competition.create.share.copy_link.tooltip" />
          }
        >
          <Link component="button" onClick={handleCopyClipboard}>
            <IcoLink fontSize={14} style={{ marginRight: 4 }} />
            <FormattedMessage id="mini_competition.create.share.copy_link.button" />
          </Link>
        </Tooltip>
        <Box ml={6}>
          <ShareLinkButton variant="url" url={url} color="primary">
            <IcoShare fontSize={14} style={{ marginRight: 4 }} />
            <FormattedMessage id="mini_competition.create.share.button" />
          </ShareLinkButton>
        </Box>
      </Box>
    </Box>
  );
};

export default ShareLinkInfo;
