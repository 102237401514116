import { API, graphqlOperation } from '@aws-amplify/api';
import * as Sentry from '@sentry/browser';
import humps from 'humps';
import _get from 'lodash/get';
import Observable, { ZenObservable } from 'zen-observable-ts';

import { transformDisqualified } from 'utils/game.utils';

import { QueryKeys } from 'queries';
import { queryClient } from 'services/react-query';
import reduxStore from 'store';
import { getPredictionsForParticipant } from 'store/game/game.selectors';
import { gameActions } from 'store/game/game.slice';
import {
  GameData,
  Participant,
  Prediction,
  PredictionType,
} from 'types/game.types';
import { User } from 'types/user.types';

import awsconfig from './aws-config';
import { subscribeGameData } from './subscriptions';

enum EventType {
  Survivor = 'SURVIVOR',
  Banished = 'BANISHED',
  Murdered = 'MURDERED',
  Seduced = 'SEDUCED',
  Traitor = 'TRAITOR',
  DeathRow = 'DEATH_ROW',
  SurvivorRemoved = 'SURVIVOR_REMOVED',
  BanishedRemoved = 'BANISHED_REMOVED',
  MurderedRemoved = 'MURDERED_REMOVED',
  SeducedRemoved = 'SEDUCED_REMOVED',
  TraitorRemoved = 'TRAITOR_REMOVED',
  DeathRowRemoved = 'DEATH_ROW_REMOVED',
  GameSettings = 'GAME_SETTINGS',
}

type BroadCast = {
  eventType: EventType;
  payload: string;
};

const RANDOM_API_DELAY = 10000;

class AwsService {
  private subscription: ZenObservable.Subscription | undefined;

  constructor() {
    if (awsconfig.API.aws_appsync_apiKey.length) {
      API.configure(awsconfig.API);
      this.subscribe();
    }
  }

  public subscribe() {
    if (this.subscription) {
      return;
    }

    const sub = API.graphql(graphqlOperation(subscribeGameData));
    if (sub instanceof Observable) {
      this.subscription = sub.subscribe({
        error: (error) => {
          Sentry.withScope((scope) => {
            scope.setExtras({
              name: 'aws-appsync',
              errorMessage: _get(error, 'error.errors[0].message'),
              errorJson: JSON.stringify(error),
            });
            Sentry.captureException(error);
          });
        },
        next: (payload: {
          value: { data: { onBroadcastEvent: BroadCast } };
        }) => {
          try {
            const item = payload.value.data.onBroadcastEvent;
            const itemPayload = humps.camelizeKeys(
              JSON.parse(
                Buffer.from(item.payload, 'base64').toString('binary'),
              ),
            ) as unknown as { [key: string]: string };

            if (item.eventType === EventType.GameSettings) {
              queryClient.setQueryData<GameData>(
                QueryKeys.gameData.default(),
                (old) => {
                  return { ...old, ...itemPayload } as GameData;
                },
              );
            } else {
              const { participantId } = itemPayload;
              if (participantId) {
                if (
                  item.eventType === EventType.Banished ||
                  item.eventType === EventType.Murdered ||
                  item.eventType === EventType.Survivor ||
                  item.eventType === EventType.Seduced
                ) {
                  setTimeout(() => {
                    // Invalidate the score-breakdown so the user gets a new score
                    queryClient.invalidateQueries(
                      QueryKeys.scoreBreakDown.all(),
                    );

                    // Invalidate the leaderboard because the users position might have changed
                    queryClient.invalidateQueries(QueryKeys.leaderboard.all());

                    // Update the participant state
                    this.handleParticipantEvent(participantId, item.eventType);
                  }, Math.random() * RANDOM_API_DELAY);
                }

                if (
                  item.eventType === EventType.BanishedRemoved ||
                  item.eventType === EventType.MurderedRemoved ||
                  item.eventType === EventType.SurvivorRemoved
                ) {
                  setTimeout(() => {
                    // Invalidate the predictions if it was an removed event
                    queryClient.invalidateQueries(QueryKeys.predictions.all());
                  }, Math.random() * RANDOM_API_DELAY);
                }
              }
            }
          } catch (error) {
            Sentry.withScope((scope) => {
              scope.setExtras({ name: 'aws-appsync-catch' });
              Sentry.captureException(error);
            });
          }
        },
      });
    }
  }

  private handleParticipantEvent(participantId: string, eventType: EventType) {
    if (participantId) {
      switch (eventType) {
        case EventType.DeathRow:
        case EventType.DeathRowRemoved:
          this.editParticipant(participantId, {
            isDeathRow: eventType === EventType.DeathRow,
          });
          break;
        case EventType.Banished:
        case EventType.BanishedRemoved:
          this.editParticipant(participantId, {
            isBanished: eventType === EventType.Banished,
          });
          break;
        case EventType.Murdered:
        case EventType.MurderedRemoved:
          this.editParticipant(participantId, {
            isMurdered: eventType === EventType.Murdered,
          });
          break;
        case EventType.Survivor:
        case EventType.SurvivorRemoved:
          this.editParticipant(participantId, {
            isSurvivor: eventType === EventType.Survivor,
          });
          break;
        case EventType.Seduced:
        case EventType.SeducedRemoved:
          this.editParticipant(participantId, {
            isSeduced: eventType === EventType.Seduced,
          });
          break;
        case EventType.Traitor:
        case EventType.TraitorRemoved:
          this.editParticipant(participantId, {
            isTraitor: eventType === EventType.Traitor,
          });
          break;
      }
    }

    // If the user has predictions for the participant we should remove them. Because the backend will do the same
    // We don't want extra calls to the BE for this
    if (
      eventType === EventType.Banished ||
      eventType === EventType.Murdered ||
      eventType === EventType.Survivor
    ) {
      this.updatePredictions(participantId, eventType);
    }
  }

  private updatePredictions(participantId: string, eventType: EventType) {
    const state = reduxStore.store.getState();
    const user = queryClient.getQueryData<User>(QueryKeys.user.me());
    const predictions = getPredictionsForParticipant(state, participantId);

    if (
      user?.id &&
      (predictions.murdered || predictions.banished || predictions.survivor)
    ) {
      const predictions = queryClient.setQueryData<Prediction[] | undefined>(
        QueryKeys.predictions.byId(user.id),
        (old) => {
          if (!old || old.length === 0) {
            return;
          } else {
            const filteredPredictions = old.filter(
              (p) =>
                p.participantId !== participantId ||
                (p.predictionType === PredictionType.Survivor &&
                  eventType === EventType.Survivor),
            );

            return filteredPredictions;
          }
        },
      );

      if (predictions) {
        reduxStore.store.dispatch(gameActions.SET_PREDICTIONS(predictions));
      }
    }
  }

  private editParticipant(
    participantId: string,
    partialParticipant: Partial<Participant>,
  ) {
    queryClient.setQueryData<Participant[]>(
      QueryKeys.participants.default(),
      (old) => {
        if (!old) {
          return [];
        } else {
          const edited = [...old];
          const idx = old.findIndex((p) => p.id === participantId);

          if (idx >= 0) {
            const participant = { ...edited[idx], ...partialParticipant };
            edited[idx] = transformDisqualified(participant);
          }
          return edited;
        }
      },
    );
  }

  public unsubscribe() {
    this.subscription?.unsubscribe();
  }
}

export default AwsService;
