import { Link } from '@material-ui/core';
import styled from 'styled-components';

export const LinkStyled = styled(Link).attrs({ underline: 'none' })`
  position: relative;
  background-color: #000;
  border: 1px solid #fff;
  border-radius: 40px;
  height: 40px;
  text-align: left;
  font-family: 'Futura';
  font-weight: 700;
  color: #fff;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 10px;

  &:hover {
    color: #fff;
  }
`;
