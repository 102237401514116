import _reject from 'lodash/reject';

import {
  Breakdown,
  GameData,
  Participant,
  PredictionType,
  ScoreBreakdownEventType,
} from 'types/game.types';

export const isEndOfGame = (participants?: Participant[]) => {
  return participants
    ? _reject(
        participants,
        (p) => p.isBanished || p.isMurdered || p.isDisqualified || p.isSurvivor,
      ).length === 0
    : false;
};

export const isPredictionClosed = (
  gameData: GameData,
  type: PredictionType,
) => {
  return (
    (type === PredictionType.Murdered &&
      !gameData.predictionsMurderedAreOpen) ||
    (type === PredictionType.Banished &&
      !gameData.predictionsBanishedAreOpen) ||
    (type === PredictionType.Survivor && !gameData.predictionsSurvivorAreOpen)
  );
};

const DISQUALIFIED_IDS: string[] = [];

export const transformDisqualified = (
  participant: Participant,
): Participant => {
  if (
    DISQUALIFIED_IDS.includes(participant.id) &&
    (participant.isBanished || participant.isMurdered)
  ) {
    return {
      ...participant,
      isBanished: false,
      isSurvivor: false,
      isMurdered: false,
      isDisqualified: true,
    };
  }
  return { ...participant, isDisqualified: false };
};

export const transformDisqualifiedScore = (breakdown: Breakdown): Breakdown => {
  if (
    breakdown.type !== ScoreBreakdownEventType.BonusQuestion &&
    breakdown.type !== ScoreBreakdownEventType.PollQuestion
  ) {
    if (DISQUALIFIED_IDS.includes(breakdown.event.participantId)) {
      return { ...breakdown, type: ScoreBreakdownEventType.Disqualified };
    }
  }

  return breakdown;
};
