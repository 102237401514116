import { ShareResponse } from 'types/share.types';

import RestApiService from './';

export default {
  sharePredictions(this: RestApiService, userId: string) {
    return this.api.post<ShareResponse>(`/users/${userId}/predictions/share`);
  },
  shareLeaderboard(this: RestApiService, userId: string) {
    return this.api.post<ShareResponse>(`/users/${userId}/leaderboard/share`);
  },
  shareCompetitionLeaderboard(this: RestApiService, competitionId: string) {
    return this.api.post<ShareResponse>(
      `/competitions/${competitionId}/leaderboard/share`,
    );
  },
};
