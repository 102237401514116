import { ButtonBaseProps } from '@material-ui/core';
import React from 'react';

import ButtonOutlined from './ButtonOutlined';
import ButtonPrimary from './ButtonPrimary';
import ButtonSecondary from './ButtonSecondary';

export interface BaseProps
  extends Pick<ButtonBaseProps, 'onClick' | 'disabled' | 'children'> {
  loading?: boolean;
  fullWidth?: boolean;
}

export interface ButtonProps extends BaseProps {
  variant?: 'primary' | 'secondary' | 'outlined';
}

const Button: React.FC<ButtonProps> = ({
  variant = 'primary',
  children,
  ...rest
}) => {
  if (variant === 'secondary') {
    return <ButtonSecondary {...rest}>{children}</ButtonSecondary>;
  } else if (variant === 'outlined') {
    return <ButtonOutlined {...rest}>{children}</ButtonOutlined>;
  }
  return <ButtonPrimary {...rest}>{children}</ButtonPrimary>;
};

export default Button;
