import { Box, Link, Typography } from '@material-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';
import { useDispatch } from 'react-redux';

import episodeImg from 'assets/webp/img-shorties-vtmgo.webp';

import { EventAction } from 'constants/analytics';
import { useAnalytics } from 'hooks';
import { QueryKeys, useAnswerBonusQuestion, useBonusQuestion } from 'queries';
import { gameActions } from 'store/game/game.slice';
import { BonusQuestionAnswer } from 'types/bonus.types';
import { ViewType } from 'types/game.types';

import Button from 'components/@common/Button';
import QuestionAnswerButton from 'components/@common/QuestionAnswerButton';
import VtmGoButton from 'components/@common/VtmGoButton';
import { IcoAddWatchlist } from 'components/@icons';

import { ModalContainer, ModalScrollContent, ModalTitle } from '../Modal';
import { Cover, WatchlistButton } from './BonusQuestionModal.style';

const VTMGO_PROGRAM_URL =
  'https://www.vtmgo.be/vtmgo/de-verraders-het-uur-van-de-waarheid~7715087e-a179-46a3-b0bf-01b5d009a8fa';
const VTMGO_WATCHLIST_URL =
  'https://www.vtmgo.be/vtmgo/de-verraders-het-uur-van-de-waarheid~7715087e-a179-46a3-b0bf-01b5d009a8fa/toevoegen-aan-mijn-lijst';

const Episode: React.FC = () => {
  const { trackEvent } = useAnalytics();
  return (
    <Box display="flex">
      <Cover src={episodeImg} />
      <Box ml={4}>
        <Typography variant="body1">
          <FormattedMessage
            id="bonus.episode.label"
            values={{
              b: (v: string) => <b>{v}</b>,
            }}
          />
        </Typography>
        <Box mt={2} display="flex">
          <VtmGoButton
            href={VTMGO_PROGRAM_URL}
            onClick={() => {
              trackEvent({ eventAction: EventAction.VtmGoWatch });
            }}
          >
            <FormattedMessage id="bonus.episode.watch_now.button" />
          </VtmGoButton>
          <WatchlistButton
            href={VTMGO_WATCHLIST_URL}
            target="_blank"
            onClick={() => {
              trackEvent({ eventAction: EventAction.VtmGoAddList });
            }}
          >
            <IcoAddWatchlist fontSize={22} />
            <Box position="absolute" left="42px" width="65px">
              <Typography variant="body2">
                <b>
                  <FormattedMessage id="bonus.episode.watch_list.button" />
                </b>
              </Typography>
            </Box>
          </WatchlistButton>
        </Box>
      </Box>
    </Box>
  );
};

const BonusQuestionModal: React.VFC = () => {
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const [selectedAnswers, setSelectedAnswers] = useState<
    BonusQuestionAnswer['id'][]
  >([]);
  const { bonusQuestion } = useBonusQuestion();
  const { answer, isLoading } = useAnswerBonusQuestion();
  const queryClient = useQueryClient();

  const handleSubmit = () => {
    if (selectedAnswers.length > 0 && bonusQuestion) {
      trackEvent({ eventAction: EventAction.BonusQuestionAnswer });
      answer(
        {
          questionId: bonusQuestion.id,
          answerIds: selectedAnswers,
        },
        {
          onSuccess: () => {
            if (bonusQuestion?.questionType === 'poll_question') {
              handleShowOverview();
            }
          },
        },
      );
    }
  };

  const handleShowOverview = () => {
    dispatch(gameActions.SET_VIEW({ type: ViewType.Game }));
    queryClient.refetchQueries(QueryKeys.bonus.default());
  };

  const handleChangeAnswer = (
    selected: boolean,
    answer: BonusQuestionAnswer,
  ) => {
    if (!bonusQuestion) return;

    const multipeAnswersAllowed = (bonusQuestion.maxUserAnswers ?? 0) > 1;
    if (multipeAnswersAllowed) {
      if (selected) {
        setSelectedAnswers([...selectedAnswers, answer.id]);
      } else {
        setSelectedAnswers(selectedAnswers.filter((a) => a !== answer.id));
      }
    } else {
      setSelectedAnswers([answer.id]);
    }
  };

  return (
    <ModalContainer onClose={handleShowOverview}>
      <ModalTitle>
        <FormattedMessage
          id={`${
            bonusQuestion?.questionType === 'poll_question' ? 'poll' : 'bonus'
          }.title`}
        />
      </ModalTitle>
      {bonusQuestion && (
        <ModalScrollContent>
          {bonusQuestion.questionType !== 'poll_question' && <Episode />}
          <Box mt={2} width="100%" display="flex" justifyContent="center">
            <Box flexDirection="column" display="flex" alignItems="center">
              <Box textAlign="center" my={6}>
                {bonusQuestion.questionType === 'poll_question' ? (
                  <Typography variant="h2">{bonusQuestion.question}</Typography>
                ) : (
                  <Typography variant="body1">
                    <i>"{bonusQuestion.question}"</i>
                  </Typography>
                )}
              </Box>
              {bonusQuestion.questionType === 'poll_question' && (
                <Box textAlign="center" mb={6} maxWidth={350}>
                  <Typography variant="body1">
                    <FormattedMessage id="bonus.body" />
                  </Typography>
                </Box>
              )}
              {bonusQuestion.possibleAnswers.map((b) => (
                <Box key={b.answer} my={1} width={{ xs: '100%', sm: 300 }}>
                  <QuestionAnswerButton
                    selected={selectedAnswers.includes(b.id)}
                    onChange={(s) => handleChangeAnswer(s, b)}
                    correct={bonusQuestion.userAnsweredCorrectly}
                    disabled={
                      isLoading ||
                      bonusQuestion.userAnsweredCorrectly !== null ||
                      (!!bonusQuestion.maxUserAnswers &&
                        bonusQuestion.maxUserAnswers ===
                          selectedAnswers.length &&
                        bonusQuestion.maxUserAnswers > 1)
                    }
                    radio={(bonusQuestion.maxUserAnswers ?? 1) <= 1}
                  >
                    {b.answer}
                  </QuestionAnswerButton>
                </Box>
              ))}
              <Box
                mt={4}
                display="flex"
                justifyContent="center"
                width={{ xs: '100%', sm: 300 }}
              >
                {bonusQuestion.userAnsweredCorrectly === null ? (
                  <Button
                    variant="secondary"
                    disabled={
                      (bonusQuestion.maxUserAnswers ?? 1) !==
                      selectedAnswers.length
                    }
                    fullWidth
                    loading={isLoading}
                    onClick={handleSubmit}
                  >
                    <FormattedMessage
                      id={`${
                        bonusQuestion.questionType === 'poll_question'
                          ? 'poll'
                          : 'bonus'
                      }.confirm.button`}
                    />
                    {(bonusQuestion.maxUserAnswers ?? 1) > 1 && (
                      <Box textAlign="center" my={4}>
                        <Typography variant="body1">
                          {` (${selectedAnswers.length}/${bonusQuestion.maxUserAnswers})`}
                        </Typography>
                      </Box>
                    )}
                  </Button>
                ) : (
                  <Link
                    component="button"
                    onClick={handleShowOverview}
                    style={{ fontSize: 16 }}
                  >
                    <FormattedMessage id="bonus.overview.button" />
                  </Link>
                )}
              </Box>
            </Box>
          </Box>
        </ModalScrollContent>
      )}
    </ModalContainer>
  );
};

export default BonusQuestionModal;
