import { Container, Link, Typography } from '@material-ui/core';
import { FC, useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import { Routes } from 'pages/routes.constants';

const NotFound: FC = () => {
  const history = useHistory();
  useEffect(() => {
    history.replace(Routes.Root);
  }, [history]);

  return (
    <Container>
      <Typography variant="h1" gutterBottom>
        404
      </Typography>
      <Link component={RouterLink} to={Routes.Root}>
        Go to {Routes.Root}
      </Link>
    </Container>
  );
};

export default NotFound;
