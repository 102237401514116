import { Box, Divider, Link, Typography } from '@material-ui/core';
import parse, {
  domToReact,
  Element,
  HTMLReactParserOptions,
} from 'html-react-parser';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';

import imgPointsWin from 'assets/png/img-points-win.png';

import { useIntl } from 'hooks';

import { ModalContainer, ModalScrollContent, ModalTitle } from '../Modal';

const ALLOWED_TAGS = ['br', 'b', 'a', 'h1', 'h2', 'h3', 'h4', 'h5'];

const OPTIONS: HTMLReactParserOptions = {
  htmlparser2: {
    recognizeSelfClosing: true,
  },
  replace: (domNode) => {
    if (domNode.type === 'tag') {
      const { name, children, attribs } = domNode as Element;
      if (ALLOWED_TAGS.includes(name)) {
        if (name === 'a') {
          return (
            <Link
              href={attribs.href}
              target="_blank"
              data-testid="link-test-id"
            >
              {domToReact(children, OPTIONS)}
            </Link>
          );
        } else if (name.includes('h')) {
          return (
            // @ts-ignore
            <Typography variant={name}>
              {domToReact(children, OPTIONS)}
            </Typography>
          );
        }
      } else {
        return <>{domToReact(children, OPTIONS)}</>;
      }
    }
  },
};

interface Props {
  value: number;
}

const Score = ({ value }: Props) => {
  return (
    <Box
      display="flex"
      position="relative"
      width={55}
      height={55}
      flexShrink={0}
    >
      <img
        src={imgPointsWin}
        width="100%"
        height="100%"
        alt={`score is ${value}`}
      />
      <Box
        position="absolute"
        top={0}
        right={0}
        left={0}
        bottom={0}
        fontSize={16}
        display="flex"
        justifyContent="center"
        alignItems="center"
        component="span"
      >
        <Typography variant="h4" color="textPrimary">
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

interface ScoreItemProps {
  labelId: string;
  value: number;
  divider?: boolean;
}

const ScoreItem = ({ labelId, value, divider = true }: ScoreItemProps) => {
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        my={4}
      >
        <Typography>
          <FormattedMessage id={labelId} />
        </Typography>
        <Score value={value} />
      </Box>
      {divider && <Divider style={{ opacity: 0.1 }} />}
    </Box>
  );
};

const RulesModal: React.VFC = () => {
  const intl = useIntl();
  return (
    <ModalContainer>
      <ModalTitle>
        <FormattedMessage id="rules.title" />
      </ModalTitle>
      <ModalScrollContent>
        <Typography
          variant="body1"
          color="textPrimary"
          component="div"
          data-testid="details-body-test-id"
        >
          <Box mt={8} mb={16}>
            <Typography variant="h3">
              <FormattedMessage id="rules.points.title" />
            </Typography>
            <Box>
              <ScoreItem labelId="rules.points.place_1" value={150} />
              <ScoreItem labelId="rules.points.place_2" value={100} />
              <ScoreItem labelId="rules.points.place_3" value={50} />
              <ScoreItem
                labelId="rules.points.survivor"
                value={75}
                divider={false}
              />
            </Box>
          </Box>
          {parse(intl.formatMessage({ id: 'rules.description' }), OPTIONS)}
        </Typography>
      </ModalScrollContent>
    </ModalContainer>
  );
};

export default RulesModal;
