import styled from 'styled-components';

export const TipSvg = styled.svg`
  fill: ${({ theme }) => theme.palette.global01[500]};
  width: 18px;
  height: 13px;
  position: absolute;
  bottom: -9px;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 500;
`;

export const Line = styled.div`
  width: 100%;
  height: 3px;

  background-color: ${({ theme }) => theme.palette.global01[500]};
`;

export const ContentWrap = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.neutral02[500]};
  padding: ${({ theme }) => theme.spacing(3)}px;
  display: flex;
`;
