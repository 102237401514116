import styled from 'styled-components';

import borderBg from 'assets/jpg/bg-board-border-tile.jpg';
import boardBg from 'assets/jpg/bg-board-tile.jpg';

export const Wrap = styled.div`
  display: flex;
  position: relative;

  padding: 0;
  width: 100%;
  height: 100%;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: ${({ theme }) => theme.spacing(4)}px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${({ theme }) => theme.spacing(16)}px;
  }
`;

export const Background = styled.div<{ $variant: 'blue' | 'green' }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${boardBg});
  background-size: 35%;

  filter: ${({ $variant }) =>
    $variant === 'green' ? 'hue-rotate(-60deg) brightness(1.5)' : undefined};
`;

export const Border = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: url(${borderBg});
  background-size: 5%;
  border-radius: ${({ theme }) => theme.shape.radius(4)}px;
  box-shadow: 0px 2px 2px rgba(170, 163, 151, 0.5);

  padding: ${({ theme }) => theme.spacing(2)}px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: ${({ theme }) => theme.spacing(4)}px;
  }
`;
