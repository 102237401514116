import styled from 'styled-components';

export const Wrap = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.palette.neutral07[500]};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: ${({ theme }) => theme.spacing(6)}px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 14px;
      bottom: 14px;
      left: 14px;
      right: 14px;
      border: 1px solid ${({ theme }) => theme.palette.global01[500]};
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 22px;
      bottom: 22px;
      left: 22px;
      right: 22px;
      border: 1px solid ${({ theme }) => theme.palette.global01[500]};
    }
  }
`;

export const Content = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 10;

  padding: ${({ theme }) => theme.spacing(6, 0)};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: ${({ theme }) => theme.spacing(5, 0, 0)};
  }
`;

export const Background = styled.div`
  position: absolute;
  top: 10%;
  left: 10%;
  right: 10%;
  aspect-ratio: 1;
  background-image: url('/assets/bg-center-table.png');
  background-size: cover;
  overflow: hidden;
  z-index: 1;
`;
