import * as React from 'react';

function SvgIcoShare(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 16" fill="none" {...props}>
      <path
        d="M10.683 5.346a2.503 2.503 0 002.496-2.496A2.491 2.491 0 0010.683.354 2.503 2.503 0 008.187 2.85c0 .346.077.672.192.96l-3.59 2.304a2.55 2.55 0 00-1.498-.499A2.503 2.503 0 00.795 8.111a2.491 2.491 0 002.496 2.496 2.55 2.55 0 001.498-.499l3.513 2.265c-.077.23-.115.5-.115.768a2.503 2.503 0 002.496 2.496 2.503 2.503 0 002.496-2.496 2.491 2.491 0 00-2.496-2.496c-.557 0-1.056.173-1.46.48L5.692 8.841c.077-.23.115-.48.115-.73s-.038-.5-.115-.73l3.725-2.4c.365.25.806.365 1.267.365z"
        fill="currentColor"
      />
    </svg>
  );
}
const MemoSvgIcoShare = React.memo(SvgIcoShare);
export default MemoSvgIcoShare;
