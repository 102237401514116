import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';

import Button from 'components/@common/Button';
import { IcoBanish, IcoBonus, IcoMurder, IcoSurvive } from 'components/@icons';

interface InfoIconProps {
  icon: React.ReactNode;
  labelId: string;
}
const InfoIcon: React.VFC<InfoIconProps> = ({ icon, labelId }) => {
  return (
    <>
      {icon}
      <Typography variant="body2" color="textSecondary">
        <FormattedMessage
          id={labelId}
          values={{
            b: (v: string) => <b>{v}</b>,
          }}
        />
      </Typography>
    </>
  );
};

interface Props {
  onPlay: () => void;
}

const MobileLoggedOutState: React.VFC<Props> = ({ onPlay }) => {
  return (
    <Box
      textAlign="center"
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      minHeight={400}
      position="relative"
      overflow="hidden"
    >
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        alignItems="center"
        m={4}
      >
        <Box mt={2} mb={6} textAlign="center" width={180}>
          <Typography variant="h2" color="textSecondary">
            <FormattedMessage id="game.start.title.mobile" />
          </Typography>
        </Box>
        <Box mb={6}>
          <Grid container spacing={4} justifyContent="space-around">
            <Grid item xs={6}>
              <InfoIcon
                icon={<IcoMurder fontSize={40} />}
                labelId="game.start.who.murder"
              />
            </Grid>
            <Grid item xs={6}>
              <InfoIcon
                icon={<IcoBanish fontSize={40} />}
                labelId="game.start.who.banish"
              />
            </Grid>
            <Grid item xs={6}>
              <InfoIcon
                icon={<IcoSurvive fontSize={40} />}
                labelId="game.start.who.survive"
              />
            </Grid>
            <Grid item xs={6}>
              <InfoIcon
                icon={<IcoBonus fontSize={40} />}
                labelId="game.start.bonus"
              />
            </Grid>
          </Grid>
        </Box>
        <Button variant="primary" fullWidth onClick={onPlay}>
          <FormattedMessage id="game.start.play.button" />
        </Button>
      </Box>
    </Box>
  );
};

export default MobileLoggedOutState;
