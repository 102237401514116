/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { PredictionType } from 'types/game.types';

import { SvgStyled20 } from './BoardSvgPiece.style';

interface Props {
  banishedPosition?: number;
  murdererPosition?: number;
  survivorPosition?: number;
  selectingType?: PredictionType;
  active?: boolean;
  disabled?: boolean;
  isCorrect?: boolean;
}

const BoardSvgPiece: React.VFC<Props> = ({
  active = false,
  murdererPosition,
  banishedPosition,
  survivorPosition,
  selectingType,
  disabled,
  isCorrect,
}) => {
  return (
    <SvgStyled20 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111.85 105.65">
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="102.72"
          y1="56.79"
          x2="80.99"
          y2="18.35"
          gradientTransform="translate(.23 108.97) rotate(-.58) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" />
          <stop offset="1" stopColor="#feedc0" />
        </linearGradient>
      </defs>
      <g id="bg">
        <path
          id="bg-color"
          className="cls-14"
          d="M0,4.46C37.05-1.41,74.8-1.41,111.85,4.46l-16.03,101.2c-26.43-4.19-53.36-4.19-79.79,0L0,4.46Z"
        />
        <g id="lines">
          <path
            className="cls-12"
            d="M9.12,55.55l2.08,13.46c16.35-2.2,27.21-3.34,44.4-3.34s28.09,1.14,45.02,3.44l2.08-13.46c-15.69-2.28-28.91-3.59-47.09-3.59s-30.28,1.13-46.48,3.5Z"
          />
          <path
            className="cls-9"
            d="M4.38,25.24c19.05-2.91,33.71-4.89,51.33-4.85,17.06,0,15.56,.16,52.39,4.9l3.74-20.83c-15.73-2.34-24.59-3.34-40.24-4.04-.01,0-.02,0-.03,0-2.53-.11-5.07-.2-7.6-.26-.22,0-.45,0-.67-.01-2.35-.05-4.7-.08-7.05-.08-.15,0-.3,0-.44,0-2.38,0-4.75,.03-7.12,.07-.34,0-.67,.01-1.01,.02-2.46,.05-4.92,.13-7.38,.24-.03,0-.07,0-.1,0C24.89,1.31,16.8,1.92,1.12,4.24l3.26,21Zm1.93-17.06c.46-.62,1.04-1.14,1.7-1.53s1.4-.65,2.16-.76h0c34.3-4.56,57.64-4.64,91.44,.19,.78,.11,1.53,.39,2.2,.8s1.25,.95,1.71,1.59c.46,.64,.78,1.37,.95,2.14,.17,.77,.18,1.56,.04,2.34l-.66,3.5c-.29,1.47-1.13,2.78-2.35,3.64-1.22,.87-2.73,1.23-4.22,1.02-32.66-5.12-54.28-5.12-86.94,0-1.52,.21-3.07-.18-4.3-1.1-1.23-.92-2.06-2.28-2.29-3.8l-.54-3.66c-.11-.76-.08-1.54,.11-2.29s.52-1.45,.98-2.07Z"
          />
          <path
            className="cls-19"
            d="M106.81,24.76c-9.23-1.26-19.59,10.12-19.59,10.12-.17,.19-.38,.34-.62,.44-.23,.1-.49,.16-.74,.16h-12.7c-.52,0-1.02,.18-1.41,.52-.39,.34-.64,.81-.71,1.33-.46,3.63-2.22,6.97-4.97,9.39-2.74,2.42-6.28,3.76-9.94,3.76s-7.19-1.34-9.94-3.76c-2.74-2.42-4.51-5.76-4.97-9.39-.07-.51-.32-.98-.71-1.32-.39-.34-.89-.52-1.41-.52h-13.34c-.26,0-.51-.05-.74-.16-.23-.1-.44-.25-.62-.44,0,0-12.11-11.7-19.26-10.34"
          />
          <path className="cls-19" d="M38.4,9.95h14" />
          <path
            className="cls-9"
            d="M56.02,24.47l2.41,4.9,.22,.44,.5,.07,5.4,.79-3.91,3.82-.35,.34v.5l.92,5.38-4.83-2.55-.44-.23-.44,.23-4.84,2.55,.92-5.38,.09-.5-.35-.34-3.92-3.82,5.41-.79,.48-.07,.23-.44,2.41-4.9h.07Zm-.07-2.14l-3.26,6.61-7.31,1.07,5.28,5.15-1.24,7.31,6.54-3.44,6.52,3.44-1.24-7.31,5.28-5.15-7.31-1.07-3.26-6.61h.01Z"
          />
          <path
            className="cls-9"
            d="M56.01,22.33v11.09l-3.26-4.48,3.26-6.61Z"
          />
          <path
            className="cls-9"
            d="M45.45,30.01l10.56,3.41-5.28,1.74-5.28-5.15Z"
          />
          <path
            className="cls-9"
            d="M56.01,39.01v-5.59l-6.52,9.03,6.52-3.44Z"
          />
          <path
            className="cls-9"
            d="M61.3,35.16l-5.3-1.74,6.54,9.03-1.24-7.28Z"
          />
          <path
            className="cls-9"
            d="M66.58,30.01l-10.58,3.41,3.28-4.48,7.3,1.07Z"
          />
          <path
            className="cls-17"
            d="M55.72,13.27c1.88,0,3.41-1.53,3.41-3.41s-1.53-3.41-3.41-3.41-3.41,1.53-3.41,3.41,1.53,3.41,3.41,3.41Z"
          />
          <path className="cls-19" d="M59.12,9.86h13.65" />
          <path
            className="cls-19"
            d="M106.81,24.76c-9.23-1.26-19.59,10.12-19.59,10.12-.17,.19-.38,.34-.62,.44-.23,.1-.49,.16-.74,.16h-12.7c-.52,0-1.02,.18-1.41,.52-.39,.34-.64,.81-.71,1.33-.46,3.63-2.22,6.97-4.97,9.39-2.74,2.42-6.28,3.76-9.94,3.76s-7.19-1.34-9.94-3.76c-2.74-2.42-4.51-5.76-4.97-9.39-.07-.51-.32-.98-.71-1.32-.39-.34-.89-.52-1.41-.52h-13.34c-.26,0-.51-.05-.74-.16-.23-.1-.44-.25-.62-.44,0,0-12.11-11.7-19.26-10.34"
          />
          <path className="cls-19" d="M38.4,9.95h14" />
          <path
            className="cls-9"
            d="M56.02,24.47l2.41,4.9,.22,.44,.5,.07,5.4,.79-3.91,3.82-.35,.34v.5l.92,5.38-4.83-2.55-.44-.23-.44,.23-4.84,2.55,.92-5.38,.09-.5-.35-.34-3.92-3.82,5.41-.79,.48-.07,.23-.44,2.41-4.9h.07Zm-.07-2.14l-3.26,6.61-7.31,1.07,5.28,5.15-1.24,7.31,6.54-3.44,6.52,3.44-1.24-7.31,5.28-5.15-7.31-1.07-3.26-6.61h.01Z"
          />
          <path
            className="cls-9"
            d="M56.01,22.33v11.09l-3.26-4.48,3.26-6.61Z"
          />
          <path
            className="cls-9"
            d="M45.45,30.01l10.56,3.41-5.28,1.74-5.28-5.15Z"
          />
          <path
            className="cls-9"
            d="M56.01,39.01v-5.59l-6.52,9.03,6.52-3.44Z"
          />
          <path
            className="cls-9"
            d="M61.3,35.16l-5.3-1.74,6.54,9.03-1.24-7.28Z"
          />
          <path
            className="cls-9"
            d="M66.58,30.01l-10.58,3.41,3.28-4.48,7.3,1.07Z"
          />
          <path
            className="cls-17"
            d="M55.72,13.27c1.88,0,3.41-1.53,3.41-3.41s-1.53-3.41-3.41-3.41-3.41,1.53-3.41,3.41,1.53,3.41,3.41,3.41Z"
          />
          <path className="cls-19" d="M59.12,9.86h13.65" />
          <path
            className="cls-3"
            d="M0,4.41c.37-.06,.74-.12,1.11-.17l15.68,100.87c-.26,.04-.52,.09-.79,.13L0,4.41Z"
          />
          <path
            className="cls-3"
            d="M110.74,4.34c.37,.06,.74,.12,1.11,.17l-16.11,101.03c-.26-.04-.54-.09-.8-.13L110.74,4.34Z"
          />
        </g>
        <line className="cls-18" x1="41.02" y1="66" x2="42.87" y2="102.42" />
        <line className="cls-18" x1="71.16" y1="66.15" x2="69.41" y2="102.59" />
      </g>
      {active && (
        <g id="bg-overlay">
          <path
            id="bg-color-2"
            className="cls-8"
            d="M0,4.46C37.05-1.41,74.8-1.41,111.85,4.46l-16.03,101.2c-26.43-4.19-53.36-4.19-79.79,0L0,4.46Z"
          />
          <g id="lines-2">
            <path
              className="cls-4"
              d="M9.12,55.55l2.08,13.46c16.35-2.2,27.21-3.34,44.4-3.34s28.09,1.14,45.02,3.44l2.08-13.46c-15.69-2.28-28.91-3.59-47.09-3.59s-30.28,1.13-46.48,3.5Z"
            />
            <path
              className="cls-4"
              d="M4.38,25.24c19.05-2.91,33.71-4.89,51.33-4.85,17.06,0,15.56,.16,52.39,4.9l3.74-20.83c-15.73-2.34-24.59-3.34-40.24-4.04-.01,0-.02,0-.03,0-2.53-.11-5.07-.2-7.6-.26-.22,0-.45,0-.67-.01-2.35-.05-4.7-.08-7.05-.08-.15,0-.3,0-.44,0-2.38,0-4.75,.03-7.12,.07-.34,0-.67,.01-1.01,.02-2.46,.05-4.92,.13-7.38,.24-.03,0-.07,0-.1,0C24.89,1.31,16.8,1.92,1.12,4.24l3.26,21Zm1.93-17.06c.46-.62,1.04-1.14,1.7-1.53s1.4-.65,2.16-.76h0c34.3-4.56,57.64-4.64,91.44,.19,.78,.11,1.53,.39,2.2,.8s1.25,.95,1.71,1.59c.46,.64,.78,1.37,.95,2.14,.17,.77,.18,1.56,.04,2.34l-.66,3.5c-.29,1.47-1.13,2.78-2.35,3.64-1.22,.87-2.73,1.23-4.22,1.02-32.66-5.12-54.28-5.12-86.94,0-1.52,.21-3.07-.18-4.3-1.1-1.23-.92-2.06-2.28-2.29-3.8l-.54-3.66c-.11-.76-.08-1.54,.11-2.29s.52-1.45,.98-2.07Z"
            />
            <path
              className="cls-19"
              d="M106.81,24.76c-9.23-1.26-19.59,10.12-19.59,10.12-.17,.19-.38,.34-.62,.44-.23,.1-.49,.16-.74,.16h-12.7c-.52,0-1.02,.18-1.41,.52-.39,.34-.64,.81-.71,1.33-.46,3.63-2.22,6.97-4.97,9.39-2.74,2.42-6.28,3.76-9.94,3.76s-7.19-1.34-9.94-3.76c-2.74-2.42-4.51-5.76-4.97-9.39-.07-.51-.32-.98-.71-1.32-.39-.34-.89-.52-1.41-.52h-13.34c-.26,0-.51-.05-.74-.16-.23-.1-.44-.25-.62-.44,0,0-12.11-11.7-19.26-10.34"
            />
            <path className="cls-19" d="M38.4,9.95h14" />
            <path
              className="cls-9"
              d="M56.02,24.47l2.41,4.9,.22,.44,.5,.07,5.4,.79-3.91,3.82-.35,.34v.5l.92,5.38-4.83-2.55-.44-.23-.44,.23-4.84,2.55,.92-5.38,.09-.5-.35-.34-3.92-3.82,5.41-.79,.48-.07,.23-.44,2.41-4.9h.07Zm-.07-2.14l-3.26,6.61-7.31,1.07,5.28,5.15-1.24,7.31,6.54-3.44,6.52,3.44-1.24-7.31,5.28-5.15-7.31-1.07-3.26-6.61h.01Z"
            />
            <path
              className="cls-9"
              d="M56.01,22.33v11.09l-3.26-4.48,3.26-6.61Z"
            />
            <path
              className="cls-9"
              d="M45.45,30.01l10.56,3.41-5.28,1.74-5.28-5.15Z"
            />
            <path
              className="cls-9"
              d="M56.01,39.01v-5.59l-6.52,9.03,6.52-3.44Z"
            />
            <path
              className="cls-9"
              d="M61.3,35.16l-5.3-1.74,6.54,9.03-1.24-7.28Z"
            />
            <path
              className="cls-9"
              d="M66.58,30.01l-10.58,3.41,3.28-4.48,7.3,1.07Z"
            />
            <path
              className="cls-17"
              d="M55.72,13.27c1.88,0,3.41-1.53,3.41-3.41s-1.53-3.41-3.41-3.41-3.41,1.53-3.41,3.41,1.53,3.41,3.41,3.41Z"
            />
            <path className="cls-19" d="M59.12,9.86h13.65" />
            <path
              className="cls-20"
              d="M106.81,24.76c-9.23-1.26-19.59,10.12-19.59,10.12-.17,.19-.38,.34-.62,.44-.23,.1-.49,.16-.74,.16h-12.7c-.52,0-1.02,.18-1.41,.52-.39,.34-.64,.81-.71,1.33-.46,3.63-2.22,6.97-4.97,9.39-2.74,2.42-6.28,3.76-9.94,3.76s-7.19-1.34-9.94-3.76c-2.74-2.42-4.51-5.76-4.97-9.39-.07-.51-.32-.98-.71-1.32-.39-.34-.89-.52-1.41-.52h-13.34c-.26,0-.51-.05-.74-.16-.23-.1-.44-.25-.62-.44,0,0-12.11-11.7-19.26-10.34"
            />
            <path className="cls-20" d="M38.4,9.95h14" />
            <path
              className="cls-4"
              d="M56.02,24.47l2.41,4.9,.22,.44,.5,.07,5.4,.79-3.91,3.82-.35,.34v.5l.92,5.38-4.83-2.55-.44-.23-.44,.23-4.84,2.55,.92-5.38,.09-.5-.35-.34-3.92-3.82,5.41-.79,.48-.07,.23-.44,2.41-4.9h.07Zm-.07-2.14l-3.26,6.61-7.31,1.07,5.28,5.15-1.24,7.31,6.54-3.44,6.52,3.44-1.24-7.31,5.28-5.15-7.31-1.07-3.26-6.61h.01Z"
            />
            <path
              className="cls-4"
              d="M56.01,22.33v11.09l-3.26-4.48,3.26-6.61Z"
            />
            <path
              className="cls-4"
              d="M45.45,30.01l10.56,3.41-5.28,1.74-5.28-5.15Z"
            />
            <path
              className="cls-4"
              d="M56.01,39.01v-5.59l-6.52,9.03,6.52-3.44Z"
            />
            <path
              className="cls-4"
              d="M61.3,35.16l-5.3-1.74,6.54,9.03-1.24-7.28Z"
            />
            <path
              className="cls-4"
              d="M66.58,30.01l-10.58,3.41,3.28-4.48,7.3,1.07Z"
            />
            <path
              className="cls-16"
              d="M55.72,13.27c1.88,0,3.41-1.53,3.41-3.41s-1.53-3.41-3.41-3.41-3.41,1.53-3.41,3.41,1.53,3.41,3.41,3.41Z"
            />
            <path className="cls-20" d="M59.12,9.86h13.65" />
            <path
              className="cls-3"
              d="M0,4.41c.37-.06,.74-.12,1.11-.17l15.68,100.87c-.26,.04-.52,.09-.79,.13L0,4.41Z"
            />
            <path
              className="cls-3"
              d="M110.74,4.34c.37,.06,.74,.12,1.11,.17l-16.11,101.03c-.26-.04-.54-.09-.8-.13L110.74,4.34Z"
            />
          </g>
          <line className="cls-18" x1="41.02" y1="66" x2="42.87" y2="102.42" />
          <line
            className="cls-18"
            x1="71.16"
            y1="66.15"
            x2="69.41"
            y2="102.59"
          />
        </g>
      )}
      {!!survivorPosition && (
        <g id="ico-survivor-icon">
          <path
            className="cls-1"
            d="M71.53,66.07c11,.7,18.2,1.53,29.09,3.04l-5.68,36.41c-9.53-1.42-15.53-2.07-25.18-2.61l1.77-36.84Z"
          />
          <path
            className="cls-7"
            d="M86.37,84.09l-1.19-3.41-1.94,3.05-3.5-3.59,.94,6.82,7.42,.86,2.48-6.43-4.22,2.69Z"
          />
          <path
            className="cls-7"
            d="M79.74,87.31l9.21,1.07-.1,.9-9.21-1.07,.1-.9Z"
          />
        </g>
      )}

      {survivorPosition &&
        selectingType &&
        selectingType !== PredictionType.Survivor && (
          <g id="ico-survivor-icon-inactive">
            <path
              className="cls-15"
              d="M71.53,66.07c11,.7,18.2,1.53,29.09,3.04l-5.68,36.41c-9.53-1.42-15.53-2.07-25.18-2.61l1.77-36.84Z"
            />
            <path
              className="cls-10"
              d="M86.37,84.09l-1.19-3.41-1.94,3.05-3.5-3.59,.94,6.82,7.42,.86,2.48-6.43-4.22,2.69Z"
            />
            <path
              className="cls-10"
              d="M79.74,87.31l9.21,1.07-.1,.9-9.21-1.07,.1-.9Z"
            />
          </g>
        )}

      {!!banishedPosition && (
        <g id="ico-banished-number">
          <path
            className="banished-bg"
            d="M41.37,65.98c10.99-.61,18.41-.58,29.4,.05l-1.78,36.79c-9.63-.41-16.06-.41-25.7,0l-1.92-36.84Z"
          />
          {selectingType === PredictionType.Banished && (
            <text className="rank" transform="translate(52.93 88.86)">
              <tspan x="0" y="0">
                {banishedPosition}
              </tspan>
            </text>
          )}
          {selectingType !== PredictionType.Banished && (
            <path
              className="cls-4"
              d="M60.03,78.88c-2.24-2.24-5.88-2.24-8.13,0,0,0,0,0,0,0,0,0,0,0,0,0-2.24,2.24-2.24,5.89,0,8.13,1.12,1.12,2.59,1.68,4.07,1.68s2.95-.56,4.07-1.68c2.24-2.24,2.24-5.89,0-8.13Zm-4.07-.41c1.15,0,2.29,.44,3.16,1.31,1.59,1.59,1.71,4.08,.41,5.83l-6.24-6.24c.79-.59,1.72-.9,2.67-.9Zm-3.16,7.63c-1.59-1.59-1.71-4.08-.41-5.83l6.24,6.24c-1.75,1.31-4.24,1.18-5.83-.41Z"
            />
          )}
        </g>
      )}

      {banishedPosition &&
        selectingType &&
        selectingType !== PredictionType.Banished && (
          <g id="ico-banished-icon-inactive">
            <path
              className="cls-15"
              d="M41.37,65.98c10.99-.61,18.41-.58,29.4,.05l-1.78,36.79c-9.63-.41-16.06-.41-25.7,0l-1.92-36.84Z"
            />
            <path
              className="cls-10"
              d="M60.03,78.88c-2.24-2.24-5.88-2.24-8.13,0,0,0,0,0,0,0,0,0,0,0,0,0-2.24,2.24-2.24,5.89,0,8.13,1.12,1.12,2.59,1.68,4.07,1.68s2.95-.56,4.07-1.68c2.24-2.24,2.24-5.89,0-8.13Zm-4.07-.41c1.15,0,2.29,.44,3.16,1.31,1.59,1.59,1.71,4.08,.41,5.83l-6.24-6.24c.79-.59,1.72-.9,2.67-.9Zm-3.16,7.63c-1.59-1.59-1.71-4.08-.41-5.83l6.24,6.24c-1.75,1.31-4.24,1.18-5.83-.41Z"
            />
          </g>
        )}

      {!!murdererPosition && (
        <g id="ico-murderer-number">
          <path
            className="murderer-bg"
            d="M40.48,66.07c-11,.7-18.2,1.53-29.09,3.04l5.68,36.41c9.53-1.42,15.53-2.07,25.18-2.61l-1.77-36.84Z"
          />
          {selectingType === PredictionType.Murdered && (
            <text
              className="rank"
              transform="translate(24.97 90.15) rotate(-4.87)"
            >
              <tspan x="0" y="0">
                {murdererPosition}
              </tspan>
            </text>
          )}
          {selectingType !== PredictionType.Murdered && (
            <path
              className="cls-6"
              d="M33.4,82.95c.15,1.96-.74,3.76-2.2,4.86l.19,2.51c0,.12-.08,.23-.2,.24l-1.07,.08c-.12,0-.23-.08-.24-.21l-.08-1.08c0-.08-.04-.16-.11-.22s-.14-.09-.23-.08c-.08,0-.16,.05-.22,.11-.05,.07-.08,.15-.07,.23l.08,1.08c0,.06-.01,.12-.05,.16-.04,.05-.09,.07-.15,.08l-1.06,.08c-.06,0-.12-.01-.16-.05-.04-.04-.07-.1-.08-.15l-.08-1.08c0-.09-.04-.17-.11-.22-.06-.06-.15-.08-.23-.08-.08,0-.16,.05-.21,.11-.05,.07-.08,.15-.07,.23l.08,1.08c0,.12-.08,.23-.2,.24l-1.07,.08c-.12,0-.23-.08-.24-.21l-.18-2.37c-1.73-.84-2.98-2.55-3.14-4.6-.23-3.07,2.07-5.74,5.14-5.97s5.74,2.07,5.97,5.14Zm-7.8,1.51c.77-.06,1.34-.73,1.28-1.49s-.73-1.34-1.49-1.28-1.34,.73-1.28,1.49,.73,1.34,1.49,1.28Zm5.91-1.84c.06,.77-.52,1.43-1.28,1.49s-1.43-.52-1.49-1.28,.52-1.43,1.28-1.49,1.43,.52,1.49,1.28Zm-3.87,2.79c.07-.12,.2-.2,.34-.2h0c.14-.02,.27,.04,.37,.15l.55,.71c.15,.19,.12,.47-.07,.63-.16,.12-.38,.12-.54,0-.12-.09-.3-.08-.4,.04-.15,.16-.4,.19-.57,.05-.18-.13-.23-.38-.12-.58l.44-.79Z"
            />
          )}
        </g>
      )}

      {murdererPosition &&
        selectingType &&
        selectingType !== PredictionType.Murdered && (
          <g id="ico-murderer-icon-inactive">
            <path
              className="cls-15"
              d="M40.48,66.07c-11,.7-18.2,1.53-29.09,3.04l5.68,36.41c9.53-1.42,15.53-2.07,25.18-2.61l-1.77-36.84Z"
            />
            <path
              className="cls-11"
              d="M33.4,82.95c.15,1.96-.74,3.76-2.2,4.86l.19,2.51c0,.12-.08,.23-.2,.24l-1.07,.08c-.12,0-.23-.08-.24-.21l-.08-1.08c0-.08-.04-.16-.11-.22s-.14-.09-.23-.08c-.08,0-.16,.05-.22,.11-.05,.07-.08,.15-.07,.23l.08,1.08c0,.06-.01,.12-.05,.16-.04,.05-.09,.07-.15,.08l-1.06,.08c-.06,0-.12-.01-.16-.05-.04-.04-.07-.1-.08-.15l-.08-1.08c0-.09-.04-.17-.11-.22-.06-.06-.15-.08-.23-.08-.08,0-.16,.05-.21,.11-.05,.07-.08,.15-.07,.23l.08,1.08c0,.12-.08,.23-.2,.24l-1.07,.08c-.12,0-.23-.08-.24-.21l-.18-2.37c-1.73-.84-2.98-2.55-3.14-4.6-.23-3.07,2.07-5.74,5.14-5.97s5.74,2.07,5.97,5.14Zm-7.8,1.51c.77-.06,1.34-.73,1.28-1.49s-.73-1.34-1.49-1.28-1.34,.73-1.28,1.49,.73,1.34,1.49,1.28Zm5.91-1.84c.06,.77-.52,1.43-1.28,1.49s-1.43-.52-1.49-1.28,.52-1.43,1.28-1.49,1.43,.52,1.49,1.28Zm-3.87,2.79c.07-.12,.2-.2,.34-.2h0c.14-.02,.27,.04,.37,.15l.55,.71c.15,.19,.12,.47-.07,.63-.16,.12-.38,.12-.54,0-.12-.09-.3-.08-.4,.04-.15,.16-.4,.19-.57,.05-.18-.13-.23-.38-.12-.58l.44-.79Z"
            />
          </g>
        )}

      {disabled && (
        <g id="reveal-no-guess">
          <path
            className="cls-21"
            d="M95.82,105.65L111.85,4.46C95.96,1.92,79.94,.46,63.9,.09c-.05,0-.1,0-.16,0-4.98-.11-9.97-.12-14.95-.02-1.93,.04-3.86,.1-5.79,.17-.42,.01-.84,.02-1.26,.04-2.18,.09-4.36,.19-6.53,.32-.14,0-.27,.01-.41,.02C23.17,1.31,11.55,2.57,0,4.4L16.03,105.65c26.54-4.11,53.3-4.17,79.79,0Z"
          />
          {isCorrect && (
            <g id="reveal-correct">
              <path
                className="cls-21"
                d="M95.82,105.65L111.85,4.46C95.96,1.92,79.94,.46,63.9,.09c-.05,0-.1,0-.16,0-4.98-.11-9.97-.12-14.95-.02-1.93,.04-3.86,.1-5.79,.17-.42,.01-.84,.02-1.26,.04-2.18,.09-4.36,.19-6.53,.32-.14,0-.27,.01-.41,.02C23.17,1.31,11.55,2.57,0,4.4L16.03,105.65c26.54-4.11,53.3-4.17,79.79,0Z"
              />
            </g>
          )}
          {isCorrect !== undefined && !isCorrect && (
            <g id="reveal-wrong">
              <path
                className="cls-21"
                d="M95.82,105.65L111.85,4.46C95.96,1.92,79.94,.46,63.9,.09c-.05,0-.1,0-.16,0-4.98-.11-9.97-.12-14.95-.02-1.93,.04-3.86,.1-5.79,.17-.42,.01-.84,.02-1.26,.04-2.18,.09-4.36,.19-6.53,.32-.14,0-.27,.01-.41,.02C23.17,1.31,11.55,2.57,0,4.4L16.03,105.65c26.54-4.11,53.3-4.17,79.79,0Z"
              />
            </g>
          )}
        </g>
      )}
    </SvgStyled20>
  );
};

export default BoardSvgPiece;
