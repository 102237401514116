import React from 'react';
import { useSelector } from 'react-redux';

import { getViewType } from 'store/game/game.selectors';
import { ViewType } from 'types/game.types';

import BonusQuestionModal from 'components/@modals/BonusQuestionModal';
import LeaderboardModal from 'components/@modals/LeaderboardModal';
import MiniCompetitionCreateModal from 'components/@modals/MiniCompetitionCreateModal';
import MiniCompetitionDetailModal from 'components/@modals/MiniCompetitionDetailModal';
import MiniCompetitionEditModal from 'components/@modals/MiniCompetitionEditModal';
import MiniCompetitionEditNameModal from 'components/@modals/MiniCompetitionEditNameModal';
import MiniCompetitionJoinModal from 'components/@modals/MiniCompetitionJoinModal';
import MiniCompetitionMemberDeleteModal from 'components/@modals/MiniCompetitionMemberDeleteModal';
import MiniCompetitionOverviewModal from 'components/@modals/MiniCompetitionOverviewModal';
import RulesModal from 'components/@modals/RulesModal';
import ScoreBreakdownModal from 'components/@modals/ScoreBreakdownModal';

import { Background, Content, Wrap } from './BoardModal.style';

const BoardModal: React.VFC = () => {
  const viewType = useSelector(getViewType);

  return (
    <Wrap>
      <Background />
      <Content>
        {viewType === ViewType.Rules && <RulesModal />}
        {viewType === ViewType.ScoreBreakdown && <ScoreBreakdownModal />}
        {viewType === ViewType.Leaderboard && <LeaderboardModal />}
        {viewType === ViewType.BonusQuestion && <BonusQuestionModal />}
        {viewType === ViewType.MiniCompetitionOverview && (
          <MiniCompetitionOverviewModal />
        )}
        {viewType === ViewType.MiniCompetitionCreate && (
          <MiniCompetitionCreateModal />
        )}
        {viewType === ViewType.MiniCompetitionJoin && (
          <MiniCompetitionJoinModal />
        )}
        {viewType === ViewType.MiniCompetitionDetail && (
          <MiniCompetitionDetailModal />
        )}
        {viewType === ViewType.MiniCompetitionEdit && (
          <MiniCompetitionEditModal />
        )}
        {viewType === ViewType.MiniCompetitionEditName && (
          <MiniCompetitionEditNameModal />
        )}
        {viewType === ViewType.MiniCompetitionMemberDelete && (
          <MiniCompetitionMemberDeleteModal />
        )}
      </Content>
    </Wrap>
  );
};

export default BoardModal;
