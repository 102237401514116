import {
  Divider,
  Link,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { darken } from 'polished';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';
import styled from 'styled-components';

const LinkDelete = styled(Link).attrs({ component: 'button' })`
  color: ${({ theme }) => theme.palette.red[500]};

  &:hover {
    color: ${({ theme }) => darken(0.1, theme.palette.red[500])};
  }
`;

interface Props {
  name: string;
  onDelete: () => void;
}

const CompetitionMemberDeleteListItem: React.VFC<Props> = ({
  name,
  onDelete,
}) => {
  return (
    <>
      <ListItem>
        <ListItemText>
          <Typography variant="body1">{name}</Typography>
        </ListItemText>
        <ListItemSecondaryAction>
          <LinkDelete onClick={onDelete}>
            <FormattedMessage id="mini_competition.edit.delete.button" />
          </LinkDelete>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider component="li" light />
    </>
  );
};

export default CompetitionMemberDeleteListItem;
