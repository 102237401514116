import * as React from 'react';

function SvgIcoBonus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 24" fill="none" {...props}>
      <path
        d="M5.347 11.573h6.142V6.16h1.536v5.412h6.143V6.16h-4.126c.662-.359 1.303-.827 1.76-1.376 1.522-1.834.95-3.409-.004-4.2-.604-.502-1.303-.687-2.022-.532-.692.149-1.366.614-2.005 1.384-.178.214-.352.47-.514.753a5.34 5.34 0 00-.513-.753C11.105.667 10.431.202 9.74.053 9.02-.1 8.32.083 7.717.585c-.953.791-1.526 2.366-.003 4.2.456.549 1.098 1.018 1.76 1.376H5.346v5.412zm8.9-8.91c.487-.585.814-.707.933-.732.02-.005.047-.01.08-.01.072 0 .172.025.312.14.146.122.589.49-.247 1.497-.5.602-1.405 1.064-2.046 1.295.129-.642.449-1.563.969-2.19zm-5.056.895c-.837-1.007-.393-1.375-.248-1.496.14-.116.24-.142.311-.142.034 0 .06.006.082.01.118.026.445.148.931.733.52.627.84 1.547.97 2.19-.641-.23-1.546-.693-2.046-1.295zM5.347 13.109h6.143V19.2H5.347V13.11zM13.025 13.109h6.143V19.2h-6.143V13.11z"
        fill="#fff"
      />
    </svg>
  );
}
const MemoSvgIcoBonus = React.memo(SvgIcoBonus);
export default MemoSvgIcoBonus;
