import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GameState } from 'store/game/game.slice';

export interface AuthState {
  accessToken?: string;
  redirectView?: GameState['view'];
}

const initialState: AuthState = {
  accessToken: undefined,
  redirectView: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    RESET: (state) => {
      state.accessToken = undefined;
    },
    SET_ACCESS_TOKEN: (
      state,
      { payload }: PayloadAction<AuthState['accessToken']>,
    ) => {
      state.accessToken = payload;
    },
    LOGIN_START: (
      state,
      { payload }: PayloadAction<AuthState['redirectView']>,
    ) => {
      state.redirectView = payload;
    },
    LOGIN_CLEAR: (state) => {
      state.redirectView = undefined;
    },
  },
});

export const { actions: authActions } = authSlice;

export default authSlice.reducer;
