import * as React from 'react';

function SvgIcoArrowRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 16" fill="none" {...props}>
      <path d="M5.68 7.032h20.713v.869H5.68v-.869z" fill="currentColor" />
      <path
        fill="currentColor"
        d="M20.148 0l7.363 7.567-.597.614L19.55.614zM6.337 3.413l3.768 3.873-.597.614-3.769-3.872zM5.739 7.032l-3.77 3.873.599.614 3.768-3.873z"
      />
      <path
        fill="currentColor"
        d="M2.568 3.413l3.769 3.873-.598.614L1.97 4.028zM9.508 7.032l-3.77 3.873.599.614 3.768-3.873zM19.556 14.518l7.36-7.563.597.614-7.36 7.563z"
      />
    </svg>
  );
}
const MemoSvgIcoArrowRight = React.memo(SvgIcoArrowRight);
export default MemoSvgIcoArrowRight;
