import { Box, Typography } from '@material-ui/core';
import { darken } from 'polished';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { EventAction } from 'constants/analytics';
import { useAnalytics } from 'hooks';
import { useDeleteCompetitionMember } from 'queries';
import { getViewParams } from 'store/game/game.selectors';
import { gameActions } from 'store/game/game.slice';
import { Store } from 'store/reducers';
import { ViewType } from 'types/game.types';

import Button from 'components/@common/Button';

import { ModalContainer, ModalScrollContent, ModalTitle } from '../Modal';

const DeleteButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.red[500]};
  &:hover {
    background-color: ${({ theme }) => darken(0.1, theme.palette.red[500])};
    @media (hover: none) {
      background-color: ${({ theme }) => darken(0.1, theme.palette.red[500])};
    }
  }
`;

type Params = {
  id: string;
  name: string;
  competitionId: string;
};

const MiniCompetitionMemberDeleteModal: React.VFC = () => {
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const viewParams = useSelector((state: Store) =>
    getViewParams<Params>(state),
  );

  const { deleteMember, isLoading } = useDeleteCompetitionMember({
    onSuccess: () => handleBack(),
  });

  const handleDelete = () => {
    if (viewParams?.competitionId && viewParams?.id) {
      trackEvent({ eventAction: EventAction.MiniCompetitionsDeletePlayer });
      deleteMember({
        competitionId: viewParams.competitionId,
        memberId: viewParams.id,
      });
    }
  };

  const handleBack = () => {
    dispatch(
      gameActions.SET_VIEW({
        type: ViewType.MiniCompetitionEdit,
        params: { id: viewParams?.competitionId },
      }),
    );
  };

  return (
    <ModalContainer onBack={handleBack}>
      <ModalTitle>
        <FormattedMessage id="mini_competition.delete_member.title" />
      </ModalTitle>
      <ModalScrollContent>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          maxWidth={350}
          mx="auto"
          mt={10}
          textAlign="center"
        >
          <Typography variant="h3">
            <FormattedMessage
              id="mini_competition.delete_member.confirm.title"
              values={{ name: viewParams?.name ?? '' }}
            />
          </Typography>
          <Box pt={4} clone>
            <Typography variant="body1">
              <FormattedMessage id="mini_competition.delete_member.confirm.description" />
            </Typography>
          </Box>
          <Box mt={6} width={200}>
            <DeleteButton
              variant="secondary"
              fullWidth
              loading={isLoading}
              disabled={isLoading}
              onClick={handleDelete}
            >
              <FormattedMessage id="mini_competition.delete_member.confirm.button" />
            </DeleteButton>
          </Box>
        </Box>
      </ModalScrollContent>
    </ModalContainer>
  );
};

export default MiniCompetitionMemberDeleteModal;
