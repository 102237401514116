import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { QueryKeys, useAuth } from 'queries';
import { StaleTime } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { queryClient } from 'services/react-query';
import store from 'store';
import { gameActions } from 'store/game/game.slice';
import { Prediction } from 'types/game.types';

const getPredictions = async (userId: string) => {
  const { data: response } = await apiService.getPredictions(userId);
  return response;
};

export const prefetchPredictions = async (memberId: string) => {
  try {
    await queryClient.prefetchQuery(
      QueryKeys.predictions.byId(memberId),
      () => getPredictions(memberId),
      { staleTime: StaleTime.FIVE_MIN },
    );

    const data = queryClient.getQueryData<Prediction[]>(
      QueryKeys.predictions.byId(memberId),
    );
    if (data) {
      store.store.dispatch(
        gameActions.SET_MEMBER_PREDICTIONS({
          memberId,
          predictions: data,
        }),
      );
    }
  } catch {}
};

export const usePredictions = (
  memberId?: string,
  config: UseCommonQueryOptions<Prediction[]> = { enabled: true },
) => {
  const dispatch = useDispatch();
  const { userId } = useAuth();

  const id = memberId ?? userId;

  const { data, status, isInitialLoading, error } = useQuery(
    QueryKeys.predictions.byId(id ?? ''),
    // We can disable it because the query isn't enabled without a valid userId
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => getPredictions(id!),
    {
      ...config,
      enabled: !!id && config.enabled,
      staleTime:
        config?.staleTime ??
        (memberId ? StaleTime.FIVE_MIN : StaleTime.FOREVER),
      onSuccess: (data) => {
        config.onSuccess?.(data);

        if (memberId) {
          dispatch(
            gameActions.SET_MEMBER_PREDICTIONS({
              memberId,
              predictions: data,
            }),
          );
        } else {
          dispatch(gameActions.SET_PREDICTIONS(data));
        }
      },
    },
  );

  return {
    predictions: data,
    status,
    isLoading: isInitialLoading,
    error,
  };
};
