import { ButtonBase } from '@material-ui/core';
import styled from 'styled-components';

export const BackToBoardButton = styled(ButtonBase)`
  height: 75px;
  background-color: ${({ theme }) => theme.palette.neutral04[500]};
  color: ${({ theme }) => theme.palette.neutral01[500]};
  width: 100%;

  padding: ${({ theme }) => theme.spacing(6)}px;

  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.55);

  z-index: 10;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-bottom: ${({ theme }) => theme.spacing(8)}px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    right: 30px;
    top: 0;
    width: auto;
    position: absolute;
    border-radius: 0px 0px 8px 8px;
  }
`;

export const ScoreWrap = styled.div`
  position: absolute;
  right: -15px;
  bottom: 95%;
  max-width: 360px;
  transform: rotate(3.56deg);

  ${({ theme }) => theme.breakpoints.up('md')} {
    right: -30px;
    bottom: 90%;
  }
`;
