import { Box } from '@material-ui/core';
import styled from 'styled-components';

import imgSideBarLeft from 'assets/png/img-bar-left.png';
import imgCenterBar from 'assets/png/img-bar-rep.png';
import imgSideBarRight from 'assets/png/img-bar-right.png';
import imgSilverBar from 'assets/png/img-silver-bar-active.png';
import imgSilverInactiveBar from 'assets/png/img-silver-bar-inactive.png';
import imgSilverLostBar from 'assets/png/img-silver-bar-lost.png';

export const LeftBar = styled.div`
  background: url(${imgSideBarLeft});
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  aspect-ratio: 71 / 80;

  display: none;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: block;
  }
`;

export const RightBar = styled.div`
  background: url(${imgSideBarRight});
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  aspect-ratio: 71 / 80;

  display: none;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: block;
  }
`;

export const CenterBar = styled.div`
  flex: 1;
  width: 100%;
  background: url(${imgCenterBar});
  background-size: contain;
  background-repeat: repeat-x;
  background-color: ${({ theme }) => theme.palette.neutral04[500]};
  height: 100%;
  padding: ${({ theme }) => theme.spacing(2, 4)};
  color: ${({ theme }) => theme.palette.neutral01[500]};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: ${({ theme }) => theme.spacing(2, 0)};
  }
`;

export const AnimationWrap = styled(Box)<{ $idx: number }>`
  animation: bounce 1250ms cubic-bezier(0.175, 0.885, 0.32, 1.375)
    ${({ $idx }) => $idx * 150 + 500}ms both;

  @keyframes bounce {
    0% {
      transform: scale(1);
      opacity: 0.5;
    }
    60% {
      transform: scale(1.3);
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

export const SilverBar = styled.img.attrs({
  src: imgSilverBar,
  alt: 'zilveren staaf',
})`
  width: 100%;
  aspect-ratio: 113 / 81;
`;

export const SilverInactiveBar = styled.img.attrs({
  src: imgSilverInactiveBar,
  alt: 'te winnen zilveren staaf',
})`
  width: 100%;
  aspect-ratio: 81 / 43;
`;

export const SilverLostBar = styled.img.attrs({
  src: imgSilverLostBar,
  alt: 'verloren zilveren staaf',
})`
  width: 100%;
  aspect-ratio: 81 / 43;
`;

export const Title = styled.span`
  font-size: 13px;
  color: ${({ theme }) => theme.palette.neutral01[500]};
  opacity: 0.7;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 16px;
  }
`;

export const SilverLabel = styled.span`
  font-family: Butler;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.neutral01[500]};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 28px;
  }
`;

export const SilverValue = styled.span`
  font-family: Butler;
  font-size: 14px;

  background: linear-gradient(90deg, #feeacf 0%, #f7cb78 76.39%);
  background-clip: content-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 32px;
  }
`;
