import { Box, Link, List, Typography } from '@material-ui/core';
import _sortBy from 'lodash/sortBy';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth, useCompetitions } from 'queries';
import { getViewParams } from 'store/game/game.selectors';
import { gameActions } from 'store/game/game.slice';
import { Store } from 'store/reducers';
import { CompetitionUser } from 'types/competition.types';
import { ViewType } from 'types/game.types';

import Loader from 'components/@common/Loader';
import ShareLinkInfo from 'components/@competition/ShareLinkInfo';
import CompetitionMemberDeleteListItem from 'components/@list/CompetitionMemberDeleteListItem';

import { ModalContainer, ModalScrollContent, ModalTitle } from '../Modal';

const MiniCompetitionEditModal: React.VFC = () => {
  const dispatch = useDispatch();
  const { userId } = useAuth();
  const viewParams = useSelector((state: Store) =>
    getViewParams<{ id: string }>(state),
  );

  const { competitions, isLoading } = useCompetitions();
  const competition = competitions?.find((c) => c.id === viewParams?.id);

  const sortedMembers = _sortBy(competition?.members, ['firstName']).filter(
    (m) => m.id !== userId,
  );

  const handleBack = () => {
    dispatch(
      gameActions.SET_VIEW({
        type: ViewType.MiniCompetitionDetail,
        params: viewParams,
      }),
    );
  };

  const handleDeleteMember = (member: CompetitionUser) => {
    dispatch(
      gameActions.SET_VIEW({
        type: ViewType.MiniCompetitionMemberDelete,
        params: {
          competitionId: viewParams?.id,
          id: member.id,
          name: member.firstName,
        },
      }),
    );
  };

  const handleEdit = () => {
    dispatch(
      gameActions.SET_VIEW({
        type: ViewType.MiniCompetitionEditName,
        params: { id: viewParams?.id, name: competition?.name },
      }),
    );
  };

  return (
    <ModalContainer onBack={handleBack}>
      <ModalTitle>
        {competition?.name ?? <FormattedMessage id="mini_competition.title" />}
      </ModalTitle>
      <ModalScrollContent>
        {isLoading && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Loader fontSize={126} variant="beige" />
          </Box>
        )}
        {competition && !isLoading && (
          <Box
            textAlign="center"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <ShareLinkInfo id={competition.id} />
            <Box
              mt={8}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="h3">
                <FormattedMessage id="mini_competition.edit.group.label" />
              </Typography>
              <Box py={3} clone>
                <Typography variant="body1">{competition.name}</Typography>
              </Box>
              <Link component="button" onClick={handleEdit}>
                <FormattedMessage id="mini_competition.edit.change_name.button" />
              </Link>
            </Box>
            <Box mt={5} maxWidth={350} width="100%">
              <List>
                {sortedMembers.map((member) => (
                  <CompetitionMemberDeleteListItem
                    key={member.id}
                    name={`${member.firstName} ${member.lastNameInitials}`}
                    onDelete={() => handleDeleteMember(member)}
                  />
                ))}
              </List>
            </Box>
          </Box>
        )}
      </ModalScrollContent>
    </ModalContainer>
  );
};

export default MiniCompetitionEditModal;
