import styled, { css } from 'styled-components';

export const Position = styled.div<{
  $position: number;
  $currentUser: boolean;
}>`
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.neutral02[500]};
  padding: 3px;
  position: relative;

  ${({ $position, $currentUser, theme }) => {
    if ($currentUser) {
      return css`
        border: 3px solid ${theme.palette.neutral01[500]};
      `;
    } else if ($position === 1) {
      return css`
        border: 1px solid ${theme.palette.neutral07[400]};
      `;
    } else if ($position === 2) {
      return css`
        border: 1px solid ${theme.palette.neutral01[500]};
      `;
    } else if ($position === 3) {
      return css`
        border: 1px solid #ddc7ad;
      `;
    } else {
      return css`
        border: 1px solid ${theme.palette.neutral01[500]};
        filter: grayscale(1) brightness(0.7);
      `;
    }
  }}
`;
