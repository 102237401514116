import { Theme } from '@material-ui/core';

const createOverrides = (theme: Theme): Theme['overrides'] => ({
  MuiCssBaseline: {
    '@global': {
      html: {
        // Set relative html font size based
        fontSize: `${(theme.typography.fontSize / 16) * 100}%`,
      },
      button: {
        appearance: 'none',
        border: 'none',
        cursor: 'pointer',
        outline: 'none',
        backgroundColor: 'transparent',
        fontFamily: 'Montserrat',
      },
    },
  },
  MuiFormLabel: {
    root: {
      color: theme.palette.neutral02[500],
      fontSize: 16,
      fontWeight: 700,
      '&&$focused': {
        color: theme.palette.neutral02[500],
      },
    },
  },
  MuiInputLabel: {
    shrink: {
      transform: 'translate(0, 2.5px) scale(0.75)',
      transformOrigin: 'top left',
      marginBottom: 2,
    },
  },
  MuiTooltip: {
    tooltip: {
      fontFamily: 'Montserrat',
    },
  },
  MuiInputBase: {
    root: {
      border: `1px solid ${theme.palette.global01[600]}`,
      boxShadow: 'none',

      '&:hover:not($disabled)': {
        border: `1px solid ${theme.palette.global01[500]}`,
      },
      '&&$focused': {
        border: `1px solid ${theme.palette.global01[500]}`,
      },
    },
    input: {
      lineHeight: 1,
      boxSizing: 'border-box',
      height: 40,
      padding: theme.spacing(3, 4),
      fontWeight: 700,
    },
  },
  MuiInput: {
    formControl: {
      'label + &': {
        marginTop: 20,
      },
    },
  },
  MuiButton: {
    root: {
      '&:hover': {
        backgroundColor: theme.palette.global01[500],
        color: theme.palette.global01[400],
      },
      backgroundColor: 'transparant',
      border: `1px solid ${theme.palette.global01[500]}`,
      borderRadius: 0,
      color: theme.palette.global01[500],
      fontWeight: 500,
      padding: 5,
    },
  },
  MuiCheckbox: {
    root: {
      color: theme.palette.global01[400],
      borderRadius: 0,
    },
  },
  MuiChip: {
    root: {
      height: 28,
      backgroundColor: theme.palette.neutral07[600],
      fontSize: 16,
      transition: 'none',
      pointerEvents: 'none',
    },
    colorPrimary: {
      color: theme.palette.neutral02[500],
      backgroundColor: theme.palette.neutral01[500],
    },
    colorSecondary: {
      color: theme.palette.neutral05[500],
      backgroundColor: theme.palette.neutral03[500],
    },
  },
  MuiSnackbarContent: {
    root: {
      backgroundColor: theme.palette.global01[500],
      borderRadius: 4,
      boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.17)',
    },
  },
  MuiLink: {
    root: {
      fontSize: 16,
      fontFamily: 'Montserrat',
      alignItems: 'center',
      display: 'flex',
      '&:hover': {
        color: '#D3A122',
      },
    },
  },
  MuiIconButton: {
    root: {
      padding: 0,
      transition: 'transform .2s ease-in-out;',
      '&:hover': {
        transform: 'scale(1.2)',
        backgroundColor: 'transparent',
      },
    },
  },
  MuiDivider: {
    root: {
      backgroundColor: theme.palette.blue[500],
    },
    middle: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
  },
  MuiList: {
    root: {
      width: '100%',
    },
  },
  MuiListItem: {
    root: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
  MuiListItemAvatar: {
    root: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(5),
    },
  },
  MuiNativeSelect: {
    root: {
      height: 54,
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette.neutral05[500]}`,
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      fontFamily: 'Butler',
      fontWeight: 700,
      fontSize: 16,
    },
  },
});

export { createOverrides };
