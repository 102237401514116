import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from 'queries';
import { StaleTime } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { LeaderboardUser } from 'types/leaderboard.types';

const getLeaderboard = async () => {
  const { data: response } = await apiService.getLeaderBoard();
  return response;
};

export const useLeaderboard = (
  config: UseCommonQueryOptions<LeaderboardUser[]> = {},
) => {
  const { data, status, isInitialLoading, error } = useQuery(
    QueryKeys.leaderboard.all(),
    () => getLeaderboard(),
    {
      ...config,
      staleTime: config?.staleTime ?? StaleTime.FOREVER,
    },
  );

  return {
    leaderboard: data,
    status,
    isLoading: isInitialLoading,
    error,
  };
};
