import { Box, IconButton, Link, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';
import { useDispatch } from 'react-redux';

import { gameActions, GameState } from 'store/game/game.slice';
import { Participant, Prediction, PredictionType } from 'types/game.types';

import Button from 'components/@common/Button';
import {
  IcoButtonArrowLeft,
  IcoButtonArrowRight,
  IcoKing,
} from 'components/@icons';

import PositionButton from './components/PositionButton';
import SeducedButton from './components/SeducedButton';
import TypeButtons from './components/TypeButtons';

interface Props {
  onBack: () => void;
  onChangePredictionType: (type: PredictionType) => void;
  positionPredictions: (Prediction | undefined)[];
  participants?: Participant[];
  closedPrediction?: boolean;
  selecting: {
    type: PredictionType;
    position: number;
  };
  survivorConfirm: GameState['surivorConfirm'];
  onConfirm: (confirm: boolean) => void;
}

const Desktop: React.VFC<Props> = ({
  onBack,
  onConfirm,
  onChangePredictionType,
  selecting,
  positionPredictions,
  participants,
  survivorConfirm,
  closedPrediction = true,
}) => {
  const dispatch = useDispatch();

  const handleChangePosition = (position: number) => {
    dispatch(gameActions.SET_SELECTING({ type: selecting.type, position }));
  };

  const handleNext = () => {
    if (
      selecting.type === PredictionType.Survivor &&
      selecting.position === 3
    ) {
      onBack();
    } else {
      dispatch(gameActions.SELECT_NEXT());
    }
  };

  const handlePrev = () => {
    if (
      selecting.type === PredictionType.Murdered &&
      (selecting.position === 1 || closedPrediction)
    ) {
      onBack();
    } else {
      dispatch(gameActions.SELECT_PREV());
    }
  };

  return (
    <Box
      textAlign="center"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      height="100%"
      width="100%"
    >
      {survivorConfirm ? (
        <Box
          height="100%"
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Link component="button" onClick={() => onConfirm(false)}>
            <FormattedMessage id="game.select.confirm.back.button" />
          </Link>
          <Box
            display="flex"
            flex={1}
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
          >
            <Typography variant="h3">
              <FormattedMessage id="game.select.confirm.title" />
            </Typography>
            <Typography variant="body1">
              <FormattedMessage id="game.select.confirm.description" />
            </Typography>
            <Box display="flex">
              <Box mr={4}>
                <Button variant="outlined" onClick={() => onConfirm(false)}>
                  <FormattedMessage id="game.select.confirm.action.cancel" />
                </Button>
              </Box>
              <Button variant="outlined" onClick={() => onConfirm(true)}>
                <FormattedMessage id="game.select.confirm.action.confirm" />
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Link component="button" onClick={onBack}>
              <FormattedMessage id="game.general.back_overview.button" />
            </Link>

            <Box maxWidth={310} py={4} clone>
              <Typography variant="h3">
                <FormattedMessage
                  id={`game.select.${selecting.type.toLowerCase()}.title`}
                />
              </Typography>
            </Box>
            <TypeButtons
              type={selecting.type}
              onClick={onChangePredictionType}
            />
          </Box>
          {closedPrediction ? (
            <Box color="global01.600">
              <Typography variant="h3">
                <FormattedMessage
                  id={`game.select.blocked.reveal.${selecting.type.toLowerCase()}.title`}
                />
              </Typography>
              <Box pt={3} clone>
                <Typography variant="body2">
                  <FormattedMessage
                    id={`game.select.blocked.reveal.${selecting.type.toLowerCase()}.description`}
                    values={{ b: (v: string) => <b>{v}</b> }}
                  />
                </Typography>
              </Box>
            </Box>
          ) : (
            <>
              <Box maxWidth={310} color="global01.600" height={40}>
                <Typography variant="body2">
                  {positionPredictions[selecting.position - 1] &&
                  selecting.type !== PredictionType.Survivor ? (
                    <FormattedMessage
                      id={`game.select.${selecting.type.toLowerCase()}.choice`}
                      values={{
                        name: (
                          <b>
                            {
                              participants?.find(
                                (p) =>
                                  p.id ===
                                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                  positionPredictions[selecting.position - 1]!
                                    .participantId,
                              )?.firstName
                            }
                          </b>
                        ),
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      id={`game.select.${selecting.type.toLowerCase()}.suspect`}
                    />
                  )}

                  {selecting.type !== PredictionType.Survivor && (
                    <>
                      &nbsp;
                      <b>
                        <FormattedMessage
                          id={`game.select.place.${selecting.position}`}
                        />
                      </b>
                    </>
                  )}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <PositionButton
                  check={!!positionPredictions[0]}
                  active={selecting.position === 1}
                  onClick={() => handleChangePosition(1)}
                >
                  {selecting.type === PredictionType.Survivor ? (
                    <IcoKing fontSize={32} />
                  ) : (
                    1
                  )}
                </PositionButton>
                <Box mx={4}>
                  <PositionButton
                    check={!!positionPredictions[1]}
                    active={selecting.position === 2}
                    onClick={() => handleChangePosition(2)}
                  >
                    {selecting.type === PredictionType.Survivor ? (
                      <IcoKing fontSize={32} />
                    ) : (
                      2
                    )}
                  </PositionButton>
                </Box>
                <PositionButton
                  check={!!positionPredictions[2]}
                  active={selecting.position === 3}
                  onClick={() => handleChangePosition(3)}
                >
                  {selecting.type === PredictionType.Survivor ? (
                    <IcoKing fontSize={32} />
                  ) : (
                    3
                  )}
                </PositionButton>
              </Box>
              {selecting.type === PredictionType.Murdered && (
                <Box my={2}>
                  <SeducedButton />
                </Box>
              )}
            </>
          )}
          <Box color="global01.500">
            <Box mr={4} clone>
              <IconButton onClick={handlePrev}>
                <IcoButtonArrowLeft fontSize={24} />
              </IconButton>
            </Box>
            <IconButton onClick={handleNext} color="inherit">
              <IcoButtonArrowRight fontSize={24} />
            </IconButton>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Desktop;
