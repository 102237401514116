import { Box, Link, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';

import imgMiniCompetition from 'assets/png/img-mini-competition.png';

import IcoArrowRight from 'components/@icons/IcoArrowRight';

import { Border } from './MiniCompetitionInfo.style';

interface Props {
  onShowMiniCompetitions: () => void;
}

const MiniCompetitionInfo: React.VFC<Props> = ({ onShowMiniCompetitions }) => {
  return (
    <Box position="relative" zIndex={0}>
      <Border>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          alignItems="center"
          justifyContent={{ xs: 'space-between', sm: 'flex-start' }}
          textAlign={{ xs: 'center', sm: 'left' }}
          flex={1}
          p={4}
        >
          <Box maxWidth={{ xs: 120, md: 234 }} mt={{ xs: -12, sm: 0 }} clone>
            <img alt="mini competitie" src={imgMiniCompetition} width="90%" />
          </Box>
          <Box
            flexDirection="column"
            display="flex"
            ml={{ xs: 0, sm: 8 }}
            maxWidth={{ sm: 280, md: 320 }}
            alignItems={{ xs: 'center', sm: 'flex-start' }}
          >
            <Box color="global01.600" clone>
              <Typography variant="h3">
                <FormattedMessage id="mini_competition.info.title" />
              </Typography>
            </Box>
            <Box py={6} maxWidth={250} clone>
              <Typography variant="body2">
                <FormattedMessage id="mini_competition.info.description" />
              </Typography>
            </Box>
            <Link component="button" onClick={onShowMiniCompetitions}>
              <FormattedMessage id="mini_competition.info.button" />
              <IcoArrowRight
                color="global01.500"
                width={28}
                height={15}
                style={{ paddingLeft: 8 }}
              />
            </Link>
          </Box>
        </Box>
      </Border>
    </Box>
  );
};

export default MiniCompetitionInfo;
