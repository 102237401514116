import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';

import imgMiniCompetition from 'assets/png/img-mini-competition.png';

import Button from 'components/@common/Button';

interface Props {
  onCreateCompetition: () => void;
}

const EmptyCompetitions: React.VFC<Props> = ({ onCreateCompetition }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box mb={4} maxWidth={200} clone>
        <img alt="mini competitie" src={imgMiniCompetition} width="90%" />
      </Box>
      <Box mb={10}>
        <Box color="global01.600" display="flex" alignItems="center">
          <Box pr={4} clone>
            <Typography variant="h2">1</Typography>
          </Box>
          <Typography variant="h5" color="textPrimary">
            <FormattedMessage id="mini_competition.empty.subtitle1" />
          </Typography>
        </Box>
        <Box color="global01.600" display="flex" alignItems="center" my={4}>
          <Box pr={4} clone>
            <Typography variant="h2">2</Typography>
          </Box>
          <Typography variant="h5" color="textPrimary">
            <FormattedMessage id="mini_competition.empty.subtitle2" />
          </Typography>
        </Box>
        <Box color="global01.600" display="flex" alignItems="center">
          <Box pr={4} clone>
            <Typography variant="h2">3</Typography>
          </Box>
          <Typography variant="h5" color="textPrimary">
            <FormattedMessage id="mini_competition.empty.subtitle3" />
          </Typography>
        </Box>
      </Box>
      <Button variant="secondary" onClick={onCreateCompetition}>
        <FormattedMessage id="mini_competition.create.button" />
      </Button>
    </Box>
  );
};

export default EmptyCompetitions;
