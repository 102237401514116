import styled from 'styled-components';

export const Wrap = styled.div`
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  width: 57%;
  height: 57%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform: translate(-50%, -50%);
  overflow: hidden;
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('/assets/bg-center-table.png');
  background-size: cover;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 38%;
    bottom: 0;
    background: rgba(255, 255, 255, 0.2);
  }
`;
