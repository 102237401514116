import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth } from 'queries';
import { getRedirectView } from 'store/auth/auth.selectors';
import { authActions } from 'store/auth/auth.slice';
import { gameActions } from 'store/game/game.slice';

export const useRedirectView = () => {
  const { isLoggedIn } = useAuth();
  const dispatch = useDispatch();

  const redirectView = useSelector(getRedirectView);
  useEffect(() => {
    if (isLoggedIn && redirectView) {
      dispatch(gameActions.SET_VIEW(redirectView));
      dispatch(authActions.LOGIN_CLEAR());
    }
  }, [dispatch, redirectView, isLoggedIn]);
};
