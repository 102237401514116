import { Box, List } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';

import { Participant, PredictionType } from 'types/game.types';

import UserListItem from 'components/@list/UserListItem';
import {
  ModalContainer,
  ModalScrollContent,
  ModalTitle,
} from 'components/@modals/Modal';

import { Wrap } from './SelectModal.style';

interface Props {
  onClose: () => void;
  onSelect: (participant: Participant) => void;
  type?: PredictionType;
  position: number;
  participants: Participant[];
  title?: string;
}

const SelectModal: React.VFC<Props> = ({
  onClose,
  participants,
  position,
  type,
  onSelect,
  title,
}) => {
  return (
    <Box
      bgcolor="rgba(0,0,0,0.5)"
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      px={4}
      pb={8}
    >
      <Wrap>
        <ModalContainer onClose={onClose}>
          <ModalTitle wrap>
            {!!title ? (
              title
            ) : (
              <>
                {type && (
                  <>
                    <FormattedMessage
                      id={
                        type === PredictionType.Survivor
                          ? 'game.select.survivor.title'
                          : `game.select.${type.toLowerCase()}.suspect`
                      }
                    />
                    {type !== PredictionType.Survivor && (
                      <>
                        &nbsp;
                        <b>
                          <FormattedMessage
                            id={`game.select.place.${position}`}
                          />
                        </b>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </ModalTitle>
          <ModalScrollContent disableGutters>
            <Box width="100%" position="relative">
              <List disablePadding>
                {participants.map((p) => (
                  <UserListItem
                    key={p.id}
                    participant={p}
                    disableGutters
                    color="global01.600"
                    dividerColor="rgba(94, 81, 64, 0.2)"
                    activeType={type}
                    activePosition={position}
                    onClick={() => {
                      onSelect(p);
                      onClose();
                    }}
                  />
                ))}
              </List>
            </Box>
          </ModalScrollContent>
        </ModalContainer>
      </Wrap>
    </Box>
  );
};

export default SelectModal;
