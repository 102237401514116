import styled from 'styled-components';

import imgSideBarLeft from 'assets/png/img-score-bar-left.png';
import imgCenterBar from 'assets/png/img-score-bar-rep.png';
import imgSideBarRight from 'assets/png/img-score-bar-right.png';

export const LeftBar = styled.div`
  background: url(${imgSideBarLeft});
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  aspect-ratio: 199 / 128;
`;

export const RightBar = styled.div`
  background: url(${imgSideBarRight});
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  aspect-ratio: 199 / 128;
`;

export const CenterBar = styled.div`
  flex: 1;
  width: 100%;
  background: url(${imgCenterBar});
  background-size: contain;
  background-repeat: repeat-x;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.neutral01[500]};
`;
