import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';

import Loader from 'components/@common/Loader';

const LoadingState: React.VFC = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      width="100%"
    >
      <Typography variant="h2" color="textSecondary">
        <FormattedMessage id="game.loading.title" />
      </Typography>
      <Box my={4}>
        <Loader fontSize={126} />
      </Box>
      <Box color="blue.400" clone>
        <Typography variant="body1">
          <FormattedMessage id="game.loading.description" />
        </Typography>
      </Box>
    </Box>
  );
};

export default LoadingState;
