import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';

import Button from 'components/@common/Button';
import { IcoBanish, IcoMurder, IcoSurvive } from 'components/@icons';

interface Props {
  onPlay: () => void;
}

const DesktopLoggedOutState: React.VFC<Props> = ({ onPlay }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="100%"
    >
      <Typography variant="h3" color="textSecondary">
        <FormattedMessage id="game.start.title" />
      </Typography>
      <Box display="flex" my={4}>
        <Box display="flex" flexDirection="column" mr={4}>
          <IcoMurder fontSize={40} />
          <Box clone my={4}>
            <IcoBanish fontSize={40} />
          </Box>
          <IcoSurvive fontSize={44} />
        </Box>
        <Box
          height="auto"
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          textAlign="left"
        >
          <Typography variant="body2" color="textSecondary">
            <FormattedMessage
              id="game.start.who.murder"
              values={{
                b: (v: string) => <b>{v}</b>,
              }}
            />
          </Typography>
          <Box my={4}>
            <Typography variant="body2" color="textSecondary">
              <FormattedMessage
                id="game.start.who.banish"
                values={{
                  b: (v: string) => <b>{v}</b>,
                }}
              />
            </Typography>
          </Box>
          <Typography variant="body2" color="textSecondary">
            <FormattedMessage
              id="game.start.who.survive"
              values={{
                b: (v: string) => <b>{v}</b>,
              }}
            />
          </Typography>
        </Box>
      </Box>
      <Box minWidth={250}>
        <Button variant="primary" fullWidth onClick={onPlay}>
          <FormattedMessage id="game.start.play.button" />
        </Button>
      </Box>
    </Box>
  );
};

export default DesktopLoggedOutState;
