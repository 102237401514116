import * as React from 'react';

function SvgIcoAddWatchlist(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 21 20"
      fill="none"
      className="ico-add-watchlist_svg__MyListButton__Icon-sc-16i7je4-0 ico-add-watchlist_svg__bhoDRl"
      {...props}
    >
      <path
        d="M10.313 0c-5.514 0-10 4.486-10 10s4.486 10 10 10 10-4.486 10-10-4.485-10-10-10zm0 2c4.412 0 8 3.589 8 8s-3.588 8-8 8c-4.41 0-8-3.589-8-8s3.59-8 8-8zm-1 4v3h-3a1 1 0 100 2h3v3a1 1 0 102 0v-3h3a1 1 0 100-2h-3V6a1 1 0 10-2 0z"
        fill="#fff"
      />
    </svg>
  );
}
const MemoSvgIcoAddWatchlist = React.memo(SvgIcoAddWatchlist);
export default MemoSvgIcoAddWatchlist;
