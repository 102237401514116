import { useSelector } from 'react-redux';

import { isEndOfGame } from 'utils/game.utils';

import { useAuth, useParticipants, useSeducedPrediction } from 'queries';
import {
  getCurrentSelecting,
  getPredictionCount,
  getPredictions,
  getViewParams,
  getViewType,
} from 'store/game/game.selectors';
import { Store } from 'store/reducers';
import { ViewType } from 'types/game.types';

import CompleteSelectingState from 'components/@states/CompleteSelectingState';
import FinalState from 'components/@states/FinalState';
import GuessTraitorState from 'components/@states/GuessTraitorState';
import IncompleteSelectingState from 'components/@states/IncompleteSelectingState';
import LoggedOutState from 'components/@states/LoggedOutState';
import MemberGameState from 'components/@states/MemberGameState';
import NoSelectionState from 'components/@states/NoSelectionState';
import SelectingState from 'components/@states/SelectingState';
import StatsState from 'components/@states/StatsState';

export enum RenderState {
  None = 'none',
  Final = 'final',
  LoggedOut = 'logged-out',
  Member = 'member',
  NoSelection = 'no-selection',
  Selecting = 'selecting',
  Incomplete = 'incomplete',
  Complete = 'complete',
  Stats = 'stats',
  GuessTraitors = 'guess-traitors',
}

export const useRenderState = (mobile?: boolean) => {
  const { isLoggedIn } = useAuth();
  const { participants } = useParticipants();
  const { isSeduced } = useSeducedPrediction();
  const viewType = useSelector(getViewType);
  const viewParams = useSelector((state: Store) =>
    getViewParams<{ state?: RenderState }>(state),
  );

  const selecting = useSelector(getCurrentSelecting);
  const predictionCount = useSelector(getPredictionCount);
  const memberPredictions = useSelector(getPredictions);

  const renderFinal = isEndOfGame(participants);
  const renderMember = viewType === ViewType.MemberGame;
  const renderNoSelection = isLoggedIn && predictionCount === 0 && !selecting;
  const renderSelecting = isLoggedIn && !!selecting;
  const renderIncompleteSelection =
    isLoggedIn &&
    predictionCount > 0 &&
    (memberPredictions.banished.length < 3 ||
      memberPredictions.survivor.length < 3 ||
      (memberPredictions.murdered.length < 3 && !isSeduced)) &&
    !renderSelecting;
  const renderCompleteSelection =
    isLoggedIn &&
    memberPredictions.banished.length >= 3 &&
    memberPredictions.survivor.length >= 3 &&
    (memberPredictions.murdered.length >= 3 || isSeduced) &&
    !renderSelecting;

  const renderGuessTraitor =
    participants && !participants?.some((p) => p.isTraitor);

  if (renderGuessTraitor) {
    return {
      component: <GuessTraitorState mobile={mobile} />,
      state: RenderState.GuessTraitors,
    };
  } else if (!isLoggedIn) {
    return {
      component: <LoggedOutState mobile={mobile} />,
      state: RenderState.LoggedOut,
    };
  } else if (renderFinal) {
    return { component: <FinalState />, state: RenderState.Final };
  } else if (renderMember) {
    return { component: <MemberGameState />, state: RenderState.Member };
  } else if (
    viewType === ViewType.Game &&
    viewParams?.state === RenderState.Stats
  ) {
    return {
      component: <StatsState mobile={mobile} />,
      state: RenderState.Stats,
    };
  } else if (renderNoSelection) {
    return { component: <NoSelectionState />, state: RenderState.NoSelection };
  } else if (renderSelecting) {
    return {
      component: <SelectingState mobile={mobile} />,
      state: RenderState.Selecting,
    };
  } else if (renderIncompleteSelection) {
    return {
      component: <IncompleteSelectingState />,
      state: RenderState.Incomplete,
    };
  } else if (renderCompleteSelection) {
    return {
      component: <CompleteSelectingState />,
      state: RenderState.Complete,
    };
  }

  return { component: undefined, state: RenderState.None };
};
