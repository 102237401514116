import * as React from 'react';

function SvgIcoBonusButton(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 44 58" fill="none" {...props}>
      <g filter="url(#ico-bonus-button_svg__filter0_d_1037_357042)">
        <path
          d="M5.244 5.14h34.003v34.004L21.64 52.502 5.244 39.144V5.14z"
          fill="url(#ico-bonus-button_svg__paint0_linear_1037_357042)"
        />
        <path
          d="M5.244 5.14h34.003v34.004L21.64 52.502 5.244 39.144V5.14z"
          fill="url(#ico-bonus-button_svg__paint1_linear_1037_357042)"
        />
      </g>
      <g
        clipPath="url(#ico-bonus-button_svg__clip0_1037_357042)"
        fill="#8FA82A"
      >
        <path d="M16.226 25.39h5.882v-5.182h1.47v5.182h5.882v-5.182h-3.95c.633-.344 1.247-.792 1.684-1.318 1.458-1.756.91-3.264-.003-4.022-.578-.48-1.248-.657-1.937-.509-.662.143-1.307.588-1.92 1.325-.17.205-.336.45-.491.721a5.121 5.121 0 00-.491-.721c-.612-.737-1.258-1.182-1.92-1.325-.688-.148-1.358.028-1.937.509-.913.758-1.46 2.266-.003 4.022.437.525 1.051.974 1.685 1.318h-3.951v5.182zm8.523-8.531c.466-.56.779-.678.892-.702a.346.346 0 01.078-.01c.068 0 .164.024.298.135.14.116.564.469-.237 1.433-.479.577-1.345 1.02-1.959 1.24.123-.615.43-1.497.928-2.096zm-4.843.856c-.8-.964-.376-1.317-.236-1.433.133-.11.23-.135.297-.135.033 0 .058.005.079.01.113.024.426.141.891.702.498.6.805 1.48.928 2.096-.613-.22-1.48-.663-1.959-1.24zM16.226 26.86h5.882v5.833h-5.882v-5.832zM23.58 26.86h5.881v5.833h-5.882v-5.832z" />
      </g>
      <defs>
        <linearGradient
          id="ico-bonus-button_svg__paint0_linear_1037_357042"
          x1={15.328}
          y1={10.52}
          x2={13.755}
          y2={8.316}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D7B55D" />
          <stop offset={1} stopColor="#A9811B" />
        </linearGradient>
        <linearGradient
          id="ico-bonus-button_svg__paint1_linear_1037_357042"
          x1={13.691}
          y1={8.318}
          x2={37.933}
          y2={43.435}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#FEEDC0" />
        </linearGradient>
        <clipPath id="ico-bonus-button_svg__clip0_1037_357042">
          <path
            fill="#fff"
            transform="translate(11.352 14.308)"
            d="M0 0h22.981v22.981H0z"
          />
        </clipPath>
        <filter
          id="ico-bonus-button_svg__filter0_d_1037_357042"
          x={0.554}
          y={0.451}
          width={43.383}
          height={56.742}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={2.345} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.65 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1037_357042"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_1037_357042"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
const MemoSvgIcoBonusButton = React.memo(SvgIcoBonusButton);
export default MemoSvgIcoBonusButton;
