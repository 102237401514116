import styled from 'styled-components';

export const Wrap = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.palette.neutral07[700]};
  border-radius: 10px;
  box-shadow: 0px 7.05134px 7.05134px rgba(0, 0, 0, 0.1);
  min-height: 100px;
  min-width: 250px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Borders = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.palette.neutral07[300]};
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 11px;
    bottom: 11px;
    left: 11px;
    right: 11px;
    border: 1px solid ${({ theme }) => theme.palette.neutral07[300]};
    border-radius: 5px;
  }
`;

export const Background = styled.div`
  position: absolute;
  top: 10%;
  left: 10%;
  right: 10%;
  bottom: 10%;
  background-image: url('/assets/bg-center-table.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  opacity: 0.15;
`;
