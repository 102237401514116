/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import _reject from 'lodash/reject';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { EventAction } from 'constants/analytics';
import { useAnalytics } from 'hooks';
import { QueryKeys, useAuth } from 'queries';
import { apiService } from 'services';
import { gameActions } from 'store/game/game.slice';
import {
  Prediction,
  PredictionPostData,
  PredictionType,
} from 'types/game.types';

const postPrediction = async (userId: string, data: PredictionPostData) => {
  const { data: response } = await apiService.postPrediction(userId, data);
  return response;
};

export const useCreatePrediction = (
  config: UseMutationOptions<
    Prediction,
    AxiosError,
    PredictionPostData,
    { previousPredictions: Prediction[] }
  > = {},
) => {
  const { trackEvent } = useAnalytics();
  const queryClient = useQueryClient();
  const { userId } = useAuth();
  const dispatch = useDispatch();

  const { mutate, isLoading } = useMutation(
    (data) => postPrediction(userId!, data),
    {
      ...config,
      onMutate: async (data) => {
        await queryClient.cancelQueries(QueryKeys.predictions.byId(userId!));

        const previousPredictions =
          queryClient.getQueryData<Prediction[]>(
            QueryKeys.predictions.byId(userId!),
          ) ?? [];

        queryClient.setQueryData<Prediction[] | undefined>(
          QueryKeys.predictions.byId(userId!),
          (old) => {
            const newPrediction = {
              ...data,
              userId: userId!,
              createdAt: new Date().toISOString(),
            };
            let finalData: Prediction[];

            if (data.predictionType === PredictionType.Murdered) {
              trackEvent({ eventAction: EventAction.SelectPredictionMurder });
            } else if (data.predictionType === PredictionType.Banished) {
              trackEvent({ eventAction: EventAction.SelectPredictionBanish });
            } else if (data.predictionType === PredictionType.Survivor) {
              trackEvent({
                eventAction: EventAction.SelectPredictionSurvive,
              });
            }

            if (!old || old.length === 0) {
              // trackEvent({ eventAction: EventAction.FirstSuspect });
              finalData = [newPrediction];
            } else {
              // Check if the user is replacing another participant
              // if (
              //   old.find(
              //     (p) =>
              //       p.predictionType === data.predictionType &&
              //       p.position === data.position,
              //   )
              // ) {
              //   trackEvent({ eventAction: EventAction.ChangedSuspect });
              // } else {
              //   trackEvent({ eventAction: EventAction.AddSuspect });
              // }

              let filteredPredictions = _reject(
                old,
                (p) =>
                  p.predictionType === data.predictionType &&
                  (p.position === data.position ||
                    p.participantId === data.participantId),
              );

              if (data.predictionType === PredictionType.Murdered) {
                filteredPredictions = filteredPredictions.filter(
                  (p) => p.predictionType !== PredictionType.Seduced,
                );
              }

              finalData = [...filteredPredictions, newPrediction];

              // const currentPredictions = finalData.filter(
              //   (p) => p.predictionType === data.predictionType,
              // );
              // if (finalData.length === 9) {
              //   trackEvent({ eventAction: EventAction.AllSuspectsComplete });
              // } else if (
              //   data.predictionType === PredictionType.Murdered &&
              //   currentPredictions.length === 3
              // ) {
              //   trackEvent({ eventAction: EventAction.AllMurderedComplete });
              // } else if (
              //   data.predictionType === PredictionType.Banished &&
              //   currentPredictions.length === 3
              // ) {
              //   trackEvent({ eventAction: EventAction.AllBanishedComplete });
              // } else if (
              //   data.predictionType === PredictionType.Survivor &&
              //   currentPredictions.length === 3
              // ) {
              //   trackEvent({ eventAction: EventAction.AllSurvivorsComplete });
              // }
            }

            dispatch(gameActions.SET_PREDICTIONS(finalData));

            return finalData;
          },
        );

        return { previousPredictions };
      },
      onError: (_err, _id, ctx) => {
        if (ctx?.previousPredictions) {
          queryClient.setQueryData(
            QueryKeys.predictions.byId(userId!),
            ctx.previousPredictions,
          );
        }
      },
    },
  );

  const handleCreatePrediction = useCallback(
    (data: PredictionPostData) => {
      if (userId) {
        mutate(data);
      }
    },
    [mutate, userId],
  );

  return {
    createPrediction: handleCreatePrediction,
    isLoading,
  };
};
