import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

import { ShareResponse } from 'types/share.types';

import { IcoDownload } from 'components/@icons';

import Tooltip from './components';
import { PopoverStyled } from './ShareTooltip.style';

type Props = {
  title?: string;
  shareData?: ShareResponse;
  anchorEl: (EventTarget & HTMLButtonElement) | null;
  canDownload: boolean;
  isIphoneOrIpad?: boolean;
  isDesktopSafari?: boolean;
  isShareAll?: boolean;
  onClose: () => void;
  onDownload: (shareData: ShareResponse) => void;
  onShareNative: (shareData: ShareResponse) => void;
};

const ShareTooltip: React.FC<Props> = ({
  anchorEl,
  shareData,
  isDesktopSafari,
  isIphoneOrIpad,
  canDownload,
  title,
  onClose,
  onDownload,
  onShareNative,
}) => {
  const open = Boolean(anchorEl);

  return (
    <PopoverStyled
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: -50,
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Tooltip>
        {shareData &&
        (!navigator?.share || isDesktopSafari || isIphoneOrIpad) ? (
          <>
            {isIphoneOrIpad ? (
              <Grid container spacing={4} wrap="nowrap" justifyContent="center">
                {canDownload && (
                  <Grid item>
                    <Box
                      px={3}
                      onClick={() => onDownload(shareData)}
                      justifyContent="center"
                      alignItems="center"
                      display="flex"
                    >
                      <IcoDownload />
                    </Box>
                  </Grid>
                )}
                <Grid item>
                  <Box px={3} mt={1} onClick={() => onShareNative(shareData)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      style={{ transform: 'scale(1.3)' }}
                    >
                      <path
                        fill="white"
                        d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"
                      />
                    </svg>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={4} wrap="nowrap" justifyContent="center">
                <Grid item>
                  <FacebookShareButton title={title} url={shareData.shareUrl}>
                    <FacebookIcon size={32} round={true} />
                  </FacebookShareButton>
                </Grid>
                <Grid item>
                  <TwitterShareButton title={title} url={shareData.shareUrl}>
                    <TwitterIcon size={32} round={true} />
                  </TwitterShareButton>
                </Grid>
                <Grid item>
                  <WhatsappShareButton title={title} url={shareData.shareUrl}>
                    <WhatsappIcon size={32} round={true} />
                  </WhatsappShareButton>
                </Grid>
              </Grid>
            )}
          </>
        ) : (
          <Box px={3}>
            <CircularProgress size={32} color="secondary" />
          </Box>
        )}
      </Tooltip>
    </PopoverStyled>
  );
};

export default ShareTooltip;
