import styled, { css } from 'styled-components';

export const Background = styled.div<{ $hasOverlay?: boolean }>`
  position: absolute;
  top: 0;
  left: -100%;
  right: -100%;
  bottom: -100%;
  background-image: url('/assets/bg-center-table.png');
  background-size: cover;
  border-radius: 50%;
  overflow: hidden;
  z-index: -1;

  ${({ $hasOverlay = true }) =>
    $hasOverlay
      ? css`
          &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 8%;
            bottom: 0;
            background: rgba(255, 255, 255, 0.2);
          }
        `
      : undefined}
`;
