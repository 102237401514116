import * as React from 'react';

function SvgIcoClose(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 29" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 16.021L26.479 28.5 28 26.979 15.521 14.5 28 2.022 26.479.5 14 12.979 1.522.5 0 2.022 12.479 14.5 0 26.979 1.522 28.5 14 16.021z"
        fill="currentColor"
      />
    </svg>
  );
}
const MemoSvgIcoClose = React.memo(SvgIcoClose);
export default MemoSvgIcoClose;
