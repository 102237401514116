import { useQuery } from '@tanstack/react-query';

import { transformDisqualified } from 'utils/game.utils';

import { PLAYER_COUNT } from 'constants/game';
import { QueryKeys, StaleTime } from 'queries';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { Participant } from 'types/game.types';

const getParticipants = async () => {
  const { data: response } = await apiService.getParticipants();

  return response.map((p) => {
    return transformDisqualified(p);
  });
};

export const useParticipants = (
  config: UseCommonQueryOptions<Participant[]> = {},
) => {
  const { data, status, isInitialLoading, error } = useQuery(
    QueryKeys.participants.default(),
    getParticipants,
    {
      ...config,
      staleTime: StaleTime.FOREVER,
    },
  );

  return {
    participants: data ? data.slice(0, PLAYER_COUNT) : undefined,
    status,
    isLoading: isInitialLoading,
    error,
  };
};
