import { Box, IconButton } from '@material-ui/core';
import React, { useEffect } from 'react';

import { EventAction } from 'constants/analytics';
import { useAnalytics } from 'hooks';
import { PredictionType } from 'types/game.types';

import {
  IcoBanishActive,
  IcoBanishInactive,
  IcoMurderActive,
  IcoMurderInactive,
  IcoSurviveActive,
  IcoSurviveInactive,
} from 'components/@icons';

interface Props {
  type: PredictionType;
  onClick: (
    type: Exclude<
      PredictionType,
      PredictionType.Disqualified | PredictionType.Seduced
    >,
  ) => void;
}

const TypeButtons: React.VFC<Props> = ({ type, onClick }) => {
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    if (type === PredictionType.Murdered) {
      trackEvent({ eventAction: EventAction.TopPredictionsMurdered });
    } else if (type === PredictionType.Banished) {
      trackEvent({ eventAction: EventAction.TopPredictionsBanished });
    } else if (type === PredictionType.Survivor) {
      trackEvent({ eventAction: EventAction.TopPredictionsSurvived });
    }
  }, [trackEvent, type]);

  return (
    <Box>
      <IconButton
        disabled={type === PredictionType.Murdered}
        aria-label="selecteer vermoorde verdachte"
        onClick={() => onClick(PredictionType.Murdered)}
      >
        {type === PredictionType.Murdered ? (
          <IcoMurderActive fontSize={68} />
        ) : (
          <IcoMurderInactive fontSize={68} />
        )}
      </IconButton>
      <IconButton
        disabled={type === PredictionType.Banished}
        aria-label="selecteer verbannen verdachte"
        onClick={() => onClick(PredictionType.Banished)}
      >
        {type === PredictionType.Banished ? (
          <IcoBanishActive fontSize={68} />
        ) : (
          <IcoBanishInactive fontSize={68} />
        )}
      </IconButton>
      <IconButton
        disabled={type === PredictionType.Survivor}
        aria-label="selecteer overlevende"
        onClick={() => onClick(PredictionType.Survivor)}
      >
        {type === PredictionType.Survivor ? (
          <IcoSurviveActive fontSize={68} />
        ) : (
          <IcoSurviveInactive fontSize={68} />
        )}
      </IconButton>
    </Box>
  );
};

export default React.memo(TypeButtons);
