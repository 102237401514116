import { Link, LinkProps } from '@material-ui/core';
import React from 'react';

import { EventAction } from 'constants/analytics';
import { useAnalytics, useShare } from 'hooks';
import { useAuth } from 'queries';
import { apiService } from 'services';

import ShareTooltip from '../ShareTooltip';

interface BaseProps extends Omit<LinkProps, 'variant'> {
  className?: string;
}

type Props = BaseProps &
  (
    | { variant: 'score' | 'predictions'; url?: never; competitionId?: never }
    | { variant: 'url'; url: string; competitionId?: never }
    | { variant: 'competition'; competitionId: string; url?: never }
  );

const ShareLinkButton: React.FC<Props> = ({
  variant,
  className,
  children,
  color = 'secondary',
  url,
  competitionId,
  ...rest
}) => {
  const { trackEvent } = useAnalytics();
  const {
    isDesktopSafari,
    isIphoneOrIpad,
    isSafari,
    anchorEl,
    shareData,
    squareImg,
    setAnchorEl,
    setShareData,
    setSquareImg,
    nativeLikeShare,
    handleDownload,
  } = useShare();

  const { userId } = useAuth();
  const canDownload = isSafari && isIphoneOrIpad;
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (variant === 'score') {
      trackEvent({ eventAction: EventAction.ShareScore });
    } else if (variant === 'predictions') {
      trackEvent({ eventAction: EventAction.SharePredictions });
    } else if (variant === 'url') {
      trackEvent({ eventAction: EventAction.MiniCompetitionsShareLink });
    }

    // Open our custom share popover so the user sees a loading state while fetching the share url.
    setAnchorEl(event.currentTarget);

    if (shareData && squareImg && navigator?.share) {
      // Open native share. If it doesn't exist it won't trigger anything.
      // The (non-native) share popover will open because it triggers when an anchor element is set;
      nativeLikeShare(shareData, squareImg);
      return;
    }

    const shareApi = (userId: string) => {
      if (variant === 'score') {
        return apiService.shareLeaderboard(userId);
      } else if (variant === 'competition' && competitionId) {
        return apiService.shareCompetitionLeaderboard(competitionId);
      }

      return apiService.sharePredictions(userId);
    };

    if (url && variant === 'url') {
      if (navigator?.share && !isDesktopSafari && !isIphoneOrIpad) {
        nativeLikeShare({
          shareUrl: url,
          shareImageUrlRect: '',
          shareImageUrlSquare: '',
        });
      } else {
        setShareData({
          shareUrl: url,
          shareImageUrlRect: '',
          shareImageUrlSquare: '',
        });
      }
    } else if (userId) {
      shareApi(userId)
        .then((res) => {
          if (navigator.share !== undefined && !isDesktopSafari) {
            fetch(res.data.shareImageUrlSquare)
              .then((res) => res.blob())
              .then((blob) => {
                setSquareImg(blob);
                setShareData(res.data);

                if (!canDownload) {
                  nativeLikeShare(res.data, blob);
                }
              })
              .catch(() => nativeLikeShare(res.data));
          } else {
            setShareData(res?.data);
          }
        })
        .catch(() => {
          setAnchorEl(null);
        });
    } else {
      setAnchorEl(null);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Link
        component="button"
        // @ts-ignore
        onClick={handleClick}
        color={color}
        className={className}
        {...rest}
      >
        {children}
      </Link>
      <ShareTooltip
        anchorEl={anchorEl}
        shareData={shareData}
        isIphoneOrIpad={isIphoneOrIpad}
        isDesktopSafari={isDesktopSafari}
        canDownload={canDownload}
        onClose={handleClose}
        onShareNative={(data) => nativeLikeShare(data, squareImg)}
        onDownload={handleDownload}
      />
    </>
  );
};

export default ShareLinkButton;
