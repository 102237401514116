import { LeaderboardUser } from 'types/leaderboard.types';

import RestApiService from './';

export default {
  getLeaderboard(this: RestApiService) {
    return this.api.get<LeaderboardUser[]>('/leaderboard');
  },
  getUserLeaderboard(this: RestApiService, userId: string) {
    return this.api.get<LeaderboardUser[]>(`/users/${userId}/leaderboard`);
  },
};
