import { Box, IconButton } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';

import { gameActions } from 'store/game/game.slice';
import { ViewType } from 'types/game.types';

import { IcoArrowLeft, IcoClose } from 'components/@icons';

interface Props {
  onClose?: () => void;
  onBack?: () => void;
}

const ModalContainer: React.FC<Props> = ({ children, onClose, onBack }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(gameActions.SET_VIEW({ type: ViewType.Game }));
  };

  return (
    <Box
      textAlign="left"
      whiteSpace="pre-wrap"
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <Box
        position="absolute"
        right={{ xs: 22, sm: 18 }}
        top={{ xs: 22, sm: 18 }}
        color="global01.500"
        zIndex={15}
      >
        <IconButton color="inherit" onClick={onClose ?? handleClose}>
          <IcoClose />
        </IconButton>
      </Box>
      {onBack && (
        <Box
          position="absolute"
          left={{ xs: 22, sm: 18 }}
          top={{ xs: 16, sm: 8 }}
          color="global01.500"
          zIndex={15}
        >
          <IconButton color="inherit" onClick={onBack}>
            <IcoArrowLeft fontSize={50} />
          </IconButton>
        </Box>
      )}
      {children}
    </Box>
  );
};

export default ModalContainer;
