import {
  Box,
  Container,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useEffect, useRef, VFC } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl-phraseapp';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { isEndOfGame } from 'utils/game.utils';

import { EventAction } from 'constants/analytics';
import { useAnalytics, useIntl } from 'hooks';
import { useRedirectView } from 'hooks/useRedirectView';
import {
  useAuth,
  useParticipants,
  usePredictions,
  useTraitorGuesses,
} from 'queries';
import { getViewParams, getViewType } from 'store/game/game.selectors';
import { gameActions } from 'store/game/game.slice';
import { Store } from 'store/reducers';
import { ViewType } from 'types/game.types';

import { DesktopBoard, MobileBoard } from 'components/@board/Board';
import { RenderState, useRenderState } from 'components/@board/useRenderState';
import ScoreCard from 'components/@cards/ScoreCard';
import SilverCard from 'components/@cards/SilverCard';
import GameInfo from 'components/@home/GameInfo';
import LeaderboardInfo from 'components/@home/LeaderboardInfo';
import MiniCompetitionInfo from 'components/@home/MiniCompetitionInfo';
import { IcoArrowRight } from 'components/@icons';

import { BackToBoardButton, ScoreWrap } from './Home.style';

const Home: VFC = () => {
  useRedirectView();

  const { trackPageView, trackEvent } = useAnalytics();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isLoggedIn, isLoading: isLoadingUser } = useAuth();
  const boardContainerRef = useRef<HTMLDivElement>(null);
  const viewType = useSelector(getViewType);
  const viewParams = useSelector((state: Store) =>
    getViewParams<{ id: string }>(state),
  );

  const { state: renderState } = useRenderState();
  const { isLoading: isLoadingTraitorGuesses } = useTraitorGuesses({
    enabled: renderState === RenderState.GuessTraitors,
  });

  const memberId =
    viewType === ViewType.MemberGame && viewParams?.id
      ? viewParams.id
      : undefined;

  useEffect(() => {
    trackPageView({ path: location.pathname });
  }, [location, trackPageView]);

  const { isLoading: isLoadingPredictions } = usePredictions(memberId);

  const { participants, isLoading: isLoadingParticipants } = useParticipants();

  const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'), {
    noSsr: true,
  });

  const endOfGame = isEndOfGame(participants);

  const scrollToBoard = () => {
    const parentIframe = window.parentIFrame;

    // Scroll the parent page to fit the rules
    if (parentIframe && boardContainerRef.current) {
      parentIframe.scrollToOffset(0, boardContainerRef.current.offsetTop);
    } else if (boardContainerRef.current) {
      window.scrollTo(0, boardContainerRef.current.offsetTop);
    }
  };

  const handleBackToBoard = () => {
    dispatch(gameActions.SET_VIEW({ type: ViewType.Game }));
  };

  const handleShowRules = () => {
    trackEvent({ eventAction: EventAction.MoreInfo });
    dispatch(gameActions.SET_VIEW({ type: ViewType.Rules }));
    setTimeout(() => scrollToBoard(), 100);
  };

  const handleShowMiniCompetitions = () => {
    trackEvent({ eventAction: EventAction.MiniCompetitionsShow });
    dispatch(gameActions.SET_VIEW({ type: ViewType.MiniCompetitionOverview }));
    setTimeout(() => scrollToBoard(), 100);
  };

  const handleShowLeaderboard = () => {
    trackEvent({ eventAction: EventAction.Leaderboard });
    dispatch(gameActions.SET_VIEW({ type: ViewType.Leaderboard }));
    setTimeout(() => scrollToBoard(), 100);
  };

  const intl = useIntl();

  const showLoading =
    isLoadingParticipants ||
    isLoadingPredictions ||
    isLoadingUser ||
    isLoadingTraitorGuesses;

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: 'home.seo.title' })}</title>
        <meta name="description" content="VTM - De Verraders" />
      </Helmet>
      <Box bgcolor="neutral06.500" py={8} textAlign="center" overflow="hidden">
        <Container maxWidth="md">
          <Box mx={4}>
            <Typography variant="h2">
              <FormattedMessage id="home.title" />
            </Typography>
            <Box mt={2} mb={5} maxWidth={350} margin="auto">
              <Typography variant="body1">
                <FormattedMessage
                  id="home.description"
                  values={{
                    b: (v: string) => <b>{v}</b>,
                  }}
                />
              </Typography>
            </Box>
          </Box>
          <Box mx={4} mt={isLoggedIn ? 10 : 20}>
            {isLoggedIn && (
              <MiniCompetitionInfo
                onShowMiniCompetitions={handleShowMiniCompetitions}
              />
            )}
          </Box>
          <Box
            mx={{ xs: 0, sm: 4 }}
            mt={{ xs: 8, sm: 8 }}
            id="board"
            ref={boardContainerRef}
            position="relative"
          >
            {isLoggedIn && viewType === ViewType.MemberGame && (
              <BackToBoardButton onClick={handleBackToBoard}>
                <Typography variant="h3" color="textSecondary">
                  <FormattedMessage id="game.general.back_my_board.button" />
                </Typography>
                <IcoArrowRight fontSize={28} style={{ marginLeft: 16 }} />
              </BackToBoardButton>
            )}

            {isXs ? (
              <MobileBoard
                participants={participants}
                isLoading={showLoading}
              />
            ) : (
              <Box position="relative">
                <DesktopBoard
                  participants={participants}
                  isLoading={showLoading}
                />
                {!isLoadingParticipants &&
                  !endOfGame &&
                  renderState !== RenderState.GuessTraitors &&
                  isLoggedIn &&
                  viewType !== ViewType.MemberGame && (
                    <ScoreWrap>
                      <ScoreCard />
                    </ScoreWrap>
                  )}
              </Box>
            )}
          </Box>
          <Box
            mx={{ xs: 0, md: 4 }}
            mt={isXs ? -4 : { xs: 4, md: -8 }}
            mb={10}
            zIndex={10}
            position="relative"
          >
            <SilverCard
              silverCount={5} // Not coming from api anymore: gameData?.silverCount
              lostSilverCount={0} // Not coming from api anymore: gameData?.silverLost
            />
          </Box>
          <Box
            px={4}
            pt={8}
            display="flex"
            justifyContent="center"
            alignItems="stretch"
            flexDirection={{ xs: 'column', sm: 'row' }}
          >
            <Box py={{ xs: 10, sm: 0 }} mx={{ xs: 0, sm: 4 }}>
              <GameInfo onShowRules={handleShowRules} />
            </Box>
            {isLoggedIn && (
              <LeaderboardInfo onShowLeaderboard={handleShowLeaderboard} />
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Home;
