import axios, { AxiosRequestConfig } from 'axios';
import humps from 'humps';

import { isDev } from 'utils/env.utils';

import store from 'store';
import { getLocale } from 'store/intl/intl.selectors';

const DEFAULT_CONFIG: AxiosRequestConfig = {
  headers: { 'Content-Type': 'application/json' },
  transformRequest: [
    (data) => {
      if (!(data instanceof FormData)) {
        // convert all body keys to snake_case
        data = humps.decamelizeKeys(data);

        // convert all body objects to strings
        if (typeof data === 'object') {
          data = JSON.stringify(data);
        }
      }

      return data;
    },
  ],
  transformResponse: [
    (data) => {
      try {
        // convert all body keys to camelCase
        if (typeof data !== 'object' && data) {
          data = JSON.parse(data);
        }
        if (!(data instanceof Blob)) {
          data = humps.camelizeKeys(data, (key, convert) => {
            return /^[A-Z0-9_]+$/.test(key) ? key : convert(key);
          });
        }
        return data;
      } catch (error) {
        return humps.camelizeKeys(data, (key, convert) => {
          return /^[A-Z0-9_]+$/.test(key) ? key : convert(key);
        });
      }
    },
  ],
};

const createManager = (requestConfig?: AxiosRequestConfig) => {
  const manager = axios.create({ ...DEFAULT_CONFIG, ...requestConfig });

  manager.interceptors.request.use(
    async (axiosConfig) => {
      const state = store.store.getState();

      if (axiosConfig.headers) {
        const locale = getLocale(state);
        axiosConfig.headers['Accept-Language'] = locale.toUpperCase();

        if (isDev()) {
          axiosConfig.headers['X-FAKE-USER-ID'] =
            'cb6dab51-8769-4a49-8505-7b224c871872';
        }
      }

      return axiosConfig;
    },
    (error) => Promise.reject(error),
  );

  return manager;
};

export { createManager };
