import React from 'react';

import { EventAction } from 'constants/analytics';
import { useAnalytics } from 'hooks';
import { useAuth } from 'queries';

import DesktopLoggedOutState from './Desktop';
import MobileLoggedOutState from './Mobile';

interface Props {
  mobile?: boolean;
}

const LoggedOutState: React.VFC<Props> = ({ mobile = false }) => {
  const { login } = useAuth();
  const { trackEvent } = useAnalytics();

  const handlePlayClick = () => {
    trackEvent({ eventAction: EventAction.Play });
    login();
  };

  if (mobile) {
    return <MobileLoggedOutState onPlay={handlePlayClick} />;
  }
  return <DesktopLoggedOutState onPlay={handlePlayClick} />;
};

export default LoggedOutState;
