import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isPredictionClosed } from 'utils/game.utils';

import { PLAYER_COUNT } from 'constants/game';
import { useIntl } from 'hooks';
import {
  useCreatePrediction,
  useCreateTraitorGuess,
  useDeletePrediction,
  useGameData,
  useScoreBreakdown,
} from 'queries';
import { useTraitorGuesses } from 'queries/user/useTraitorGuesses';
import {
  getCurrentSelecting,
  getCurrentTraitorsSelecting,
  getPredictions,
  getPredictionsPositions,
  getViewParams,
  getViewType,
} from 'store/game/game.selectors';
import { gameActions } from 'store/game/game.slice';
import { Store } from 'store/reducers';
import { Participant, PredictionType, ViewType } from 'types/game.types';

import BoardAvatar from '../BoardAvatar';
import BoardSvgPiece from '../BoardSvgPiece';
import { AnimationWrap, ButtonWrap } from './BoardUserPiece.style';

interface Props {
  idx: number;
  participant: Participant;
  disableRotate?: boolean;
  disableInteraction?: boolean;
  disableAnimation?: boolean;
}

const BoardUserPiece: React.VFC<Props> = ({
  idx,
  participant,
  disableRotate,
  disableInteraction,
  disableAnimation = false,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isHovering, setIsHovering] = useState(false);

  const viewType = useSelector(getViewType);
  const viewParams = useSelector((state: Store) =>
    getViewParams<{ id: string }>(state),
  );
  const memberId =
    viewType === ViewType.MemberGame && viewParams?.id
      ? viewParams.id
      : undefined;

  const { gameData } = useGameData();

  const { scoreForParticipant } = useScoreBreakdown(memberId);
  const { createPrediction } = useCreatePrediction();
  const { createTraitorGuess } = useCreateTraitorGuess();
  const { deletePrediction } = useDeletePrediction();

  const positions = useSelector((state: Store) =>
    getPredictionsPositions(state, participant.id),
  );
  const predictions = useSelector(getPredictions);
  const selecting = useSelector(getCurrentSelecting);
  const selectingTraitors = useSelector(getCurrentTraitorsSelecting);
  const { traitorGuesses } = useTraitorGuesses({
    enabled: !!selectingTraitors,
  });

  const hasHover = (!!selecting || !!selectingTraitors) && isHovering;

  let isSelected = false;
  if (selecting) {
    switch (selecting.type) {
      case PredictionType.Murdered:
        isSelected = positions.murdered === selecting.position;
        break;
      case PredictionType.Banished:
        isSelected = positions.banished === selecting.position;
        break;
      case PredictionType.Survivor:
        isSelected = positions.survivor === selecting.position;
        break;
    }
  } else if (selectingTraitors) {
    isSelected = Boolean(
      traitorGuesses?.find((g) => g.participantId === participant.id),
    );
  }

  const handleSelectUser = () => {
    if (selecting) {
      // Check if we are editing an survivor
      if (
        selecting.type === PredictionType.Survivor &&
        (predictions.survivor.find((p) => p.position === selecting.position) ||
          (isSelected && hasHover))
      ) {
        dispatch(
          gameActions.REQUEST_SURVIVOR_CONFIRM({
            participantId: participant.id,
            position: selecting.position,
            delete: isSelected && hasHover,
          }),
        );
        return;
      }

      const postData = {
        participantId: participant.id,
        position: selecting.position,
        predictionType: selecting.type,
      };

      if (isSelected && hasHover) {
        deletePrediction(postData);
      } else {
        createPrediction(postData);
      }
    } else if (selectingTraitors) {
      createTraitorGuess({
        participantId: participant.id,
        position: selectingTraitors.position,
      });
      dispatch(gameActions.SET_TRAITORS_SELECTING(undefined));
    }
  };

  const disabledTraitor =
    selecting?.type === PredictionType.Murdered && participant.isTraitor;

  const isSelecting = !!selecting || !!selectingTraitors;

  let revealedState: PredictionType | undefined = undefined;
  if (participant.isBanished) {
    revealedState = PredictionType.Banished;
  } else if (participant.isMurdered) {
    revealedState = PredictionType.Murdered;
  } else if (participant.isSurvivor) {
    revealedState = PredictionType.Survivor;
  } else if (participant.isDisqualified) {
    revealedState = PredictionType.Disqualified;
  }

  const isSurvivor = revealedState === PredictionType.Survivor;

  const isCorrect =
    participant.isDisqualified || isSurvivor
      ? undefined
      : scoreForParticipant(participant.id)?.isCorrect;

  let blockPrediction = true;
  if (
    (selecting && gameData && !isPredictionClosed(gameData, selecting.type)) ||
    selectingTraitors
  ) {
    blockPrediction = false;
  }

  const disableClick =
    !isSelecting ||
    disabledTraitor ||
    !!revealedState ||
    blockPrediction ||
    disableInteraction;

  let title: string | undefined = undefined;
  if (disabledTraitor) {
    title = intl.formatMessage(
      { id: 'game.hover.traitor' },
      { name: participant.firstName },
    );
  } else if (revealedState === PredictionType.Disqualified) {
    title = intl.formatMessage(
      { id: 'game.hover.disqualified' },
      { name: participant.firstName },
    );
  } else if (!!revealedState && viewType !== ViewType.MemberGame) {
    title = intl.formatMessage(
      {
        id: `game.hover.${
          isCorrect ? 'correct' : 'wrong'
        }.${revealedState.toLowerCase()}`,
      },
      { name: participant.firstName },
    );
  }

  return (
    <ButtonWrap
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onFocus={() => setIsHovering(true)}
      onBlur={() => setIsHovering(false)}
      onClick={handleSelectUser}
      disabled={disableClick}
      title={title}
    >
      <Box
        width={{ xs: '75%', md: '87%' }}
        paddingBottom="75%"
        position="relative"
        zIndex={10}
      >
        <AnimationWrap
          position="absolute"
          width="100%"
          top={0}
          $idx={idx}
          $disableAnimation={disableAnimation}
        >
          <BoardAvatar
            width="100%"
            label={participant.firstName}
            imageUrl={participant.imageUrl}
            style={{
              transform: disableRotate
                ? undefined
                : `rotate(${-idx * (360 / PLAYER_COUNT)}deg)`,
            }}
            active={(hasHover || isSelected) && !disableClick}
            selected={isSelected && !disableClick}
            disabled={disabledTraitor || (!!revealedState && !isSurvivor)}
            revealedType={revealedState}
            isDeathRow={participant.isDeathRow}
            canDelete={!selectingTraitors}
          />
        </AnimationWrap>
      </Box>
      <BoardSvgPiece
        banishedPosition={positions.banished}
        murdererPosition={positions.murdered}
        survivorPosition={positions.survivor}
        selectingType={selecting?.type}
        active={(hasHover || isSelected) && !disableClick}
        disabled={disabledTraitor || (!!revealedState && !isSurvivor)}
        isCorrect={isCorrect}
      />
    </ButtonWrap>
  );
};

export default React.memo(BoardUserPiece);
