import { Box, Typography } from '@material-ui/core';
import React from 'react';

import {
  ButtonBaseStyled,
  Check,
  Radio,
  UnChecked,
} from './QuestionAnswerButton.style';

interface Props {
  selected?: boolean;
  onChange: (selected: boolean) => void;
  correct?: boolean | null;
  disabled?: boolean;
  radio?: boolean;
}
const QuestionAnswerButton: React.FC<Props> = ({
  disabled = false,
  children,
  onChange,
  selected = false,
  correct,
  radio = true,
}) => {
  return (
    <ButtonBaseStyled
      disabled={disabled && !selected}
      $correct={correct}
      $selected={selected}
      onClick={() => onChange(!selected)}
    >
      {selected ? (
        <Box
          color={
            correct !== null
              ? correct
                ? 'green.500'
                : 'red.500'
              : 'global01.500'
          }
        >
          {radio ? <Radio checked disabled $correct={correct} /> : <Check />}
        </Box>
      ) : (
        <>{radio ? <Radio disabled $correct={correct} /> : <UnChecked />}</>
      )}
      <Box ml={3}>
        <Typography variant="h5">{children}</Typography>
      </Box>
    </ButtonBaseStyled>
  );
};

export default QuestionAnswerButton;
