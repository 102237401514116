import * as React from 'react';

function SvgIcoCheckGreen(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" fill="none" {...props}>
      <g filter="url(#ico-check-green_svg__filter0_d_511_560459)">
        <rect x={2} width={24} height={24} rx={12} fill="#93AD29" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.669 11.082a421.188 421.188 0 013.894 3.523S16.94 8.3 21.947 5.333l.371 1.113c-1.854 1.112-7.417 7.417-9.457 12.98 0 0-5.748-5.563-6.861-6.86l1.669-1.484z"
          fill="#fff"
        />
      </g>
      <defs>
        <filter
          id="ico-check-green_svg__filter0_d_511_560459"
          x={0}
          y={0}
          width={28}
          height={28}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={1} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.667795 0 0 0 0 0.637751 0 0 0 0 0.590538 0 0 0 0.5 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_511_560459"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_511_560459"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
const MemoSvgIcoCheckGreen = React.memo(SvgIcoCheckGreen);
export default MemoSvgIcoCheckGreen;
