import * as React from 'react';

function SvgIcoMurderIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.922 11.835v-1.549a5.103 5.103 0 10-5.84 0v1.55h5.84zM4.859 6.798a1.246 1.246 0 112.49 0 1.246 1.246 0 01-2.49 0zm2.196 2.87a.412.412 0 11.824 0 .412.412 0 01-.824 0zm1.482.412a.412.412 0 110-.824.412.412 0 010 .823zm.117-3.282a1.245 1.245 0 112.49 0 1.245 1.245 0 01-2.49 0zM1.605 12.67c0 .26.135.487.337.618a.734.734 0 00.4 1.353c.405 0 .735-.33.735-.736h9.851a.736.736 0 101.135-.617.735.735 0 10-1.135-.618H3.077a.736.736 0 10-1.472 0z"
        fill="currentColor"
      />
    </svg>
  );
}
const MemoSvgIcoMurderIcon = React.memo(SvgIcoMurderIcon);
export default MemoSvgIcoMurderIcon;
