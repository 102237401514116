import {
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { FormattedPlural } from 'react-intl';
import { FormattedMessage } from 'react-intl-phraseapp';

import fallbackAvatar from 'assets/png/img-fallback-avatar.png';
import imgPointsLoose from 'assets/png/img-points-loose.png';
import imgPointsWin from 'assets/png/img-points-win.png';

import { PREDICTION_COLORS } from 'constants/game';
import {
  Breakdown,
  Participant,
  ScoreBreakdownEventType,
} from 'types/game.types';

import BoardAvatar from 'components/@board/BoardAvatar';
import {
  IcoBanishIcon,
  IcoBonus,
  IcoFlagScore,
  IcoMurderIcon,
  IcoSurviveIcon,
} from 'components/@icons';

interface Props {
  participant?: Participant;
  breakdown: Breakdown;
}

const COLORS = {
  ...PREDICTION_COLORS,
  [ScoreBreakdownEventType.BonusQuestion]: 'green.500',
  [ScoreBreakdownEventType.PollQuestion]: 'green.500',
};

const ScoreBreakdownListItem: React.VFC<Props> = ({
  participant,
  breakdown,
}) => {
  const correctColor =
    breakdown.type === ScoreBreakdownEventType.BonusQuestion ||
    breakdown.type === ScoreBreakdownEventType.PollQuestion
      ? COLORS[ScoreBreakdownEventType.BonusQuestion]
      : 'global01.500';

  return (
    <ListItem disableGutters style={{ padding: '8px 0' }}>
      <ListItemAvatar style={{ margin: 0, marginRight: 16 }}>
        <Box position="relative">
          <BoardAvatar
            imageUrl={
              participant && breakdown.type !== ScoreBreakdownEventType.Seduced
                ? participant.imageUrl
                : fallbackAvatar
            }
            alt={participant ? participant.firstName : 'avatar'}
            width={52}
            height={52}
          />
          {breakdown.type !== ScoreBreakdownEventType.Disqualified &&
            breakdown.type !== ScoreBreakdownEventType.Seduced && (
              <Box position="absolute" bottom={-10} right={-4}>
                <Box position="relative" width={24}>
                  <Box color={COLORS[breakdown.type]} clone>
                    <IcoFlagScore width="100%" height="100%" />
                  </Box>
                  <Box
                    color="neutral01.500"
                    position="absolute"
                    textAlign="center"
                    top={6}
                    left={0}
                    right={0}
                    fontSize={14}
                  >
                    {breakdown.type === ScoreBreakdownEventType.Murdered && (
                      <IcoMurderIcon />
                    )}
                    {breakdown.type === ScoreBreakdownEventType.Banished && (
                      <IcoBanishIcon />
                    )}
                    {breakdown.type === ScoreBreakdownEventType.Survivor && (
                      <IcoSurviveIcon />
                    )}
                    {(breakdown.type ===
                      ScoreBreakdownEventType.BonusQuestion ||
                      breakdown.type ===
                        ScoreBreakdownEventType.PollQuestion) && <IcoBonus />}
                  </Box>
                </Box>
              </Box>
            )}
        </Box>
      </ListItemAvatar>
      <ListItemText>
        <Typography variant="h4" color="textPrimary">
          <FormattedMessage
            id={`score.breakdown.${breakdown.type.toLowerCase()}`}
            values={{ name: participant?.firstName ?? '' }}
          />
        </Typography>
        {breakdown.type !== ScoreBreakdownEventType.Disqualified && (
          <Box
            color={breakdown.event.isCorrect ? correctColor : 'neutral05.500'}
          >
            <Typography variant="h6" color="inherit">
              {breakdown.event.isCorrect ? (
                breakdown.type === ScoreBreakdownEventType.BonusQuestion ||
                breakdown.type === ScoreBreakdownEventType.PollQuestion ||
                breakdown.type === ScoreBreakdownEventType.Seduced ? (
                  <FormattedMessage
                    id={
                      breakdown.type === ScoreBreakdownEventType.PollQuestion
                        ? 'score.breakdown.poll'
                        : 'score.breakdown.bonus.correct'
                    }
                  />
                ) : breakdown.type === ScoreBreakdownEventType.Survivor &&
                  breakdown.event.correctVoteStreakInWeeks &&
                  breakdown.event.correctVoteStreakInWeeks > 0 ? (
                  <FormattedPlural
                    value={breakdown.event.correctVoteStreakInWeeks}
                    one={
                      <FormattedMessage id="score.breakdown.weeks.correct.one" />
                    }
                    other={
                      <FormattedMessage
                        id="score.breakdown.weeks.correct.other"
                        values={{
                          count: breakdown.event.correctVoteStreakInWeeks,
                        }}
                      />
                    }
                  />
                ) : (
                  <FormattedMessage
                    id="score.breakdown.correct"
                    values={{ position: breakdown.event.position }}
                  />
                )
              ) : (
                <FormattedMessage id="score.breakdown.wrong" />
              )}
            </Typography>
          </Box>
        )}
      </ListItemText>
      {breakdown.type !== ScoreBreakdownEventType.Disqualified && (
        <Box display="flex" position="relative" width={55} height={55}>
          <img
            src={breakdown.event.points > 0 ? imgPointsWin : imgPointsLoose}
            width="100%"
            height="100%"
            alt={`score is ${breakdown.event.points}`}
          />
          <Box
            position="absolute"
            top={0}
            right={0}
            left={0}
            bottom={0}
            fontSize={16}
            display="flex"
            justifyContent="center"
            alignItems="center"
            component="span"
          >
            <Typography variant="h4" color="textPrimary">
              {breakdown.event.points}
            </Typography>
          </Box>
        </Box>
      )}
    </ListItem>
  );
};

export default ScoreBreakdownListItem;
