import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';
import { useDispatch, useSelector } from 'react-redux';

import { EventAction } from 'constants/analytics';
import { useAnalytics } from 'hooks';
import {
  getFallbackSelecting,
  getPredictionsCounts,
} from 'store/game/game.selectors';
import { gameActions } from 'store/game/game.slice';

import Button from 'components/@common/Button';
import StatsButton from 'components/@common/StatsButton/StatsButton';
import {
  IcoBanishIcon,
  IcoMurderIcon,
  IcoShare,
  IcoSurviveIcon,
} from 'components/@icons';
import ShareLinkButton from 'components/@share/ShareLinkButton';

const IncompleteSelectingState: React.VFC = () => {
  const { trackEvent } = useAnalytics();
  const dispatch = useDispatch();
  const selection = useSelector(getFallbackSelecting);
  const { murdered, banished, survivor } = useSelector(getPredictionsCounts);

  const handleStartSelecting = () => {
    trackEvent({ eventAction: EventAction.StartChangeSuspects });
    dispatch(gameActions.SET_SELECTING(selection));
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="100%"
      width="100%"
    >
      <Typography variant="h3" color="textSecondary">
        <FormattedMessage id="game.incomplete_selection.title" />
      </Typography>
      <Box pt={2} maxWidth={280} clone>
        <Typography variant="body1" color="textSecondary">
          <FormattedMessage id="game.incomplete_selection.description" />
        </Typography>
      </Box>
      <Box display="flex" my={4}>
        <Box color="red.500" display="flex">
          <IcoMurderIcon fontSize={24} style={{ transform: 'scaleX(-1)' }} />
          <Box pl={2} clone>
            <Typography variant="h5" component="span">
              {Math.max(3 - murdered, 0)}
            </Typography>
          </Box>
        </Box>
        <Box color="blue.400" display="flex" mx={8}>
          <IcoBanishIcon fontSize={24} />
          <Box pl={2} clone>
            <Typography variant="h5" component="span">
              {Math.max(3 - banished, 0)}
            </Typography>
          </Box>
        </Box>
        <Box color="global01.400" display="flex">
          <IcoSurviveIcon fontSize={24} />
          <Box pl={2} clone>
            <Typography variant="h5" component="span">
              {Math.max(3 - survivor, 0)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <StatsButton />
      <Box my={4} clone>
        <Button variant="primary" onClick={handleStartSelecting}>
          <FormattedMessage id="game.no_selection.play.button" />
        </Button>
      </Box>
      <ShareLinkButton variant="predictions">
        <IcoShare fontSize={14} style={{ marginRight: 4 }} />
        <FormattedMessage id="game.share.predictions.button" />
      </ShareLinkButton>
    </Box>
  );
};

export default IncompleteSelectingState;
