import * as React from 'react';

function SvgIcoDeathRow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 28" fill="none" {...props}>
      <path
        d="M8.993 3.637l-1.974.436V.25h1.974v3.387zm5.495 12.644l-2.928-5.132-2.073.437 3.257 5.835c.954 1.744.954 3.79-.033 5.533-.987 1.744-2.73 2.784-4.705 2.784-1.974 0-3.718-1.04-4.705-2.75-.987-1.744-.987-3.79-.033-5.534l2.863-5.13-2.633.57h-.033l-1.94 3.386c-1.35 2.382-1.383 5.232 0 7.647 1.348 2.38 3.783 3.823 6.481 3.823s5.133-1.442 6.482-3.823c1.35-2.381 1.35-5.232 0-7.646zM3.235 11.585l9.28-1.978c.427-.1.756-.503.756-.973a.896.896 0 00-.362-.738.974.974 0 00-.592-.2c-.066 0-.132 0-.198.033L3.5 9.573l-.658.134c-.428.068-.758.47-.758.94 0 .301.132.57.362.737.23.201.494.268.79.201zm0-3.353l9.28-1.979c.427-.1.756-.503.756-.972a.896.896 0 00-.362-.738.974.974 0 00-.592-.201c-.066 0-.132 0-.198.033L2.841 6.354c-.428.067-.757.47-.757.939 0 .302.131.57.361.738.23.2.494.268.79.2z"
        fill="#fff"
      />
    </svg>
  );
}
const MemoSvgIcoDeathRow = React.memo(SvgIcoDeathRow);
export default MemoSvgIcoDeathRow;
