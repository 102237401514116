import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';
import { useDispatch } from 'react-redux';

import { EventAction } from 'constants/analytics';
import { useAnalytics } from 'hooks';
import { gameActions } from 'store/game/game.slice';
import { PredictionType } from 'types/game.types';

import Button from 'components/@common/Button';
import StatsButton from 'components/@common/StatsButton';
import { IcoShare } from 'components/@icons';
import ShareLinkButton from 'components/@share/ShareLinkButton';

const CompleteSelectingState: React.VFC = () => {
  const { trackEvent } = useAnalytics();
  const dispatch = useDispatch();

  const handleStartSelecting = () => {
    trackEvent({ eventAction: EventAction.StartChangeSuspects });

    dispatch(
      gameActions.SET_SELECTING({ type: PredictionType.Murdered, position: 1 }),
    );
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="100%"
      width="100%"
    >
      <Typography variant="h3" color="textSecondary">
        <FormattedMessage id="game.complete_selection.title" />
      </Typography>
      <Box pt={2} pb={6} clone>
        <Typography variant="body1" color="textSecondary">
          <FormattedMessage id="game.complete_selection.description" />
        </Typography>
      </Box>
      <StatsButton />
      <Box my={4} clone>
        <Button variant="primary" onClick={handleStartSelecting}>
          <FormattedMessage id="game.complete_selection.switch.button" />
        </Button>
      </Box>
      <ShareLinkButton variant="predictions">
        <IcoShare fontSize={14} style={{ marginRight: 4 }} />
        <FormattedMessage id="game.share.predictions.button" />
      </ShareLinkButton>
    </Box>
  );
};

export default CompleteSelectingState;
