import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { QueryKeys } from 'queries';
import { apiService } from 'services';
import { Competition } from 'types/competition.types';

const putCompetitionName = async (competitionId: string, name: string) => {
  const { data: response } = await apiService.putCompetitionName(
    competitionId,
    name,
  );
  return response;
};

export const useEditCompetitionName = (
  config: UseMutationOptions<
    Competition,
    AxiosError,
    { competitionId: string; name: string }
  > = {},
) => {
  const queryClient = useQueryClient();

  const { mutate, data, isLoading, error } = useMutation(
    ({ competitionId, name }) => putCompetitionName(competitionId, name),
    {
      ...config,
      onSuccess: async (data, vars, context) => {
        config.onSuccess?.(data, vars, context);
        await queryClient.cancelQueries(QueryKeys.competitions.all());

        queryClient.setQueryData<Competition[] | undefined>(
          QueryKeys.competitions.all(),
          (old) => {
            let finalData: Competition[] = [...(old ?? [])];
            const competition = finalData.find(
              (c) => c.id === vars.competitionId,
            );

            if (competition) {
              competition.name = vars.name;
            }

            return finalData;
          },
        );
      },
    },
  );

  return {
    editName: mutate,
    competition: data,
    isLoading,
    error,
  };
};
