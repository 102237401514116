import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from 'queries';
import { StaleTime } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { BonusQuestion } from 'types/bonus.types';

const getBonusQuestion = async () => {
  const { data: response } = await apiService.getBonusQuestion();
  return response;
};

export const useBonusQuestion = (
  config: UseCommonQueryOptions<BonusQuestion> = {},
) => {
  const { data, status, isInitialLoading, error } = useQuery(
    QueryKeys.bonus.default(),
    () => getBonusQuestion(),
    {
      ...config,
      staleTime: config?.staleTime ?? StaleTime.ONE_HOUR,
      retry: false,
    },
  );

  return {
    bonusQuestion: data,
    status,
    isLoading: isInitialLoading,
    error,
  };
};
