import storage from 'redux-persist/lib/storage';
import { createFilter } from 'redux-persist-transform-filter';

import config from 'config';

import { generateStore } from 'services/redux';

import combinedReducers from './reducers';
import sagas from './sagas';
import { systemActions } from './system/system.slice';

const gameSubset = createFilter('game', ['predictions']);

const store = generateStore(sagas, combinedReducers, {
  initApp: async (store) => {
    store.dispatch(systemActions.SYSTEM_START());
  },
  enableDevTools: Boolean(config.ENABLE_DEV_TOOLS),
  persistConfig: {
    key: 'root',
    keyPrefix: '',
    storage,
    version: 2,
    debug: Boolean(config.ENABLE_DEV_TOOLS),
    whitelist: ['intl', 'auth', 'game'],
    transforms: [gameSubset],
  },
});

export default store;
