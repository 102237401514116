import { ButtonBaseProps } from '@material-ui/core';
import { ButtonBase } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

import Loader from '../Loader';
import { BaseProps } from './Button';

type StyledButtonProps = { $fullWidth?: boolean };

const ButtonStyled = styled(ButtonBase)<StyledButtonProps>`
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'unset')};
  height: 56px;

  position: relative;

  flex-shrink: 0;

  padding-left: 16px;
  padding-right: 16px;

  text-transform: uppercase;
  line-height: 1;
  font-size: 16px;
  font-family: Butler, sans-serif;
  color: ${({ theme }) => theme.palette.neutral01[500]};

  border-style: solid;
  border-width: 1px;
  border-image: ${({ theme }) => theme.palette.gradient01.main} 1;
  background-color: ${({ theme }) => theme.palette.neutral03[500]};

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: 4px;
    right: 4px;
    border-style: solid;
    border-width: 1px;
    border-image: ${({ theme }) => theme.palette.gradient01.main} 1;
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.palette.neutral01[500]};

    &::before {
      border: 1px solid ${({ theme }) => theme.palette.neutral01[500]};
    }

    @media (hover: none) {
      border-style: solid;
      border-width: 1px;
      border-image: ${({ theme }) => theme.palette.gradient01.main} 1;
      background-color: ${({ theme }) => theme.palette.neutral03[500]};

      &::before {
        border-style: solid;
        border-width: 1px;
        border-image: ${({ theme }) => theme.palette.gradient01.main} 1;
      }
    }
  }

  &.Mui-disabled {
    color: ${({ theme }) => theme.palette.neutral04[500]};
    border: 1px solid ${({ theme }) => theme.palette.neutral04[500]};

    &::before {
      border: 1px solid ${({ theme }) => theme.palette.neutral04[500]};
    }
  }
` as React.ComponentType<ButtonBaseProps & StyledButtonProps>;

const ButtonPrimary: React.FC<BaseProps> = ({
  children,
  loading,
  disabled,
  fullWidth,
  ...rest
}) => {
  return (
    <ButtonStyled
      $fullWidth={fullWidth}
      disabled={loading || disabled}
      {...rest}
    >
      {loading ? <Loader fontSize={42} /> : children}
    </ButtonStyled>
  );
};

export default ButtonPrimary;
