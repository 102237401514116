import 'utils/polyfill.utils';

import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import ReactDOM from 'react-dom';

import config from 'config';

import { runInDev, runInProd } from 'utils/env.utils';

import App from './App';

runInProd(() =>
  Sentry.init({
    ...config.SENTRY,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.2,
  }),
);

runInDev(() => {
  // Enable to start mocking!
  // const { worker } = require('./mocks/browser');
  // worker.start({ onUnhandledRequest: 'bypass' });
});

ReactDOM.render(<App />, document.getElementById('root'));
