import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { QueryKeys } from 'queries';
import { apiService } from 'services';
import {
  BonusQuestion,
  BonusQuestionAnswer,
  BonusQuestionAnswerResponse,
} from 'types/bonus.types';

type BonusQuestionPostData = {
  questionId: BonusQuestion['id'];
  answerIds: BonusQuestionAnswer['id'][];
};

const postBonusQuestionAnswer = async (data: BonusQuestionPostData) => {
  const { data: response } = await apiService.postAnswerBonusQuestion(data);
  return response;
};

export const useAnswerBonusQuestion = (
  config: UseMutationOptions<
    BonusQuestionAnswerResponse[],
    AxiosError,
    BonusQuestionPostData
  > = {},
) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    (data) => postBonusQuestionAnswer(data),
    {
      ...config,
      onSuccess: async (data) => {
        queryClient.setQueryData<BonusQuestion>(
          QueryKeys.bonus.default(),
          (old) => {
            return {
              ...old,
              pointsScored:
                data.find((d) => d.pointsScored > 0)?.pointsScored ?? 0,
              userAnsweredCorrectly: data.some((d) => d.pointsScored > 0),
            } as BonusQuestion;
          },
        );
        queryClient.invalidateQueries(QueryKeys.scoreBreakDown.all());
        queryClient.invalidateQueries(QueryKeys.leaderboard.all());
      },
    },
  );
  return {
    answer: mutate,
    isLoading,
  };
};
