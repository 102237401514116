import * as React from 'react';

function SvgIcoDownload(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M19.333 15.167H23.5L16 23.5l-7.5-8.333h4.167V6h6.666v9.167zm1.834 9.166H10.833V26h10.334v-1.667z"
        fill="#fff"
      />
    </svg>
  );
}
const MemoSvgIcoDownload = React.memo(SvgIcoDownload);
export default MemoSvgIcoDownload;
