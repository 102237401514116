import { Popover } from '@material-ui/core';
import styled from 'styled-components';

export const PopoverStyled = styled(Popover).attrs({
  classes: { root: 'root', paper: 'paper' },
})`
  &.root {
    .paper {
      margin-top: ${({ theme }) => theme.spacing(10)}px;
      overflow: visible;
    }
  }
`;
