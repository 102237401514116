import { Box, Link, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';
import { useDispatch } from 'react-redux';

import imgPointsWin from 'assets/png/img-points-win.png';

import { EventAction } from 'constants/analytics';
import { useAnalytics } from 'hooks';
import { useParticipants, useScoreBreakdown } from 'queries';
import { gameActions } from 'store/game/game.slice';
import { ViewType } from 'types/game.types';

import { IcoShare } from 'components/@icons';
import ShareLinkButton from 'components/@share/ShareLinkButton';

const FinalState: React.VFC = () => {
  const { trackEvent } = useAnalytics();
  const { participants } = useParticipants();
  const dispatch = useDispatch();
  const { scoreBreakdown } = useScoreBreakdown();
  const handleOverview = () => {
    trackEvent({ eventAction: EventAction.ScoreBreakdown });
    dispatch(
      gameActions.SET_VIEW({
        type: ViewType.ScoreBreakdown,
      }),
    );
  };

  const survivorNames =
    participants?.filter((p) => p.isSurvivor).map((p) => p.firstName) ?? [];

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      width="100%"
      height="100%"
      mx="auto"
      maxWidth={{ xs: 280, sm: '100%' }}
    >
      <>
        <Typography variant="h2" color="textSecondary">
          <FormattedMessage id="game.final_state.title" />
        </Typography>
        <Box pt={2} clone>
          <Typography variant="body1" color="textSecondary">
            <FormattedMessage
              id="game.final_state.names.label"
              values={{
                names: survivorNames
                  .slice(0, survivorNames.length - 1)
                  .join(', '),
                name: survivorNames[survivorNames.length - 1],
              }}
            />
          </Typography>
        </Box>
        {scoreBreakdown && (
          <>
            <Box display="flex" position="relative" width={114} height={114}>
              <img
                src={imgPointsWin}
                width="100%"
                height="100%"
                alt={`score is ${scoreBreakdown.totalScore}`}
              />
              <Box
                position="absolute"
                top={0}
                right={0}
                left={0}
                bottom={0}
                fontSize={16}
                display="flex"
                justifyContent="center"
                alignItems="center"
                component="span"
              >
                <Typography variant="h3" color="textPrimary">
                  {scoreBreakdown.totalScore}
                </Typography>
              </Box>
            </Box>
            <ShareLinkButton variant="score">
              <IcoShare fontSize={14} style={{ marginRight: 4 }} />
              <FormattedMessage id="score.card.share" />
            </ShareLinkButton>
            <Box pt={4}>
              <Link
                component="button"
                color="secondary"
                onClick={handleOverview}
              >
                <FormattedMessage id="score.card.score_overview" />
              </Link>
            </Box>
          </>
        )}
      </>
    </Box>
  );
};

export default FinalState;
