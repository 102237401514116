import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

import { transformDisqualifiedScore } from 'utils/game.utils';

import { QueryKeys, useAuth } from 'queries';
import { StaleTime } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { queryClient } from 'services/react-query';
import {
  ScoreBreakdown,
  ScoreBreakdownEvent,
  ScoreBreakdownEventType,
} from 'types/game.types';

const getScoreBreakdown = async (userId: string) => {
  const { data: response } = await apiService.getScoreBreakdown(userId);
  return {
    ...response,
    breakdown: response.breakdown.map((b) => transformDisqualifiedScore(b)),
  };
};

export const prefetchScoreBreakdown = async (memberId: string) => {
  try {
    await queryClient.prefetchQuery(
      QueryKeys.scoreBreakDown.byId(memberId),
      () => getScoreBreakdown(memberId),
      { staleTime: StaleTime.FIVE_MIN },
    );
  } catch {}
};

export const useScoreBreakdown = (
  memberId?: string,
  config: UseCommonQueryOptions<ScoreBreakdown> = { enabled: true },
) => {
  const { userId } = useAuth();
  const id = memberId ?? userId;
  const { data, status, isInitialLoading, error } = useQuery(
    QueryKeys.scoreBreakDown.byId(id ?? ''),
    // We can disable it because the query isn't enabled without a valid userId
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => getScoreBreakdown(id!),
    {
      ...config,
      enabled: !!id && config.enabled,
      staleTime:
        config?.staleTime ??
        (memberId ? StaleTime.FIVE_MIN : StaleTime.FOREVER),
    },
  );

  const scoreForParticipant = useCallback(
    (participantId: string) => {
      const score = data?.breakdown.find(
        (b) =>
          b.type !== ScoreBreakdownEventType.BonusQuestion &&
          b.type !== ScoreBreakdownEventType.PollQuestion &&
          b.event.participantId === participantId,
      );
      return score?.event as ScoreBreakdownEvent | undefined;
    },
    [data?.breakdown],
  );

  return {
    scoreForParticipant,
    scoreBreakdown: data,
    status,
    isLoading: isInitialLoading,
    error,
  };
};
