import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { QueryKeys } from 'queries';
import { apiService } from 'services';
import { Competition } from 'types/competition.types';

const postCompetition = async (name: string) => {
  const { data: response } = await apiService.postCompetition(name);
  return response;
};

export const useCreateCompetition = (
  config: UseMutationOptions<Competition, AxiosError, string> = {},
) => {
  const queryClient = useQueryClient();

  const { mutate, data, isLoading, error } = useMutation(
    (name) => postCompetition(name),
    {
      ...config,
      onSuccess: async (data, vars, context) => {
        config.onSuccess?.(data, vars, context);
        await queryClient.cancelQueries(QueryKeys.competitions.all());

        queryClient.setQueryData<Competition[] | undefined>(
          QueryKeys.competitions.all(),
          (old) => {
            let finalData: Competition[] = [...(old ?? [])];
            finalData.push(data);
            return finalData;
          },
        );
      },
    },
  );

  return {
    createCompetition: mutate,
    competition: data,
    isLoading,
    error,
  };
};
