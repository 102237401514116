import * as React from 'react';

function SvgIcoBanish(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 39" fill="none" {...props}>
      <path d="M0 0h28v28L13.5 39 0 28V0z" fill="#002E64" />
      <path
        d="M14.408 22.139c-1.469 0-2.94-.559-4.058-1.677a5.747 5.747 0 010-8.117 5.747 5.747 0 018.117 0 5.747 5.747 0 010 8.117 5.727 5.727 0 01-4.059 1.677zm0-10.199a4.452 4.452 0 00-3.156 1.305 4.471 4.471 0 000 6.314 4.471 4.471 0 006.313 0 4.471 4.471 0 000-6.314 4.452 4.452 0 00-3.157-1.305z"
        fill="#fff"
      />
      <path
        d="M18.016 20.648a.641.641 0 01-.452-.186l-7.215-7.216a.638.638 0 01.901-.902l7.216 7.216a.638.638 0 01-.45 1.088z"
        fill="#fff"
      />
    </svg>
  );
}
const MemoSvgIcoBanish = React.memo(SvgIcoBanish);
export default MemoSvgIcoBanish;
