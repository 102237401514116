import styled from 'styled-components';

import borderBg from 'assets/jpg/bg-board-border-tile.jpg';

export const Border = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: ${({ theme }) => theme.spacing(2)}px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background: url(${borderBg});
    background-size: 5%;
    z-index: -1;
    border: ${({ theme }) => theme.spacing(2)}px solid rgba(255, 255, 255, 0.5);
    border-radius: ${({ theme }) => theme.shape.radius(4)}px;
    box-shadow: 0px 2px 2px rgba(170, 163, 151, 0.5);
  }
`;
