import { List } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';

import { useParticipants, useScoreBreakdown } from 'queries';
import { ScoreBreakdownEventType } from 'types/game.types';

import ScoreBreakdownListItem from 'components/@list/ScoreBreakdownListItem';

import { ModalContainer, ModalScrollContent, ModalTitle } from '../Modal';

const ScoreBreakdownModal: React.VFC = () => {
  const { scoreBreakdown } = useScoreBreakdown();
  const { participants } = useParticipants();
  return (
    <ModalContainer>
      <ModalTitle>
        <FormattedMessage id="score.breakdown.title" />
      </ModalTitle>
      {scoreBreakdown && (
        <ModalScrollContent>
          <List>
            {scoreBreakdown.breakdown.map((b, idx) => (
              <ScoreBreakdownListItem
                key={idx}
                breakdown={b}
                participant={
                  b.type === ScoreBreakdownEventType.BonusQuestion ||
                  b.type === ScoreBreakdownEventType.PollQuestion
                    ? undefined
                    : participants?.find((p) => p.id === b.event.participantId)
                }
              />
            ))}
          </List>
        </ModalScrollContent>
      )}
    </ModalContainer>
  );
};

export default ScoreBreakdownModal;
