import { Box, Link, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import { FormattedMessage } from 'react-intl-phraseapp';
import { useDispatch, useSelector } from 'react-redux';

import imgPositionBronze from 'assets/png/img-position-bronze.png';
import imgPositionGold from 'assets/png/img-position-gold.png';
import imgPositionSilver from 'assets/png/img-position-silver.png';

import { getViewParams } from 'store/game/game.selectors';
import { gameActions } from 'store/game/game.slice';
import { Store } from 'store/reducers';
import { Competition, CompetitionUser } from 'types/competition.types';
import { ViewType } from 'types/game.types';

const MemberGameState: React.VFC = () => {
  const dispatch = useDispatch();
  const { member, competition } =
    useSelector((state: Store) =>
      getViewParams<{
        id: string;
        member: CompetitionUser;
        competition: Competition;
      }>(state),
    ) ?? {};

  const position = competition
    ? competition.members.findIndex((m) => m.id === member?.id) + 1
    : -1;

  let positionImg = imgPositionSilver;
  if (position === 1) {
    positionImg = imgPositionGold;
  } else if (position === 2) {
    positionImg = imgPositionSilver;
  } else if (position === 3) {
    positionImg = imgPositionBronze;
  }

  const handleOverview = () => {
    dispatch(
      gameActions.SET_VIEW({
        type: ViewType.MiniCompetitionDetail,
        params: { id: competition?.id },
      }),
    );
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      width="100%"
      height="100%"
    >
      {member && competition && (
        <>
          <Typography variant="h2" color="textSecondary">
            {`${member.firstName} ${member.lastNameInitials}`}
          </Typography>
          <Box pt={2} color="global01.400" clone>
            <Typography variant="h5">{competition.name}</Typography>
          </Box>
          {position >= 0 && (
            <Box my={4} position="relative">
              <img
                alt={`positie ${position + 1}`}
                src={positionImg}
                width="100%"
                height="100%"
                style={{
                  filter:
                    position > 3 ? `grayscale(1) brightness(0.7)` : undefined,
                }}
              />
              <Box
                position="absolute"
                top={0}
                right={0}
                bottom={0}
                left={0}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h1">{position}</Typography>
              </Box>
            </Box>
          )}
          <Typography variant="h3" color="textSecondary">
            <FormattedMessage
              id="mini_competition.detail.list.points.label"
              values={{ score: <FormattedNumber value={member.score} /> }}
            />
          </Typography>
          <Box pt={4}>
            <Link component="button" color="secondary" onClick={handleOverview}>
              <FormattedMessage id="game.general.back_competition_overview.button" />
            </Link>
          </Box>
        </>
      )}
    </Box>
  );
};

export default MemberGameState;
