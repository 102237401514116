import { Box, Link, List, Typography } from '@material-ui/core';
import _orderBy from 'lodash/orderBy';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';
import { useDispatch, useSelector } from 'react-redux';

import { EventAction } from 'constants/analytics';
import { useAnalytics } from 'hooks';
import { useAuth } from 'queries';
import {
  prefetchPredictions,
  prefetchScoreBreakdown,
  useCompetitions,
} from 'queries';
import { getViewParams } from 'store/game/game.selectors';
import { gameActions } from 'store/game/game.slice';
import { Store } from 'store/reducers';
import { CompetitionUser } from 'types/competition.types';
import { ViewType } from 'types/game.types';

import Loader from 'components/@common/Loader';
import CompetitionUserListItem from 'components/@list/CompetitionUserListItem';

// import ShareLinkButton from 'components/@share/ShareLinkButton';
// import { IcoShare } from 'components/@icons';
import { ModalContainer, ModalScrollContent, ModalTitle } from '../Modal';

const MiniCompetitionDetailModal: React.VFC = () => {
  const { trackEvent } = useAnalytics();
  const [activeMemberId, setActiveMemberId] = useState<string>();

  const dispatch = useDispatch();
  const { userId } = useAuth();

  const viewParams = useSelector((state: Store) =>
    getViewParams<{ id: string }>(state),
  );

  const { competitions, isLoading } = useCompetitions();

  const competition = competitions?.find((c) => c.id === viewParams?.id);

  const admin = competition?.members.find(
    (m) => m.id === competition.adminUserId,
  );

  const sortedMembers = _orderBy(
    competition?.members,
    ['score', 'firstName'],
    ['desc', 'asc'],
  );

  const handleBack = () => {
    dispatch(gameActions.SET_VIEW({ type: ViewType.MiniCompetitionOverview }));
  };

  const handleUserDetail = async (member: CompetitionUser) => {
    trackEvent({ eventAction: EventAction.MiniCompetitionsPlayerView });

    setActiveMemberId(member.id);

    await prefetchPredictions(member.id);
    await prefetchScoreBreakdown(member.id);

    dispatch(
      gameActions.SET_VIEW({
        type: ViewType.MemberGame,
        params: {
          id: member.id,
          member,
          competition: { ...competition, members: sortedMembers },
        },
      }),
    );
  };

  const handleEdit = async () => {
    dispatch(
      gameActions.SET_VIEW({
        type: ViewType.MiniCompetitionEdit,
        params: { id: viewParams?.id },
      }),
    );
  };

  return (
    <ModalContainer onBack={handleBack}>
      <ModalTitle>
        {competition?.name ?? <FormattedMessage id="mini_competition.title" />}
      </ModalTitle>
      <ModalScrollContent>
        {isLoading && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Loader fontSize={126} variant="beige" />
          </Box>
        )}
        {competition && !isLoading && (
          <Box
            textAlign="center"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box maxWidth={350} clone>
              <Typography variant="body1">
                <FormattedMessage id="mini_competition.detail.description" />
              </Typography>
            </Box>
            {/* <Box my={4}>
              <ShareLinkButton
                variant="competition"
                competitionId={competition.id}
                color="primary"
              >
                <IcoShare fontSize={14} style={{ marginRight: 4 }} />
                <FormattedMessage id="mini_competition.detail.share.button" />
              </ShareLinkButton>
            </Box> */}
            <List>
              {sortedMembers.map((member, idx) => (
                <CompetitionUserListItem
                  key={member.id}
                  name={`${member.firstName} ${member.lastNameInitials}`}
                  score={member.score}
                  position={idx + 1}
                  currentUser={member.id === userId}
                  onClick={
                    !activeMemberId ? () => handleUserDetail(member) : undefined
                  }
                  loading={member.id === activeMemberId}
                />
              ))}
            </List>
            {admin && (
              <Box
                pt={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                {admin.id !== userId ? (
                  <>
                    <Typography variant="h4">
                      <FormattedMessage
                        id="mini_competition.detail.no_admin.title"
                        values={{ name: admin.firstName }}
                      />
                    </Typography>
                    <Box pt={4} clone>
                      <Typography variant="body2">
                        <FormattedMessage
                          id="mini_competition.detail.no_admin.description"
                          values={{ name: admin.firstName }}
                        />
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <>
                    <Typography variant="h4">
                      <FormattedMessage id="mini_competition.detail.admin.title" />
                    </Typography>
                    <Box py={4} clone>
                      <Typography variant="body2">
                        <FormattedMessage id="mini_competition.detail.admin.description" />
                      </Typography>
                    </Box>
                    <Link component="button" onClick={handleEdit}>
                      <FormattedMessage id="mini_competition.detail.admin.edit.button" />
                    </Link>
                  </>
                )}
              </Box>
            )}
          </Box>
        )}
      </ModalScrollContent>
    </ModalContainer>
  );
};

export default MiniCompetitionDetailModal;
