import * as React from 'react';

function SvgIcoButtonArrowLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 24" fill="none" {...props}>
      <path d="M1.827 12.452h17v-1.378h-17v1.378z" fill="#A9811B" />
      <path
        fill="#A9811B"
        d="M8.478 3.38L0 11.858l.935.987 8.478-8.477zM20.833 8.024l-3.834 3.834.935.987 3.834-3.834z"
      />
      <path
        fill="#A9811B"
        d="M9.455 19.645l-8.46-8.694-.949.975 8.46 8.694zM21.796 14.856l-3.8-3.905-.948.975 3.8 3.904z"
      />
    </svg>
  );
}
const MemoSvgIcoButtonArrowLeft = React.memo(SvgIcoButtonArrowLeft);
export default MemoSvgIcoButtonArrowLeft;
