import * as React from 'react';

function SvgIcoTrash(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" {...props}>
      <g clipPath="url(#ico-trash_svg__clip0_610_648770)">
        <path
          d="M5.5 9.378l3.5 21h14l3.5-21h-21zM27.668 4.71v2.334H4.334V4.71h6.663c1.05 0 1.903-1.282 1.903-2.333h6.202c0 1.051.851 2.333 1.902 2.333h6.663z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="ico-trash_svg__clip0_610_648770">
          <path fill="#fff" transform="translate(2 2.378)" d="M0 0h28v28H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
const MemoSvgIcoTrash = React.memo(SvgIcoTrash);
export default MemoSvgIcoTrash;
