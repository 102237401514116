import {
  Box,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';
import { useSelector } from 'react-redux';

import { getPredictionsPositions } from 'store/game/game.selectors';
import { Store } from 'store/reducers';
import { Participant, PredictionType } from 'types/game.types';

import BoardAvatar from 'components/@board/BoardAvatar';
import Flag from 'components/@common/Flag/Flag';

interface Props {
  participant: Participant;
  disableGutters?: boolean;
  dividerColor?: string;
  color?: string;
  activeType?: PredictionType;
  activePosition?: number;
  onClick?: () => void;
}

const UserListItem: React.VFC<Props> = ({
  participant,
  disableGutters,
  dividerColor,
  color = 'neutral01.500',
  activePosition,
  activeType,
  onClick,
}) => {
  const positions = useSelector((state: Store) =>
    getPredictionsPositions(state, participant.id),
  );

  let revealedState: PredictionType | undefined = undefined;
  if (participant.isBanished) {
    revealedState = PredictionType.Banished;
  } else if (participant.isMurdered) {
    revealedState = PredictionType.Murdered;
  } else if (participant.isSurvivor) {
    revealedState = PredictionType.Survivor;
  } else if (participant.isDisqualified) {
    revealedState = PredictionType.Disqualified;
  }

  let isActive = false;
  if (activeType === PredictionType.Banished) {
    isActive = positions.banished === activePosition;
  } else if (activeType === PredictionType.Murdered) {
    isActive = positions.murdered === activePosition;
  } else if (activeType === PredictionType.Survivor) {
    isActive = positions.survivor === activePosition;
  }

  return (
    <>
      <ListItem
        disableGutters={disableGutters}
        style={{ opacity: isActive ? 0.5 : 1 }}
        // @ts-ignore
        button={!!onClick}
        onClick={onClick}
        disabled={isActive}
      >
        <ListItemAvatar>
          <BoardAvatar
            imageUrl={participant.imageUrl}
            alt={participant.firstName}
            width={52}
            height={52}
            style={{
              boxShadow: '0px 0px 4px rgba(255, 255, 255, 0.65)',
            }}
            disabled={!!revealedState && !participant.isSurvivor}
            revealedType={revealedState}
            isDeathRow={participant.isDeathRow}
          />
        </ListItemAvatar>
        <ListItemText>
          <Box color={color}>
            <Typography variant="h3">{participant.firstName}</Typography>
            {!!revealedState && (
              <Typography variant="body2">
                <FormattedMessage
                  id={`home.participant.list.reveal.${revealedState.toLowerCase()}`}
                />
              </Typography>
            )}
          </Box>
        </ListItemText>
        <Box display="flex" mr={4}>
          {(!activeType || activeType === PredictionType.Murdered) &&
            positions.murdered && (
              <Flag type={PredictionType.Murdered} value={positions.murdered} />
            )}
          {(!activeType || activeType === PredictionType.Banished) &&
            positions.banished && (
              <Flag type={PredictionType.Banished} value={positions.banished} />
            )}
          {(!activeType || activeType === PredictionType.Survivor) &&
            positions.survivor && <Flag type={PredictionType.Survivor} />}
        </Box>
      </ListItem>

      <Divider
        variant="middle"
        component="li"
        style={{ backgroundColor: dividerColor }}
      />
    </>
  );
};

export default UserListItem;
