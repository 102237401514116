import * as React from 'react';

function SvgIcoBanishActive(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 36 49" fill="none" {...props}>
      <path
        d="M4 2.811H2v30.95l.737.6 13.5 11 1.219.994 1.253-.95 14.5-11 .791-.6V2.81H4z"
        fill="#002E64"
        stroke="#fff"
        strokeWidth={4}
        strokeMiterlimit={10}
      />
      <path
        d="M18.407 26.95c-1.469 0-2.94-.558-4.058-1.677a5.747 5.747 0 010-8.117 5.747 5.747 0 018.117 0 5.747 5.747 0 010 8.117 5.728 5.728 0 01-4.059 1.677zm0-10.199a4.452 4.452 0 00-3.156 1.305 4.471 4.471 0 000 6.314 4.471 4.471 0 006.313 0 4.471 4.471 0 000-6.314 4.451 4.451 0 00-3.157-1.305z"
        fill="#fff"
      />
      <path
        d="M22.016 25.459a.64.64 0 01-.452-.186l-7.215-7.216a.638.638 0 01.901-.902l7.216 7.216a.638.638 0 01-.45 1.088z"
        fill="#fff"
      />
    </svg>
  );
}
const MemoSvgIcoBanishActive = React.memo(SvgIcoBanishActive);
export default MemoSvgIcoBanishActive;
