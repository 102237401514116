import * as React from 'react';

function SvgIcoSurviveActive(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 36 49" fill="none" {...props}>
      <path
        d="M4 2.811H2v30.95l.737.6 13.5 11 1.219.994 1.253-.95 14.5-11 .791-.6V2.81H4z"
        fill="url(#ico-survive-active_svg__paint0_linear_131_196503)"
      />
      <path
        d="M4 2.811H2v30.95l.737.6 13.5 11 1.219.994 1.253-.95 14.5-11 .791-.6V2.81H4z"
        fill="url(#ico-survive-active_svg__paint1_linear_131_196503)"
      />
      <path
        d="M4 2.811H2v30.95l.737.6 13.5 11 1.219.994 1.253-.95 14.5-11 .791-.6V2.81H4z"
        stroke="#fff"
        strokeWidth={4}
        strokeMiterlimit={10}
      />
      <path
        d="M16.741 19.068l1.879-3.884 1.879 3.884 4.643-3.776-2.06 7.967h-8.924l-2.06-7.967 4.643 3.776zM24.166 23.8H13.092v1.085h11.074V23.8z"
        fill="#BEAA78"
      />
      <defs>
        <linearGradient
          id="ico-survive-active_svg__paint0_linear_131_196503"
          x1={12.303}
          y1={9.241}
          x2={11.008}
          y2={7.426}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D7B55D" />
          <stop offset={1} stopColor="#A9811B" />
        </linearGradient>
        <linearGradient
          id="ico-survive-active_svg__paint1_linear_131_196503"
          x1={10.956}
          y1={7.428}
          x2={30.918}
          y2={36.345}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#FEEDC0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
const MemoSvgIcoSurviveActive = React.memo(SvgIcoSurviveActive);
export default MemoSvgIcoSurviveActive;
