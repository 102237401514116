import * as React from 'react';

function SvgIcoFlagScore(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 32" fill="none" {...props}>
      <path d="M0 0h24v22.974L11.571 32 0 22.974V0z" fill="currentColor" />
    </svg>
  );
}
const MemoSvgIcoFlagScore = React.memo(SvgIcoFlagScore);
export default MemoSvgIcoFlagScore;
