import { Color, SimplePaletteColorOptions } from '@material-ui/core';

export const global01: Color = {
  50: '',
  100: '',
  200: '',
  300: '',
  400: '#DDC895',
  500: '#A9811B',
  600: '#5E5140',
  700: '',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const neutral01: Color = {
  50: '',
  100: '',
  200: '',
  300: '',
  400: '',
  500: '#ffffff',
  600: '',
  700: '',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const neutral02: Color = {
  50: '',
  100: '',
  200: '',
  300: '',
  400: '',
  500: '#000000',
  600: '',
  700: '',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const neutral03: Color = {
  50: '',
  100: '',
  200: '',
  300: '',
  400: '',
  500: '#060F1D',
  600: '',
  700: '',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const neutral04: Color = {
  50: '',
  100: '',
  200: '',
  300: '',
  400: '',
  500: '#1C2A49',
  600: '',
  700: '',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const neutral05: Color = {
  50: '',
  100: '',
  200: '',
  300: '',
  400: '',
  500: '#878787',
  600: '',
  700: '',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const neutral06: Color = {
  50: '',
  100: '',
  200: '',
  300: '',
  400: '',
  500: '#F3F3F3',
  600: '',
  700: '',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const neutral07: Color = {
  50: '',
  100: '',
  200: '',
  300: '#DEC086',
  400: '#FFD48B',
  500: '#E2DBCA',
  600: '#DAD3C8',
  700: '#F0EDE5',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const blue: Color = {
  50: '',
  100: '',
  200: '',
  300: '',
  400: '#74A3D9',
  500: '#002E64',
  600: '',
  700: '',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const green: Color = {
  50: '',
  100: '',
  200: '',
  300: '',
  400: '',
  500: '#93AD29',
  600: '',
  700: '',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const red: Color = {
  50: '',
  100: '',
  200: '',
  300: '',
  400: '',
  500: '#D62E00',
  600: '',
  700: '',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const gradient01: SimplePaletteColorOptions = {
  main: 'linear-gradient(90deg, #7D6C56 0%, #F7CB78 76.39%)',
  dark: 'linear-gradient(90deg, #7D6C56 0%, #F7CB78 76.39%)',
  light: 'linear-gradient(90deg, #7D6C56 0%, #F7CB78 76.39%)',
  contrastText: '#FFFFFF',
};

export const gradient02: SimplePaletteColorOptions = {
  main: 'linear-gradient(153.64deg, #FFFFFF 12.72%, #FEEDC0 85.92%), linear-gradient(332.88deg, #D7B55D 82.44%, #A9811B 87.09%)',
  dark: 'linear-gradient(153.64deg, #FFFFFF 12.72%, #FEEDC0 85.92%), linear-gradient(332.88deg, #D7B55D 82.44%, #A9811B 87.09%)',
  light:
    'linear-gradient(153.64deg, #FFFFFF 12.72%, #FEEDC0 85.92%), linear-gradient(332.88deg, #D7B55D 82.44%, #A9811B 87.09%)',
  contrastText: '#FFFFFF',
};
