import type { GameData, Participant, PredictionStats } from 'types/game.types';

import RestApiService from './';

export default {
  getParticipants(this: RestApiService) {
    return this.api.get<Participant[]>('/participants', { authDisabled: true });
  },
  getGameData(this: RestApiService) {
    return this.api.get<GameData>('/game-data', { authDisabled: true });
  },
  getStats(this: RestApiService) {
    return this.api.get<PredictionStats>('/predictions/stats', {
      authDisabled: true,
    });
  },
};
