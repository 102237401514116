import { Box, Link, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';

import { useUserLeaderboard } from 'queries';

import Loader from 'components/@common/Loader';
import { IcoArrowRight } from 'components/@icons';

import { CenterBar, LeftBar, RightBar } from './LeaderboardInfo.style';

interface Props {
  onShowLeaderboard: () => void;
}

const LeaderboardInfo: React.VFC<Props> = ({ onShowLeaderboard }) => {
  const { user, isLoading } = useUserLeaderboard();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      flex={1}
    >
      <Box>
        <Typography variant="h3">
          <FormattedMessage id="leaderboard.user.title" />
        </Typography>
        <Box py={6} maxWidth={350} clone>
          <Typography variant="body2">
            <FormattedMessage id="leaderboard.user.description" />
          </Typography>
        </Box>
      </Box>
      <Box display="flex" width="100%" maxWidth={350} height={63} mb={6}>
        <LeftBar />
        <CenterBar>
          {user && !isLoading && (
            <Typography variant="h2">{user.position}</Typography>
          )}
          {(isLoading || !user) && <Loader fontSize={32} variant="white" />}
        </CenterBar>
        <RightBar />
      </Box>
      <Link component="button" onClick={onShowLeaderboard}>
        <FormattedMessage id="leaderboard.user.full_leaderboard.button" />
        <IcoArrowRight
          color="global01.500"
          width={28}
          height={15}
          style={{ paddingLeft: 8 }}
        />
      </Link>
    </Box>
  );
};

export default LeaderboardInfo;
