import { Box, Link, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';
import { useDispatch } from 'react-redux';

import { useCreatePrediction, useDeletePrediction } from 'queries';
import { gameActions, GameState } from 'store/game/game.slice';
import { Participant, Prediction, PredictionType } from 'types/game.types';

import Button from 'components/@common/Button';
import { IcoButtonArrowLeft, IcoButtonArrowRight } from 'components/@icons';

import AvatarButton from './components/AvatarButton';
import SeducedButton from './components/SeducedButton';
import SelectModal from './components/SelectModal';
import TypeButtons from './components/TypeButtons';
import { Background } from './Mobile.style';

interface Props {
  onBack: () => void;
  onChangePredictionType: (type: PredictionType) => void;
  positionPredictions: (Prediction | undefined)[];
  participants?: Participant[];
  closedPrediction?: boolean;
  selecting: {
    type: PredictionType;
    position: number;
  };
  survivorConfirm: GameState['surivorConfirm'];
  onConfirm: (confirm: boolean) => void;
}

const Mobile: React.VFC<Props> = ({
  onBack,
  onConfirm,
  onChangePredictionType,
  positionPredictions,
  participants,
  selecting,
  survivorConfirm,
  closedPrediction = true,
}) => {
  const dispatch = useDispatch();

  const { createPrediction } = useCreatePrediction();
  const { deletePrediction } = useDeletePrediction();
  const [modalSelectType, setModalSelectType] = useState<{
    type: PredictionType;
    position: number;
  }>();

  const filteredParticipants = (
    selecting.type === PredictionType.Murdered
      ? participants?.filter((p) => !p.isTraitor)
      : participants
  )?.filter((p) => !p.isBanished && !p.isMurdered && !p.isDisqualified);

  const handleNext = () => {
    if (selecting.type === PredictionType.Murdered) {
      dispatch(
        gameActions.SET_SELECTING({
          type: PredictionType.Banished,
          position: 1,
        }),
      );
    } else if (selecting.type === PredictionType.Banished) {
      dispatch(
        gameActions.SET_SELECTING({
          type: PredictionType.Survivor,
          position: 1,
        }),
      );
    } else {
      onBack();
    }
  };

  const handleBack = () => {
    if (selecting.type === PredictionType.Murdered) {
      onBack();
    } else if (selecting.type === PredictionType.Banished) {
      dispatch(
        gameActions.SET_SELECTING({
          type: PredictionType.Murdered,
          position: 3,
        }),
      );
    } else if (selecting.type === PredictionType.Survivor) {
      dispatch(
        gameActions.SET_SELECTING({
          type: PredictionType.Banished,
          position: 3,
        }),
      );
    }
  };

  const handleSelectUser = (participantId: string, position: number) => {
    const currentParticipant = positionPredictions[position - 1]?.participantId;

    if (currentParticipant && selecting.type === PredictionType.Survivor) {
      const isDelete = !participantId.length;
      dispatch(
        gameActions.REQUEST_SURVIVOR_CONFIRM({
          participantId: isDelete ? currentParticipant : participantId,
          position: position,
          delete: isDelete,
        }),
      );
      return;
    }

    if (!participantId.length) {
      if (currentParticipant) {
        const postData = {
          participantId: currentParticipant,
          position: position,
          predictionType: selecting.type,
        };

        deletePrediction(postData);
      }
    } else {
      const postData = {
        participantId: participantId,
        position: position,
        predictionType: selecting.type,
      };
      createPrediction(postData);
    }
  };

  return (
    <Box
      width="100%"
      overflow="hidden"
      zIndex={10}
      pb={16}
      display="flex"
      flexDirection="column"
      alignItems="center"
      position="relative"
    >
      {survivorConfirm ? (
        <Box
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Link component="button" onClick={() => onConfirm(false)}>
            <FormattedMessage id="game.select.confirm.back.button" />
          </Link>
          <Box position="relative" mt={5} pt={10} width="100%">
            <Background $hasOverlay={false} />
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              justifyContent="space-around"
              alignItems="center"
              minHeight={300}
              px={4}
            >
              <Typography variant="h3">
                <FormattedMessage id="game.select.confirm.title" />
              </Typography>
              <Typography variant="body1">
                <FormattedMessage id="game.select.confirm.description" />
              </Typography>
              <Box display="flex">
                <Box mr={4}>
                  <Button variant="outlined" onClick={() => onConfirm(false)}>
                    <FormattedMessage id="game.select.confirm.action.cancel" />
                  </Button>
                </Box>
                <Button variant="outlined" onClick={() => onConfirm(true)}>
                  <FormattedMessage id="game.select.confirm.action.confirm" />
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <Link component="button" onClick={onBack} color="secondary">
            <FormattedMessage id="game.general.back_overview.button" />
          </Link>
          <Box position="relative" mt={5} pt={10} width="100%">
            <Background />
            <Box mt={4}>
              <TypeButtons
                onClick={onChangePredictionType}
                type={selecting.type}
              />
            </Box>
            <Box px={4}>
              {closedPrediction ? (
                <Box my={10} color="global01.600">
                  <Typography variant="h3">
                    <FormattedMessage
                      id={`game.select.blocked.reveal.${selecting.type.toLowerCase()}.title`}
                    />
                  </Typography>
                  <Box pt={3} clone>
                    <Typography variant="body2">
                      <FormattedMessage
                        id={`game.select.blocked.reveal.${selecting.type.toLowerCase()}.description`}
                        values={{ b: (v: string) => <b>{v}</b> }}
                      />
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <>
                  <Box py={5} clone>
                    <Typography variant="h3">
                      <FormattedMessage
                        id={`game.select.${selecting.type.toLowerCase()}.title`}
                      />
                    </Typography>
                  </Box>
                  <Box pb={5} clone>
                    <Typography variant="body1">
                      <FormattedMessage
                        id={`game.select.${selecting.type.toLowerCase()}.subtitle.mobile`}
                      />
                    </Typography>
                  </Box>

                  {filteredParticipants && (
                    <Box
                      px={4}
                      width="100%"
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <AvatarButton
                        position={1}
                        onClick={() =>
                          setModalSelectType({
                            type: selecting.type,
                            position: 1,
                          })
                        }
                        participant={filteredParticipants.find(
                          (p) => p.id === positionPredictions[0]?.participantId,
                        )}
                        type={selecting.type}
                      />
                      <Box mx={4}>
                        <AvatarButton
                          position={2}
                          onClick={() =>
                            setModalSelectType({
                              type: selecting.type,
                              position: 2,
                            })
                          }
                          participant={filteredParticipants.find(
                            (p) =>
                              p.id === positionPredictions[1]?.participantId,
                          )}
                          type={selecting.type}
                        />
                      </Box>
                      <AvatarButton
                        position={3}
                        onClick={() =>
                          setModalSelectType({
                            type: selecting.type,
                            position: 3,
                          })
                        }
                        participant={filteredParticipants.find(
                          (p) => p.id === positionPredictions[2]?.participantId,
                        )}
                        type={selecting.type}
                      />
                    </Box>
                  )}
                  {selecting.type === PredictionType.Murdered && (
                    <Box mt={6}>
                      <SeducedButton />
                    </Box>
                  )}
                </>
              )}

              <Box display="flex" mt={6} width="100%" justifyContent="center">
                <Box mr={4} clone>
                  <Button variant="outlined" onClick={handleBack}>
                    <IcoButtonArrowLeft fontSize={24} />
                  </Button>
                </Box>
                <Button variant="secondary" onClick={handleNext}>
                  <FormattedMessage
                    id={`game.select.${selecting.type.toLowerCase()}.button.next.mobile`}
                  />
                  &nbsp;
                  <IcoButtonArrowRight fontSize={24} />
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {filteredParticipants && !!modalSelectType && (
        <SelectModal
          onClose={() => setModalSelectType(undefined)}
          type={modalSelectType.type}
          position={modalSelectType.position}
          participants={filteredParticipants}
          onSelect={(p) => handleSelectUser(p.id, modalSelectType.position)}
        />
      )}
    </Box>
  );
};

export default Mobile;
