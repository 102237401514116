import type { Competition } from 'types/competition.types';

import RestApiService from './';

export default {
  getCompetitions(this: RestApiService, userId: string) {
    return this.api.get<Competition[]>(`users/${userId}/competitions`);
  },
  getCompetition(this: RestApiService, competitionId: string) {
    return this.api.get<Competition>(`/competitions/${competitionId}`);
  },
  postCompetition(this: RestApiService, name: string) {
    return this.api.post<Competition>(`/competitions`, { name });
  },
  putCompetitionName(
    this: RestApiService,
    competitionId: string,
    name: string,
  ) {
    return this.api.put<Competition>(`/competitions/${competitionId}`, {
      name,
    });
  },
  postJoinCompetition(this: RestApiService, competitionId: string) {
    return this.api.post(`competitions/${competitionId}/members`);
  },
  deleteCompetitionMember(
    this: RestApiService,
    competitionId: string,
    memberId: string,
  ) {
    return this.api.delete(`competitions/${competitionId}/members/${memberId}`);
  },
};
