import * as React from 'react';

function SvgIcoFlag(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 49" fill="none" {...props}>
      <path d="M0 0h28v38L13.5 49 0 38V0z" fill="currentColor" />
    </svg>
  );
}
const MemoSvgIcoFlag = React.memo(SvgIcoFlag);
export default MemoSvgIcoFlag;
