import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isPredictionClosed } from 'utils/game.utils';

import {
  useCreatePrediction,
  useDeletePrediction,
  useGameData,
  useParticipants,
} from 'queries';
import {
  getCurrentSelecting,
  getPredictions,
  getSurvivorConfirm,
} from 'store/game/game.selectors';
import { gameActions } from 'store/game/game.slice';
import {
  findMissingPosition,
  getPredictionsByType,
} from 'store/game/game.utils';
import { PredictionType } from 'types/game.types';

import Desktop from './Desktop';
import Mobile from './Mobile';

interface Props {
  mobile?: boolean;
}
const SelectingState: React.VFC<Props> = ({ mobile = false }) => {
  const dispatch = useDispatch();
  const predictions = useSelector(getPredictions);
  const survivorConfirm = useSelector(getSurvivorConfirm);
  const { gameData } = useGameData();
  const { createPrediction } = useCreatePrediction();
  const { deletePrediction } = useDeletePrediction();
  const { participants } = useParticipants();
  const selecting = useSelector(getCurrentSelecting);

  const handleBack = () => {
    dispatch(gameActions.COMPLETE_SURVIVOR_CONFIRM());
    dispatch(gameActions.SET_SELECTING(undefined));
  };

  const handleConfirm = (confirm: boolean) => {
    if (confirm && survivorConfirm) {
      const data = {
        participantId: survivorConfirm.participantId,
        position: survivorConfirm.position,
        predictionType: PredictionType.Survivor,
      };
      if (survivorConfirm.delete) {
        deletePrediction(data);
      } else {
        createPrediction(data);
      }
    }

    dispatch(gameActions.COMPLETE_SURVIVOR_CONFIRM());
  };

  const handleChangeSelectingType = (type: PredictionType) => {
    dispatch(
      gameActions.SET_SELECTING({
        type: type,
        position: findMissingPosition(getPredictionsByType(predictions, type)),
      }),
    );
  };

  // This shouldn't happen because we checked before rendering this component
  if (!selecting) {
    return null;
  }

  const currentPredictions = getPredictionsByType(predictions, selecting.type);
  const currentPositions = [
    currentPredictions.find((p) => p.position === 1),
    currentPredictions.find((p) => p.position === 2),
    currentPredictions.find((p) => p.position === 3),
  ];

  let blockPrediction = true;
  if (selecting && gameData && !isPredictionClosed(gameData, selecting.type)) {
    blockPrediction = false;
  }

  if (mobile) {
    return (
      <Mobile
        onChangePredictionType={handleChangeSelectingType}
        onBack={handleBack}
        positionPredictions={currentPositions}
        selecting={selecting}
        participants={participants}
        survivorConfirm={survivorConfirm}
        onConfirm={handleConfirm}
        closedPrediction={blockPrediction}
      />
    );
  }
  return (
    <Desktop
      onChangePredictionType={handleChangeSelectingType}
      onBack={handleBack}
      positionPredictions={currentPositions}
      selecting={selecting}
      participants={participants}
      survivorConfirm={survivorConfirm}
      closedPrediction={blockPrediction}
      onConfirm={handleConfirm}
    />
  );
};

export default SelectingState;
