import { Box, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';
import { useDispatch, useSelector } from 'react-redux';

import imgMiniCompetition from 'assets/png/img-mini-competition.png';

import { EventAction } from 'constants/analytics';
import { useAnalytics } from 'hooks';
import { useAuth, useCompetition, useJoinCompetition } from 'queries';
import { authActions } from 'store/auth/auth.slice';
import { getViewParams } from 'store/game/game.selectors';
import { gameActions } from 'store/game/game.slice';
import { Store } from 'store/reducers';
import { ViewType } from 'types/game.types';

import Button from 'components/@common/Button';
import Loader from 'components/@common/Loader';

import { ModalContainer, ModalScrollContent, ModalTitle } from '../Modal';

const MiniCompetitionJoinModal: React.VFC = () => {
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const { isLoggedIn, userId } = useAuth();
  const viewParams = useSelector((state: Store) =>
    getViewParams<{ id: string; join?: boolean }>(state),
  );

  const { competition, isLoading, error } = useCompetition(viewParams?.id, {
    retry: false,
  });
  const { joinCompetition, isLoading: isLoadingJoin } = useJoinCompetition({
    onSuccess: () => {
      dispatch(
        gameActions.SET_VIEW({
          type: ViewType.MiniCompetitionDetail,
          params: { id: viewParams?.id },
        }),
      );
    },
  });

  // Auto join if user just logged in
  useEffect(() => {
    if (!!viewParams?.join && !!viewParams?.id.length) {
      joinCompetition(viewParams.id);
    }
  }, [joinCompetition, viewParams?.id, viewParams?.join]);

  const admin = competition?.members.find(
    (m) => m.id === competition.adminUserId,
  );

  const handleJoin = () => {
    if (viewParams?.id) {
      trackEvent({ eventAction: EventAction.MiniCompetitionsJoin });
      if (isLoggedIn) {
        joinCompetition(viewParams.id);
      } else {
        dispatch(
          authActions.LOGIN_START({
            type: ViewType.MiniCompetitionJoin,
            params: { id: viewParams.id, join: true },
          }),
        );
      }
    }
  };

  const userAlreadyJoined = !!competition?.members.find((m) => m.id === userId);

  return (
    <ModalContainer>
      <ModalTitle>
        <FormattedMessage id="mini_competition.join.title" />
      </ModalTitle>
      <ModalScrollContent>
        {isLoading && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Loader fontSize={126} variant="beige" />
          </Box>
        )}
        {!isLoading && !!error && !competition && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            mx="auto"
          >
            <Box mb={4} maxWidth={200} clone>
              <img alt="mini competitie" src={imgMiniCompetition} width="90%" />
            </Box>
            <Typography variant="h3">
              <FormattedMessage id="mini_competition.join.invite.error.title" />
            </Typography>
            <Box pt={6} clone>
              <Typography variant="body1">
                <FormattedMessage id="mini_competition.join.invite.error.description" />
              </Typography>
            </Box>
          </Box>
        )}

        {!isLoading && competition && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            mx="auto"
          >
            <Box mb={4} maxWidth={200} clone>
              <img alt="mini competitie" src={imgMiniCompetition} width="90%" />
            </Box>
            {!userAlreadyJoined ? (
              <>
                <Typography variant="h3">
                  <FormattedMessage
                    id="mini_competition.join.invite.title"
                    values={{
                      name: (
                        <Box color="global01.500" component="span">
                          {`${admin?.firstName} ${admin?.lastNameInitials}`}
                        </Box>
                      ),
                    }}
                  />
                </Typography>
                <Box py={6} clone>
                  <Typography variant="body1">
                    <FormattedMessage id="mini_competition.join.invite.description" />
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                <Typography variant="h3">
                  <FormattedMessage id="mini_competition.join.invite.accepted.title" />
                </Typography>
                <Box py={6} clone>
                  <Typography variant="body1">
                    <FormattedMessage id="mini_competition.join.invite.accepted.description" />
                  </Typography>
                </Box>
              </>
            )}
            <Box color="global01.600" mb={4}>
              <Typography variant="h5">
                <FormattedMessage
                  id="mini_competition.join.invite.competition.label"
                  values={{
                    name: (
                      <Box color="global01.500" component="span">
                        {competition.name}
                      </Box>
                    ),
                  }}
                />
              </Typography>
            </Box>
            {!userAlreadyJoined && (
              <Box width={200}>
                <Button
                  disabled={isLoadingJoin}
                  variant="secondary"
                  onClick={handleJoin}
                  loading={isLoadingJoin}
                  fullWidth
                >
                  <FormattedMessage id="mini_competition.join.invite.button" />
                </Button>
              </Box>
            )}
          </Box>
        )}
      </ModalScrollContent>
    </ModalContainer>
  );
};

export default MiniCompetitionJoinModal;
