import { Box, Container, Link, Typography } from '@material-ui/core';
import qs from 'query-string';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';

import history from 'services/history';

import { Routes } from 'pages/routes.constants';

import Button from 'components/@common/Button';

const LoginCallback: React.VFC = () => {
  const { error } = qs.parse(window.location.search);

  const errorType = typeof error === 'string' ? error : undefined;

  let errorMessageId = 'login.error.general.description';
  if (errorType === 'missing_first_name') {
    errorMessageId = 'login.error.missing_firstname.description';
  }

  return (
    <Box bgcolor="neutral06.500" py={8} textAlign="center" overflow="hidden">
      <Container maxWidth="md">
        <Box mx={4} display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h2">
            <FormattedMessage id="login.error.title" />
          </Typography>
          <Box mt={2} mb={5} maxWidth={350} margin="auto">
            <Typography variant="body1">
              <FormattedMessage id={errorMessageId} />
            </Typography>
          </Box>
          {errorType === 'missing_first_name' ? (
            <Link href="https://account.vtm.be/" target="_blank">
              <FormattedMessage id="login.error.missing_firstname.change.button" />
            </Link>
          ) : (
            <Typography variant="h5">Error: {errorType}</Typography>
          )}
          <Box mt={4}>
            <Button
              variant="secondary"
              onClick={() => history.replace(Routes.Root)}
            >
              <FormattedMessage id="login.error.home.button" />
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default LoginCallback;
