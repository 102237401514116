import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { QueryKeys, useAuth } from 'queries';
import { StaleTime } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { LeaderboardUser } from 'types/leaderboard.types';

const getUserLeaderBoard = async (userId: string) => {
  const { data: response } = await apiService.getUserLeaderBoard(userId);
  return response;
};

export const useUserLeaderboard = (
  config: UseCommonQueryOptions<LeaderboardUser[]> = { enabled: true },
) => {
  const { userId } = useAuth();
  const { data, status, isInitialLoading, error } = useQuery(
    QueryKeys.leaderboard.user(userId ?? ''),
    // We can disable it because the query isn't enabled without a valid userId
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => getUserLeaderBoard(userId!),
    {
      ...config,
      enabled: !!userId && config.enabled,
      staleTime: config?.staleTime ?? StaleTime.FOREVER,
    },
  );

  const user = useMemo(
    () => data?.find((u) => u.id === userId),
    [data, userId],
  );

  return {
    userLeaderboard: data,
    user,
    status,
    isLoading: isInitialLoading,
    error,
  };
};
