import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { QueryKeys } from 'queries';
import { apiService } from 'services';

const postJoinCompetition = async (competitionId: string) => {
  const { data: response } = await apiService.postJoinCompetition(
    competitionId,
  );
  return response;
};

export const useJoinCompetition = (
  config: UseMutationOptions<undefined, AxiosError, string> = {},
) => {
  const queryClient = useQueryClient();

  const { mutate, data, isLoading, error } = useMutation(
    (competitionId) => postJoinCompetition(competitionId),
    {
      ...config,
      onSuccess: async (data, vars, context) => {
        config.onSuccess?.(data, vars, context);
        queryClient.invalidateQueries(QueryKeys.competitions.all(), {
          exact: true,
        });
      },
    },
  );

  return {
    joinCompetition: mutate,
    competition: data,
    isLoading,
    error,
  };
};
