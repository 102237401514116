import * as React from 'react';

function SvgIcoLink(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 14" fill="none" {...props}>
      <g clipPath="url(#ico-link_svg__clip0_1131_704265)">
        <path
          d="M3.61 5.086a3.76 3.76 0 01.842-.634c1.684-.928 3.843-.435 4.926 1.207l-1.31 1.31A1.974 1.974 0 005.83 5.83c-.35.078-.681.253-.952.524L2.368 8.867a1.957 1.957 0 000 2.765 1.957 1.957 0 002.765 0l.774-.774c.704.28 1.459.39 2.204.335l-1.708 1.709a3.75 3.75 0 01-5.304-5.304l2.51-2.512zm3.987-3.988L5.888 2.807c.745-.056 1.5.056 2.205.335l.773-.774a1.957 1.957 0 012.765 0 1.957 1.957 0 010 2.765L9.12 7.644a1.959 1.959 0 01-2.765 0 2.08 2.08 0 01-.424-.613l-1.31 1.31c.137.209.28.389.464.573a3.757 3.757 0 005.304 0L12.9 6.402a3.75 3.75 0 10-5.303-5.304z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="ico-link_svg__clip0_1131_704265">
          <path fill="#fff" d="M0 0h14v14H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
const MemoSvgIcoLink = React.memo(SvgIcoLink);
export default MemoSvgIcoLink;
