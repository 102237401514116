import { Box, ButtonBase, ButtonBaseProps } from '@material-ui/core';
import React from 'react';
import styled, { css } from 'styled-components';

import { IcoCheckGreen } from 'components/@icons';

type StyledButtonProps = { $check: boolean; $active: boolean };

const ButtonStyled = styled(ButtonBase)<ButtonBaseProps & StyledButtonProps>`
  width: 54px;
  height: 54px;

  position: relative;
  flex-shrink: 0;
  text-transform: uppercase;
  line-height: 1;
  font-size: 24px;
  font-weight: 700;
  font-family: Butler, sans-serif;

  color: ${({ theme }) => theme.palette.neutral02[500]};
  border: 1px solid ${({ theme }) => theme.palette.global01[400]};
  background-color: transparent;

  ${({ theme, $active }) =>
    $active
      ? css`
          background-color: ${theme.palette.neutral01[500]};
        `
      : undefined}

  ${({ theme, $active, $check }) =>
    !$active && $check
      ? css`
          background-color: ${theme.palette.global01[400]};
        `
      : undefined}

  &:hover {
    color: ${({ theme }) => theme.palette.neutral01[500]};
    fill: ${({ theme }) => theme.palette.neutral01[500]};

    background: radial-gradient(
      70.19% 70.19% at 50% 50%,
      #e9d091 0%,
      #906d13 100%
    );
  }
` as React.ComponentType<ButtonBaseProps & StyledButtonProps>;

interface Props {
  active?: boolean;
  check?: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

const PositionButton: React.FC<Props> = ({
  children,
  active = false,
  check = false,
  onClick,
}) => {
  return (
    <ButtonStyled
      $active={active}
      $check={check}
      disabled={active}
      onClick={onClick}
    >
      {check && (
        <Box position="absolute" right={-6} top={-6} clone>
          <IcoCheckGreen fontSize={24} />
        </Box>
      )}
      {children}
    </ButtonStyled>
  );
};

export default PositionButton;
