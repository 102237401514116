import * as React from 'react';

function SvgIcoBanishInactive(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 44" fill="none" {...props}>
      <g filter="url(#ico-banish-inactive_svg__filter0_d_131_196496)">
        <path d="M2 .811h28v28l-14.5 11-13.5-11v-28z" fill="#F3F3F3" />
        <path
          d="M16.407 22.95c-1.469 0-2.94-.558-4.058-1.677a5.747 5.747 0 010-8.117 5.747 5.747 0 018.117 0 5.747 5.747 0 010 8.117 5.728 5.728 0 01-4.059 1.677zm0-10.199a4.452 4.452 0 00-3.156 1.305 4.471 4.471 0 000 6.314 4.471 4.471 0 006.313 0 4.471 4.471 0 000-6.314 4.451 4.451 0 00-3.157-1.305z"
          fill="#002E64"
        />
        <path
          d="M20.016 21.459a.64.64 0 01-.452-.186l-7.215-7.216a.638.638 0 01.901-.902l7.216 7.216a.638.638 0 01-.45 1.088z"
          fill="#002E64"
        />
      </g>
      <defs>
        <filter
          id="ico-banish-inactive_svg__filter0_d_131_196496"
          x={0}
          y={0.811}
          width={32}
          height={43}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={1} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.667795 0 0 0 0 0.637751 0 0 0 0 0.590538 0 0 0 0.5 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_131_196496"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_131_196496"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
const MemoSvgIcoBanishInactive = React.memo(SvgIcoBanishInactive);
export default MemoSvgIcoBanishInactive;
