import type {
  BonusQuestion,
  BonusQuestionAnswer,
  BonusQuestionAnswerResponse,
} from 'types/bonus.types';

import RestApiService from './';

export default {
  getBonusQuestion(this: RestApiService) {
    return this.api.get<BonusQuestion>('/bonus-question');
  },
  postAnswerBonusQuestion(
    this: RestApiService,

    data: {
      questionId: BonusQuestion['id'];
      answerIds: BonusQuestionAnswer['id'][];
    },
  ) {
    return this.api.post<BonusQuestionAnswerResponse[]>(
      `/bonus-question/${data.questionId}`,
      {
        answerIds: data.answerIds,
      },
    );
  },
};
