import { AxiosError } from 'axios';
import qs from 'query-string';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import history from 'services/history';
import { ViewType } from 'types/game.types';

import { Routes } from 'pages/routes.constants';

import { gameActions } from '../game/game.slice';
import { systemActions } from './system.slice';

function* checkForCompetition() {
  try {
    const { id } = qs.parse(window.location.search);

    if (
      id &&
      typeof id === 'string' &&
      window.location.pathname === Routes.Root
    ) {
      history.replace(Routes.Root, {});
      yield put(
        gameActions.SET_VIEW({
          type: ViewType.MiniCompetitionJoin,
          params: { id },
        }),
      );
    }
  } catch {}
}

function* initApp() {
  try {
    /**
     * start your saga flows
     */
    yield call(checkForCompetition);

    /**
     * system is ready -> time to render UI!
     */
    yield put(systemActions.SYSTEM_READY());
  } catch (e: unknown) {
    yield put(systemActions.SYSTEM_FAIL(e as AxiosError<unknown>));
  }
}

export default function* () {
  yield all([takeLatest(systemActions.SYSTEM_START, initApp)]);
}
