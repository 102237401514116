import { useQuery } from '@tanstack/react-query';
import _sortBy from 'lodash/sortBy';
import { useMemo } from 'react';

import { QueryKeys, useAuth } from 'queries';
import { StaleTime } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { Competition } from 'types/competition.types';

const getCompetitions = async (userId: string) => {
  const { data: response } = await apiService.getCompetitions(userId);
  return response;
};

export const useCompetitions = (
  config: UseCommonQueryOptions<Competition[]> = { enabled: true },
) => {
  const { userId } = useAuth();
  const { data, status, isInitialLoading, error } = useQuery(
    QueryKeys.competitions.all(),
    // We can disable it because the query isn't enabled without a valid userId
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => getCompetitions(userId!),
    {
      ...config,
      enabled: !!userId && config.enabled,
      staleTime: config?.staleTime ?? StaleTime.MINUTE,
    },
  );

  const sortedData = useMemo(() => _sortBy(data, ['name']), [data]);

  return {
    competitions: sortedData,
    status,
    isLoading: isInitialLoading,
    error,
  };
};
