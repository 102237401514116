import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { QueryKeys } from 'queries';
import { apiService } from 'services';
import { Competition } from 'types/competition.types';

const deleteCompetitionMember = async (
  competitionId: string,
  memberId: string,
) => {
  const { data: response } = await apiService.deleteCompetitionMember(
    competitionId,
    memberId,
  );
  return response;
};

export const useDeleteCompetitionMember = (
  config: UseMutationOptions<
    undefined,
    AxiosError,
    { competitionId: string; memberId: string }
  > = {},
) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = useMutation(
    ({ competitionId, memberId }) =>
      deleteCompetitionMember(competitionId, memberId),
    {
      ...config,
      onSuccess: async (data, vars, context) => {
        config.onSuccess?.(data, vars, context);

        await queryClient.cancelQueries(QueryKeys.competitions.all());

        queryClient.setQueryData<Competition[] | undefined>(
          QueryKeys.competitions.all(),
          (old) => {
            let finalData: Competition[] = [...(old ?? [])];
            const competition = finalData.find(
              (c) => c.id === vars.competitionId,
            );

            if (competition) {
              competition.members = competition.members.filter(
                (m) => m.id !== vars.memberId,
              );
            }

            return finalData;
          },
        );
      },
    },
  );

  return {
    deleteMember: mutate,
    isLoading,
    error,
  };
};
