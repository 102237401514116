import * as React from 'react';

function SvgIcoMurderActive(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 36 49" fill="none" {...props}>
      <path
        d="M4 2.811H2v30.95l.737.6 13.5 11 1.219.994 1.253-.95 14.5-11 .791-.6V2.81H4z"
        fill="#D62E00"
        stroke="#fff"
        strokeWidth={4}
        strokeMiterlimit={10}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.922 23.646v-1.549a5.103 5.103 0 10-5.84 0v1.55h5.84zm-6.063-5.037a1.246 1.246 0 112.49 0 1.246 1.246 0 01-2.49 0zm2.196 2.87a.412.412 0 11.824 0 .412.412 0 01-.824 0zm1.482.412a.412.412 0 110-.824.412.412 0 010 .824zm.117-3.282a1.245 1.245 0 112.49 0 1.245 1.245 0 01-2.49 0zm-7.049 5.872c0 .26.135.487.337.618a.734.734 0 00.4 1.353c.405 0 .735-.33.735-.736h9.851a.736.736 0 101.135-.617.735.735 0 10-1.135-.618h-9.851a.736.736 0 10-1.472 0z"
        fill="#fff"
      />
    </svg>
  );
}
const MemoSvgIcoMurderActive = React.memo(SvgIcoMurderActive);
export default MemoSvgIcoMurderActive;
