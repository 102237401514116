import { AxiosInstance } from 'axios';

import bonusApi from './bonus.api';
import competitionApi from './competition.api';
import gameApi from './game.api';
import leaderboardApi from './leaderboard.api';
import shareApi from './share.api';
import userApi from './user.api';

interface Options {
  api: AxiosInstance;
}

class RestApiService {
  public api: AxiosInstance;

  constructor(options: Options) {
    this.api = options.api;
  }

  // GAME
  public getParticipants = gameApi.getParticipants;
  public getGameData = gameApi.getGameData;
  public getStats = gameApi.getStats;

  // USER
  public getUser = userApi.getUser;
  public getPredictions = userApi.getPredictions;
  public postPrediction = userApi.postPrediction;
  public deletePrediction = userApi.deletePrediction;
  public getScoreBreakdown = userApi.getScoreBreakdown;
  public postTraitorGuess = userApi.postTraitorGuess;
  public getTraitorGuesses = userApi.getTraitorGuesses;

  // SHARE
  public sharePredictions = shareApi.sharePredictions;
  public shareLeaderboard = shareApi.shareLeaderboard;
  public shareCompetitionLeaderboard = shareApi.shareCompetitionLeaderboard;

  // LEADERBOARD
  public getLeaderBoard = leaderboardApi.getLeaderboard;
  public getUserLeaderBoard = leaderboardApi.getUserLeaderboard;

  // BONUS
  public getBonusQuestion = bonusApi.getBonusQuestion;
  public postAnswerBonusQuestion = bonusApi.postAnswerBonusQuestion;

  // COMPETITION
  public getCompetitions = competitionApi.getCompetitions;
  public getCompetition = competitionApi.getCompetition;
  public postCompetition = competitionApi.postCompetition;
  public putCompetitionName = competitionApi.putCompetitionName;
  public postJoinCompetition = competitionApi.postJoinCompetition;
  public deleteCompetitionMember = competitionApi.deleteCompetitionMember;
}

export default RestApiService;
