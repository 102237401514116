import { Box, Fade, IconButton, List } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';
import styled from 'styled-components';

import { useAuth, useLeaderboard, useUserLeaderboard } from 'queries';

import Loader from 'components/@common/Loader';
import { IcoArrowRight } from 'components/@icons';
import LeaderboardListItem from 'components/@list/LeaderboardListItem';

import { ModalContainer, ModalScrollContent, ModalTitle } from '../Modal';

const POSITION_SPLIT = 15;

const Dot = styled(Box)`
  background-color: ${({ theme }) => theme.palette.neutral05[500]};
  width: 5px;
  height: 5px;
  border-radius: 50%;
`;

const LeaderboardModal: React.VFC = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [showScroll, setShowScroll] = useState(true);
  const { userId } = useAuth();
  const { leaderboard, isLoading: isLoadingLeaderboard } = useLeaderboard();
  const { userLeaderboard, isLoading: isLoadingUser } = useUserLeaderboard();

  const leaderboardUserIdx =
    leaderboard?.findIndex((l) => l.id === userId) ?? -1;
  const isLoading = isLoadingUser || isLoadingLeaderboard;

  const userLeaderboardUserIdx =
    userLeaderboard?.findIndex((l) => l.id === userId) ?? -1;
  const currentUser = userLeaderboard?.[userLeaderboardUserIdx];
  const finalUsers =
    leaderboard?.slice(
      0,
      currentUser &&
        currentUser.position <= POSITION_SPLIT &&
        currentUser.position > 10
        ? POSITION_SPLIT
        : 10,
    ) ?? [];

  const finalUserLeaderboard = userLeaderboard;

  // Add 2 fake users when you're the last one
  if (finalUserLeaderboard) {
    const lastUser = finalUserLeaderboard[finalUserLeaderboard.length - 1];
    if (lastUser.id === userId) {
      finalUserLeaderboard.push({
        id: 'user-1',
        firstName: 'Jasper',
        lastNameInitials: 'V',
        createdAt: new Date().toISOString(),
        score: Math.max(lastUser.score - 10, 0),
        position: lastUser.position + 1,
      });
      finalUserLeaderboard.push({
        id: 'user-2',
        firstName: 'Anneleen',
        lastNameInitials: 'D',
        createdAt: new Date().toISOString(),
        score: Math.max(lastUser.score - 20, 0),
        position: lastUser.position + 2,
      });
    }
  }

  const handleScrollClick = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight - scrollRef.current.clientHeight,
        behavior: 'smooth',
      });
    }
  };

  const handleScroll = () => {
    if (scrollRef.current) {
      setShowScroll(
        scrollRef.current.scrollTop <
          scrollRef.current.scrollHeight - scrollRef.current.clientHeight,
      );
    }
  };

  useEffect(() => {
    const copyRef = scrollRef.current;
    if (copyRef) {
      copyRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (copyRef) {
        copyRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <ModalContainer>
      <ModalTitle>
        <FormattedMessage id="leaderboard.title" />
      </ModalTitle>

      <ModalScrollContent innerRef={scrollRef} disableGutters>
        {isLoading && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Loader fontSize={126} variant="beige" />
          </Box>
        )}
        <Fade in={showScroll}>
          <Box
            position="absolute"
            right={5}
            bottom={10}
            color="global01.500"
            style={{ transform: 'rotate(90deg)' }}
          >
            <IconButton color="inherit" onClick={handleScrollClick}>
              <IcoArrowRight fontSize={32} />
            </IconButton>
          </Box>
        </Fade>

        <Box mx={{ xs: 0, sm: 10 }}>
          {!isLoading && (
            <>
              <List>
                {finalUsers.map((user, idx) => (
                  <LeaderboardListItem
                    key={idx}
                    position={user.position}
                    name={`${user.firstName} ${user.lastNameInitials}`}
                    score={user.score}
                    isCurrentUser={userId === user.id}
                    divider={
                      userId !== user.id &&
                      idx !== leaderboardUserIdx - 1 &&
                      idx !== finalUsers.length - 1
                    }
                  />
                ))}
                {finalUserLeaderboard &&
                  currentUser &&
                  currentUser.position > POSITION_SPLIT && (
                    <>
                      <Box
                        width="100%"
                        height={40}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Dot />
                        <Dot mx={4} />
                        <Dot />
                      </Box>
                      {finalUserLeaderboard.map((user, idx) => (
                        <LeaderboardListItem
                          key={idx}
                          position={user.position}
                          name={`${user.firstName} ${user.lastNameInitials}`}
                          score={user.score}
                          isCurrentUser={userId === user.id}
                          divider={
                            userId !== user.id &&
                            idx !== userLeaderboardUserIdx - 1 &&
                            idx !== finalUserLeaderboard.length - 1
                          }
                        />
                      ))}
                    </>
                  )}
              </List>
            </>
          )}
        </Box>
      </ModalScrollContent>
    </ModalContainer>
  );
};

export default LeaderboardModal;
