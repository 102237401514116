import { Box } from '@material-ui/core';
import styled, { css } from 'styled-components';

const createPiecesCss = (itemCount: number, size: number) => {
  let styles = '';

  const angle = 360 / itemCount;
  let rot = -90;

  for (let i = 1; i <= itemCount; i += 1) {
    styles += `
      &:nth-of-type(${i}) {
        transform-origin: center top;
        transform: translate(-50%) rotate(${rot}deg) translate(${
      size / 2.0
    }px) rotate(90deg);
      }
    `;

    rot += angle;
  }

  return css`
    ${styles}
  `;
};

export const BoardContainer = styled.div<{ $itemCount: number; $size: number }>`
  position: relative;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  padding: 0;
  z-index: 5;
  pointer-events: none;

  > * {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12.7%;

    ${({ $size, $itemCount }) => createPiecesCss($itemCount, $size)}
  }
`;

export const RotatingBox = styled(Box)`
  animation: rotate 40s linear infinite;

  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
`;
