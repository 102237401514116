import * as React from 'react';

function SvgIcoButtonCheck(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 34 34" fill="none" {...props}>
      <path fill="#fff" stroke="currentColor" d="M5.5 5.5h23v23h-23z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.476 11.623a631.52 631.52 0 015.841 5.284S23.383 7.45 30.893 3l.557 1.669c-2.782 1.669-11.126 11.126-14.186 19.47 0 0-8.622-8.344-10.291-10.291l2.503-2.225z"
        fill="currentColor"
      />
    </svg>
  );
}
const MemoSvgIcoButtonCheck = React.memo(SvgIcoButtonCheck);
export default MemoSvgIcoButtonCheck;
