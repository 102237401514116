import { ButtonBaseProps } from '@material-ui/core';
import { ButtonBase } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

import Loader from '../Loader';
import { BaseProps } from './Button';

type StyledButtonProps = { $fullWidth?: boolean };

const ButtonStyled = styled(ButtonBase)<StyledButtonProps>`
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'unset')};
  height: 48px;

  position: relative;

  flex-shrink: 0;

  padding-left: 16px;
  padding-right: 16px;

  text-transform: uppercase;
  line-height: 1;
  font-size: 16px;
  font-family: Butler, sans-serif;
  color: ${({ theme }) => theme.palette.neutral01[500]};

  background-color: ${({ theme }) => theme.palette.global01[500]};

  &:hover {
    background-color: ${({ theme }) => theme.palette.global01[600]};

    @media (hover: none) {
      background-color: ${({ theme }) => theme.palette.global01[500]};
    }
  }

  &.Mui-disabled {
    color: ${({ theme }) => theme.palette.neutral06[500]};
    background-color: ${({ theme }) => theme.palette.neutral05[500]};
  }
` as React.ComponentType<ButtonBaseProps & StyledButtonProps>;

const ButtonSecondary: React.FC<BaseProps> = ({
  children,
  loading,
  disabled,
  fullWidth,
  ...rest
}) => {
  return (
    <ButtonStyled
      $fullWidth={fullWidth}
      disabled={loading || disabled}
      {...rest}
    >
      {loading ? <Loader fontSize={42} variant="white" /> : children}
    </ButtonStyled>
  );
};

export default ButtonSecondary;
