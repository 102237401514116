import { detect } from 'detect-browser';
import { useState } from 'react';

import { ShareResponse } from 'types/share.types';

export const useShare = () => {
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const [shareData, setShareData] = useState<ShareResponse>();
  const [squareImg, setSquareImg] = useState<Blob>();

  const browser = detect();
  const isSafari = !!browser?.name.match(/safari|^ios$/i);
  const userAgent = window.navigator.userAgent;
  const isIphoneOrIpad =
    !!userAgent.match(/iPad/i) || !!userAgent.match(/iPhone/i);
  const isDesktopSafari = isSafari && !isIphoneOrIpad;

  const nativeLikeShare = (shareData: ShareResponse, blob?: Blob) => {
    // Reset anchor element so the popover in ShareCard.tsx closes and the user won't see our custom share component.
    setAnchorEl(null);
    setShareData(shareData);

    navigator?.share &&
      navigator
        .share({
          url: blob ? undefined : shareData.shareUrl,
          // @ts-ignores
          files: blob
            ? [
                new File([blob], 'square_img.jpg', {
                  type: 'image/jpeg',
                  lastModified: new Date().getTime(),
                }),
              ]
            : [],
        })
        .catch((e: Error) => {
          console.error(e);
          setAnchorEl(null);
        });
  };

  const handleDownload = (data: ShareResponse) => {
    if (data) {
      fetch(data.shareImageUrlSquare)
        .then((res) => res.blob())
        .then((blob) => {
          var element = document.createElement('a');
          element.setAttribute('href', URL.createObjectURL(blob));
          element.setAttribute('target', '_blank');
          element.setAttribute('download', 'share.jpg');

          element.style.display = 'none';
          document.body.appendChild(element);

          element.click();

          document.body.removeChild(element);
        })
        .catch(() => window.open(data.shareImageUrlSquare, '_blank'));
    }
  };

  return {
    isDesktopSafari,
    isIphoneOrIpad,
    isSafari,
    anchorEl,
    setAnchorEl,
    shareData,
    setShareData,
    squareImg,
    setSquareImg,
    nativeLikeShare,
    handleDownload,
  };
};
