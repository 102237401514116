import { Box, Link, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';

import imgRules from 'assets/png/img-rules.png';

import IcoArrowRight from 'components/@icons/IcoArrowRight';

interface Props {
  onShowRules: () => void;
}

const GameInfo: React.VFC<Props> = ({ onShowRules }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      flex={1}
    >
      <Typography variant="h3">
        <FormattedMessage id="game.info.title" />
      </Typography>
      <Box py={6} maxWidth={375} clone>
        <Typography variant="body2">
          <FormattedMessage id="game.info.description" />
        </Typography>
      </Box>
      <Box mb={4} clone>
        <img alt="de regels" src={imgRules} width={140} />
      </Box>
      <Link component="button" onClick={onShowRules}>
        <FormattedMessage id="game.info.rules.button" />
        <IcoArrowRight
          color="global01.500"
          width={28}
          height={15}
          style={{ paddingLeft: 8 }}
        />
      </Link>
    </Box>
  );
};

export default GameInfo;
