import styled from 'styled-components';

export const Cover = styled.img`
  height: 120px;
  width: 86px;
  object-fit: cover;
  filter: drop-shadow(0px 5px 12px rgba(0, 0, 0, 0.4));
  flex-shrink: 0;
`;

export const WatchlistButton = styled.a`
  position: relative;
  height: 40px;
  width: 40px;
  color: white;
  margin-left: 12px;
  border-radius: 1.59375rem;
  padding: 9px;
  background-color: rgba(82, 82, 82, 0.8);
  overflow: hidden;
  transition: all 0.75s cubic-bezier(0.16, 1, 0.3, 1) 0s;
  display: flex;
  justify-content: flex-start;

  ${({ theme }) => theme.breakpoints.up('md')} {
    &:focus-visible,
    &:focus,
    &:hover {
      background-color: rgba(82, 82, 82, 0.8);
      opacity: 1;
      width: 115px;
    }
  }
`;
