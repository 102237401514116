import { Box } from '@material-ui/core';
import React from 'react';

import { PREDICTION_COLORS } from 'constants/game';
import { PredictionType } from 'types/game.types';

import {
  IcoBanishIcon,
  IcoCheck,
  IcoFlag,
  IcoMurderIcon,
  IcoSurviveIcon,
} from 'components/@icons';

interface Props {
  type: Exclude<
    PredictionType,
    PredictionType.Disqualified | PredictionType.Seduced
  >;
  value?: number;
}

const Flag: React.VFC<Props> = ({ type, value }) => {
  return (
    <Box
      position="relative"
      fontFamily="Montserrat"
      fontSize={16}
      fontWeight="bold"
      color="neutral01.500"
      width={28}
      height={49}
    >
      <Box color={PREDICTION_COLORS[type]} clone>
        <IcoFlag width="100%" height="100%" />
      </Box>
      <Box position="absolute" top={5} left={0} right={0} textAlign="center">
        {type === PredictionType.Murdered && <IcoMurderIcon />}
        {type === PredictionType.Banished && <IcoBanishIcon />}
        {type === PredictionType.Survivor && <IcoSurviveIcon />}
      </Box>

      <Box
        position="absolute"
        bottom={value ? 5 : 0}
        left={0}
        right={0}
        textAlign="center"
      >
        {value ? value : <IcoCheck fontSize={20} />}
      </Box>
    </Box>
  );
};

export default Flag;
