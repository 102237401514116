import { Box } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const Wrap = styled(Box)<{
  $active: boolean;
  $disabled: boolean;
  $deathRow: boolean;
}>`
  aspect-ratio: 1;
  &::before {
    content: '';
    position: absolute;

    border-radius: 50%;

    ${({ $active, $disabled, $deathRow, theme }) => css`
      left: -${$active ? 3 : 1}px;
      top: -${$active ? 3 : 1}px;
      right: -${$active ? 3 : 1}px;
      bottom: -${$active ? 3 : 1}px;

      border: ${$active ? 3 : 1}px solid
        ${$deathRow
          ? theme.palette.red[500]
          : $active
          ? theme.palette.neutral01[500]
          : $disabled
          ? theme.palette.neutral05[500]
          : theme.palette.neutral07[400]};
    `}
  }
`;
