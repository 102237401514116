import * as React from 'react';

function SvgIcoLoading(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 42 42" fill="none" {...props}>
      <circle cx={21} cy={21} r={15.5} stroke="#74A3D9" />
      <g clipPath="url(#ico-loading_svg__clip0_186_983287)">
        <path d="M21 25.5L40.053 21H1.947L21 25.5z" fill="#002E64" />
      </g>
      <path d="M21 25.5L40.053 21H1.947L21 25.5z" stroke="#74A3D9" />
      <g clipPath="url(#ico-loading_svg__clip1_186_983287)">
        <path d="M21 16.5L1.947 21h38.106L21 16.5z" fill="#002E64" />
      </g>
      <path d="M21 16.5L40.053 21H1.947L21 16.5z" stroke="#74A3D9" />
      <path
        d="M20.996 26a5 5 0 11.008-10.002A5 5 0 0120.996 26z"
        fill="#002E64"
        stroke="#74A3D9"
        strokeMiterlimit={10}
      />
      <defs>
        <clipPath id="ico-loading_svg__clip0_186_983287">
          <path fill="#fff" transform="translate(0 19.5)" d="M0 0h19v5H0z" />
        </clipPath>
        <clipPath id="ico-loading_svg__clip1_186_983287">
          <path
            fill="#fff"
            transform="rotate(-180 21 11.25)"
            d="M0 0h19v5H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
const MemoSvgIcoLoading = React.memo(SvgIcoLoading);
export default MemoSvgIcoLoading;
