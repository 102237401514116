import { Box, Link, Typography } from '@material-ui/core';
import { transparentize } from 'polished';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';

import { PREDICTION_COLORS } from 'constants/game';
import { useStats } from 'queries';
import { gameActions } from 'store/game/game.slice';
import { PredictionType, ViewType } from 'types/game.types';

import { IcoFlagScore } from 'components/@icons';

import TypeButtons from '../SelectingState/components/TypeButtons/TypeButtons';

interface Props {
  mobile?: boolean;
}

const labelId = (type: PredictionType) => {
  switch (type) {
    case PredictionType.Murdered:
      return 'game.start.who.murder';
    case PredictionType.Banished:
      return 'game.start.who.banish';
    case PredictionType.Survivor: {
      return 'game.start.who.survive';
    }
  }
};

interface StatsItemProps {
  position: number;
  type: Exclude<
    PredictionType,
    PredictionType.Disqualified | PredictionType.Seduced
  >;
  percentage: number;
  name: string;
}

const StatsItem = ({ type, position, percentage, name }: StatsItemProps) => {
  return (
    <Box display="flex" mb={2}>
      <Box
        color={PREDICTION_COLORS[type]}
        style={{
          filter: 'drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.55))',
        }}
        mr={4}
        position="relative"
      >
        <IcoFlagScore width={18} height={25} />
        <Box
          position="absolute"
          bottom={10}
          left={0}
          right={0}
          color="neutral01.500"
          textAlign="center"
        >
          <Typography variant="body1" style={{ fontSize: 13 }}>
            <b>{position}</b>
          </Typography>
        </Box>
      </Box>
      <Typography variant="body2" color="textSecondary">
        <FormattedMessage
          id="game.stats.label"
          values={{ percentage: <b>{percentage}%</b>, name: <b>{name}</b> }}
        />
      </Typography>
    </Box>
  );
};
const StatsState: React.VFC<Props> = ({ mobile }) => {
  const { stats } = useStats();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [statsType, setStatsType] = useState<
    Exclude<
      PredictionType,
      PredictionType.Disqualified | PredictionType.Seduced
    >
  >(PredictionType.Murdered);

  const handleBack = () => {
    dispatch(gameActions.SET_VIEW({ type: ViewType.Game }));
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="100%"
      width="100%"
    >
      <Box color="global01.400" mb={4}>
        <Link component="button" onClick={handleBack} color="inherit">
          <FormattedMessage id="game.select.confirm.back.button" />
        </Link>
      </Box>
      <Typography variant="h3" color="textSecondary">
        <FormattedMessage id="game.stats.title" />
      </Typography>
      <Box flex={1} />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        position={mobile ? 'relative' : 'absolute'}
        width={mobile ? 'calc(100% + 32px)' : undefined}
        bgcolor={transparentize(0.9, theme.palette.blue[400])}
        flex={1}
        pb={4}
        {...(mobile ? { mt: 12 } : { top: 150, left: 0, right: 0, bottom: 0 })}
      >
        <Box mt={mobile ? '-26px' : '-50px'}>
          <TypeButtons type={statsType} onClick={setStatsType} />
        </Box>
        <Box color="blue.400" my={6}>
          <Typography variant="h4">
            <FormattedMessage
              id={labelId(statsType)}
              values={{
                b: (v: string) => <b>{v}</b>,
              }}
            />
          </Typography>
        </Box>
        {stats && (
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            {stats[statsType][0] && (
              <StatsItem
                type={statsType}
                position={1}
                percentage={stats[statsType][0].percentage}
                name={stats[statsType][0].firstName}
              />
            )}
            {stats[statsType][1] && (
              <StatsItem
                type={statsType}
                position={2}
                percentage={stats[statsType][1].percentage}
                name={stats[statsType][1].firstName}
              />
            )}
            {stats[statsType][2] && (
              <StatsItem
                type={statsType}
                position={3}
                percentage={stats[statsType][2].percentage}
                name={stats[statsType][2].firstName}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default StatsState;
