import { Box, Divider, ListItem, Typography } from '@material-ui/core';
import React from 'react';

import {
  BarWrap,
  CenterBar,
  LeftBar,
  RightBar,
} from './LeaderboardListItem.style';

interface Props {
  position: number;
  name: string;
  score: number;
  isCurrentUser?: boolean;
  divider?: boolean;
}

const LeaderboardListItem: React.VFC<Props> = ({
  position,
  name,
  score,
  isCurrentUser = false,
  divider = true,
}) => {
  return (
    <>
      <ListItem style={{ height: 47 }}>
        {isCurrentUser && (
          <BarWrap>
            <LeftBar />
            <CenterBar />
            <RightBar />
          </BarWrap>
        )}
        <Box
          mt="2px" // Offset for font
          display="flex"
          width="100%"
          justifyContent="space-between"
          mx={5}
        >
          <Typography
            variant={isCurrentUser ? 'h5' : 'body1'}
            color={isCurrentUser ? 'textSecondary' : 'textPrimary'}
          >
            {position}. {name}
          </Typography>
          <Box
            display="flex"
            color={isCurrentUser ? 'neutral01.500' : 'global01.500'}
            clone
          >
            <Typography variant="body1">{score}</Typography>
          </Box>
        </Box>
      </ListItem>

      {divider && <Divider component="li" light />}
    </>
  );
};

export default LeaderboardListItem;
