import { ButtonBase, Radio as RadioMui } from '@material-ui/core';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { IcoButtonCheck, IcoButtonWrong } from 'components/@icons';

export const Radio = styled(RadioMui)<{
  checked?: boolean;
  $correct?: boolean | null;
}>`
  &.Mui-disabled {
    color: ${({ checked, theme, $correct }) => {
      if (!checked || $correct === undefined || $correct === null) {
        return checked
          ? theme.palette.global01[500]
          : theme.palette.neutral05[500];
      } else if ($correct) {
        return theme.palette.green[500];
      } else {
        return theme.palette.red[500];
      }
    }}
`;
export const Check = styled(IcoButtonCheck)`
  width: 34px;
  height: 34px;
`;

export const Wrong = styled(IcoButtonWrong)`
  width: 25px;
  height: 25px;
  padding-top: 1px;
  margin: 5px;
`;

export const UnChecked = styled.div`
  width: 24px;
  height: 24px;
  margin: 5px;
  border: 1px solid ${({ theme }) => theme.palette.neutral05[500]};
`;

export const ButtonBaseStyled = styled(ButtonBase)<{
  $correct?: boolean | null;
  $selected: boolean;
}>`
  position: relative;
  border: 1px solid ${({ theme }) => theme.palette.neutral05[500]};
  height: 48px;
  width: 100%;
  text-align: left;
  justify-content: flex-start;
  padding: 12px;

  ${({ $correct, $selected, theme }) => {
    if (!$selected) return;

    if ($correct === undefined || $correct === null) {
      return css`
        background-color: ${theme.palette.global01[400]};
        border: 1px solid ${theme.palette.global01[500]};
      `;
    } else if ($correct) {
      return css`
        background-color: ${transparentize(0.6, theme.palette.green[500])};
        border: 1px solid ${theme.palette.green[500]};
      `;
    } else {
      return css`
        background-color: ${transparentize(0.6, theme.palette.red[500])};
        border: 1px solid ${theme.palette.red[500]};
      `;
    }
  }}
`;
