import _get from 'lodash/get';

import { Store } from 'store/reducers';
import { Participant, PredictionType, ViewType } from 'types/game.types';

import { GameState } from './game.slice';
import { findMissingPosition } from './game.utils';

export const getSurvivorConfirm = (state: Store) => {
  return state.game.surivorConfirm;
};

export const getViewType = (state: Store) => {
  return state.game.view?.type ?? ViewType.Game;
};

export function getViewParams<T>(state: Store) {
  return state.game.view?.params as T | undefined;
}

export const getPredictionCount = (state: Store) => {
  const { murdered, banished, survivor } = getPredictions(state);
  return murdered.length + banished.length + survivor.length;
};

export const getCurrentSelecting = (state: Store) => {
  return state.game.selecting;
};

export const getCurrentTraitorsSelecting = (state: Store) => {
  return state.game.selectingTraitors;
};

export const getFallbackSelecting = (state: Store): GameState['selecting'] => {
  const { murdered, banished, survivor } = getPredictions(state);
  if (murdered.length < 3) {
    return {
      type: PredictionType.Murdered,
      position: findMissingPosition(murdered),
    };
  } else if (banished.length < 3) {
    return {
      type: PredictionType.Banished,
      position: findMissingPosition(banished),
    };
  } else if (survivor.length < 3) {
    return {
      type: PredictionType.Survivor,
      position: findMissingPosition(survivor),
    };
  }

  return { type: PredictionType.Murdered, position: 1 };
};

export const getPredictions = (state: Store) => {
  const params = getViewParams<{ id: string }>(state);
  if (state.game.view?.type === ViewType.MemberGame && params?.id) {
    const predictions = _get(state.game.memberPredictions, [params.id]);
    return (
      predictions ?? {
        banished: [],
        murdered: [],
        survivor: [],
      }
    );
  }

  return state.game.predictions;
};

export const getPredictionsCounts = (state: Store) => {
  const predictions = getPredictions(state);
  return {
    murdered: predictions.murdered.length,
    banished: predictions.banished.length,
    survivor: predictions.survivor.length,
  };
};

export const getPredictionsPositions = (
  state: Store,
  participantId: Participant['id'],
) => {
  const { murdered, banished, survivor } = getPredictions(state);

  const murderedPosition = murdered.find(
    (p) => p.participantId === participantId,
  )?.position;

  const banishedPosition = banished.find(
    (p) => p.participantId === participantId,
  )?.position;

  const surivorPosition = survivor.find(
    (p) => p.participantId === participantId,
  )?.position;

  return {
    murdered: murderedPosition,
    banished: banishedPosition,
    survivor: surivorPosition,
  };
};

export const getPredictionsForParticipant = (
  state: Store,
  participantId: Participant['id'],
) => {
  const { murdered, banished, survivor } = getPredictions(state);

  const murderedPrediction = murdered.find(
    (p) => p.participantId === participantId,
  );

  const banishedPrediction = banished.find(
    (p) => p.participantId === participantId,
  );

  const surivorPrediction = survivor.find(
    (p) => p.participantId === participantId,
  );

  return {
    murdered: murderedPrediction,
    banished: banishedPrediction,
    survivor: surivorPrediction,
  };
};
