import { isPredictionClosed } from 'utils/game.utils';

import { QueryKeys } from 'queries';
import { queryClient } from 'services/react-query';
import { GameData, Prediction, PredictionType } from 'types/game.types';

import { GameState } from './game.slice';

export const findMissingPosition = (predictions: Prediction[]) => {
  if (predictions.findIndex((p) => p.position === 1) < 0) {
    return 1;
  } else if (predictions.findIndex((p) => p.position === 2) < 0) {
    return 2;
  } else if (predictions.findIndex((p) => p.position === 3) < 0) {
    return 3;
  }
  return 1;
};

export const getPredictionsByType = (
  predictions: GameState['predictions'],
  type?: PredictionType,
): GameState['predictions']['murdered'] => {
  switch (type) {
    case PredictionType.Survivor:
      return predictions.survivor;
    case PredictionType.Banished:
      return predictions.banished;
    default:
      return predictions.murdered;
  }
};

const TYPE_ORDER = [
  PredictionType.Murdered,
  PredictionType.Banished,
  PredictionType.Survivor,
];

export const getNextSelecting = (
  selecting: GameState['selecting'],
): GameState['selecting'] => {
  const gameData = queryClient.getQueryData<GameData>(
    QueryKeys.gameData.default(),
  );

  const availableTypes = gameData
    ? TYPE_ORDER.filter((t) => !isPredictionClosed(gameData, t))
    : TYPE_ORDER;

  if (
    !selecting ||
    (selecting.type === TYPE_ORDER[TYPE_ORDER.length - 1] &&
      selecting.position === 3)
  ) {
    return { type: TYPE_ORDER[0], position: 1 };
  }

  const currentIdx = TYPE_ORDER.findIndex((t) => t === selecting.type);

  if (!availableTypes.includes(selecting.type)) {
    return {
      type: TYPE_ORDER[Math.min(currentIdx + 1, TYPE_ORDER.length - 1)],
      position: 1,
    };
  }

  if (selecting.position === 3 || currentIdx < 0) {
    return {
      type: TYPE_ORDER[Math.min(currentIdx + 1, TYPE_ORDER.length - 1)],
      position: 1,
    };
  }

  return { type: TYPE_ORDER[currentIdx], position: selecting.position + 1 };
};

export const getPrevSelecting = (
  selecting: GameState['selecting'],
): GameState['selecting'] => {
  const gameData = queryClient.getQueryData<GameData>(
    QueryKeys.gameData.default(),
  );

  const availableTypes = gameData
    ? TYPE_ORDER.filter((t) => !isPredictionClosed(gameData, t))
    : TYPE_ORDER;

  if (
    !selecting ||
    (selecting.type === TYPE_ORDER[0] && selecting.position === 1)
  ) {
    return { type: TYPE_ORDER[0], position: 1 };
  }

  const currentIdx = TYPE_ORDER.findIndex((t) => t === selecting.type);

  if (!availableTypes.includes(selecting.type)) {
    return {
      type: TYPE_ORDER[Math.max(currentIdx - 1, 0)],
      position: 3,
    };
  }

  if (selecting.position === 1) {
    return { type: TYPE_ORDER[currentIdx - 1], position: 3 };
  }

  return { type: TYPE_ORDER[currentIdx], position: selecting.position - 1 };
};
