import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { CssBaseline } from '@material-ui/core';
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { FC } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { isDev } from 'utils/env.utils';

import { gtmParams } from 'services/analytics';
import IntlSelectorProvider from 'services/i18n';
import { queryClient } from 'services/react-query';
import storeConfig from 'store';
import { theme01 as theme } from 'theme';

import Pages from 'pages/Pages';

import { RootBoundary } from 'components/@boundaries';
import LoadingState from 'components/@states/LoadingState';

const App: FC = () => (
  <RootBoundary>
    <GTMProvider state={gtmParams}>
      <QueryClientProvider client={queryClient}>
        <StylesProvider injectFirst>
          <MuiThemeProvider theme={theme}>
            <StyledThemeProvider theme={theme}>
              <CssBaseline />
              <ReduxProvider store={storeConfig.store}>
                <IntlSelectorProvider>
                  <PersistGate
                    loading={<LoadingState />}
                    persistor={storeConfig.persistor}
                    onBeforeLift={storeConfig.initApp}
                  >
                    <HelmetProvider>
                      <Pages />
                    </HelmetProvider>
                  </PersistGate>
                </IntlSelectorProvider>
              </ReduxProvider>
            </StyledThemeProvider>
          </MuiThemeProvider>
        </StylesProvider>

        {isDev() && (
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        )}
      </QueryClientProvider>
    </GTMProvider>
  </RootBoundary>
);

export default App;
