import { Box, List } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';
import { useDispatch } from 'react-redux';

import { EventAction } from 'constants/analytics';
import { useAnalytics } from 'hooks';
import { useCompetitions } from 'queries';
import { gameActions } from 'store/game/game.slice';
import { ViewType } from 'types/game.types';

import Button from 'components/@common/Button';
import Loader from 'components/@common/Loader';
import CompetitionListItem from 'components/@list/CompetitionListItem';

import { ModalContainer, ModalScrollContent, ModalTitle } from '../Modal';
import EmptyCompetitions from './EmptyCompetitions';

const MiniCompetitionOverviewModal: React.VFC = () => {
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const { competitions, isLoading } = useCompetitions();
  const isEmpty = !!!competitions?.length;

  const handleCreateCompetition = () => {
    trackEvent({ eventAction: EventAction.MiniCompetitionsCreate });
    dispatch(gameActions.SET_VIEW({ type: ViewType.MiniCompetitionCreate }));
  };

  const handleDetailCompetition = (id: string) => {
    trackEvent({ eventAction: EventAction.MiniCompetitionsView });

    dispatch(
      gameActions.SET_VIEW({
        type: ViewType.MiniCompetitionDetail,
        params: { id },
      }),
    );
  };

  return (
    <ModalContainer>
      <ModalTitle>
        <FormattedMessage id="mini_competition.title" />
      </ModalTitle>
      <ModalScrollContent>
        {isLoading && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Loader fontSize={126} variant="beige" />
          </Box>
        )}
        {!isLoading && isEmpty && (
          <EmptyCompetitions onCreateCompetition={handleCreateCompetition} />
        )}
        {!isLoading && !isEmpty && competitions && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <List>
              {competitions.map((competition) => (
                <CompetitionListItem
                  key={competition.id}
                  title={competition.name}
                  members={competition.members.map((m) => m.firstName)}
                  onClick={() => handleDetailCompetition(competition.id)}
                />
              ))}
            </List>
            <Button variant="secondary" onClick={handleCreateCompetition}>
              <FormattedMessage id="mini_competition.create.button" />
            </Button>
          </Box>
        )}
      </ModalScrollContent>
    </ModalContainer>
  );
};

export default MiniCompetitionOverviewModal;
