import config from 'config';

import RestApiService from './api';
import { createManager } from './api/axios';
import AwsService from './aws';

// You can export here services, ES module exports ensure these will all be a single object
export const apiService = new RestApiService({
  api: createManager({ baseURL: config.API_BASE }),
});

export const awsService = new AwsService();
