import { Box, BoxProps, Chip } from '@material-ui/core';
import React from 'react';

import { PredictionType } from 'types/game.types';

import {
  IcoBanishIcon,
  IcoDeathRow,
  IcoMurderIcon,
  IcoTrash,
} from 'components/@icons';

import { Wrap } from './BoardAvatar.style';

interface Props extends BoxProps {
  label?: string;
  alt?: string;
  imageUrl: string;
  active?: boolean;
  selected?: boolean;
  disabled?: boolean;
  revealedType?: PredictionType;
  isDeathRow?: boolean;
  canDelete?: boolean;
}

const BoardAvatar: React.VFC<Props> = ({
  label,
  alt = label,
  imageUrl,
  active = false,
  selected = false,
  disabled = false,
  revealedType,
  isDeathRow = false,
  canDelete = true,
  ...rest
}) => {
  const showDeathRow = isDeathRow && !revealedType;
  return (
    <Wrap
      {...rest}
      style={{
        ...rest.style,
      }}
      $disabled={disabled}
      $active={active}
      $deathRow={showDeathRow}
      boxShadow={active ? '0px 6px 8px rgba(255, 255, 255, 0.65)' : undefined}
      borderRadius="50%"
      bgcolor="neutral02.500"
      display="flex"
      position="relative"
    >
      <Box
        position="absolute"
        left={3}
        top={3}
        right={3}
        bottom={3}
        borderRadius="50%"
        overflow="hidden"
      >
        {showDeathRow ? (
          <Box
            width="100%"
            height="100%"
            style={{
              backgroundImage: `linear-gradient(0deg, #D62E00, #D62E00), url(${imageUrl})`,
              backgroundBlendMode: 'multiply, normal',
              backgroundSize: 'cover',
            }}
          />
        ) : (
          <img
            alt={`speler ${alt}`}
            src={imageUrl}
            width="100%"
            height="100%"
            style={{ filter: disabled ? 'grayscale(1)' : undefined }}
          />
        )}
      </Box>
      {!selected && (showDeathRow || revealedType) && (
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={10}
          color="neutral01.500"
        >
          {showDeathRow ? (
            <IcoDeathRow fontSize={24} />
          ) : (
            <>
              {revealedType === PredictionType.Banished && (
                <IcoBanishIcon fontSize={24} />
              )}
              {revealedType === PredictionType.Murdered && (
                <IcoMurderIcon fontSize={24} />
              )}
            </>
          )}
        </Box>
      )}

      {canDelete && selected && active && (
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={10}
        >
          <IcoTrash fontSize={32} />
        </Box>
      )}
      {label && (
        <Box
          bottom={-8}
          left={0}
          right={0}
          display="flex"
          justifyContent="center"
          position="absolute"
        >
          <Chip
            label={label}
            color={disabled ? 'secondary' : active ? 'primary' : 'default'}
            style={{
              backgroundColor: showDeathRow ? '#990B13' : undefined,
              color: showDeathRow ? '#fff' : undefined,
            }}
          />
        </Box>
      )}
    </Wrap>
  );
};

export default BoardAvatar;
