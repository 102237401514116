import { Box, Fade } from '@material-ui/core';
import { ParentSize } from '@visx/responsive';
import React from 'react';
import { useSelector } from 'react-redux';

import { getViewType } from 'store/game/game.selectors';
import { Participant, ViewType } from 'types/game.types';

import Loading from 'components/@states/LoadingState';

import BoardFrame from '../BoardFrame';
import BoardInfoPanel from '../BoardInfoPanel';
import BoardModal from '../BoardModal';
import BoardUserPiece from '../BoardUserPiece';
import { BoardContainer } from './Desktop.style';

const getValidSize = (width: number, height: number) => {
  const val = Math.min(width, height);
  if (val % 2 !== 0) {
    return val - 1;
  }
  return val;
};

interface Props {
  participants?: Participant[];
  isLoading?: boolean;
}

const DesktopBoard: React.VFC<Props> = ({ participants, isLoading }) => {
  const viewType = useSelector(getViewType);

  return (
    <Box width="100%" style={{ aspectRatio: '1' }}>
      <BoardFrame variant={viewType === ViewType.MemberGame ? 'green' : 'blue'}>
        {isLoading && <Loading />}
        {!isLoading && participants && (
          <Fade in timeout={1000}>
            <Box height="100%" width="100%" position="relative">
              <ParentSize>
                {({ width, height }) =>
                  viewType === ViewType.Game ||
                  viewType === ViewType.MemberGame ? (
                    <div>
                      <BoardContainer
                        $itemCount={participants.length}
                        $size={getValidSize(width, height)}
                      >
                        {participants.map((p, index) => (
                          <BoardUserPiece
                            key={index}
                            idx={index}
                            participant={p}
                          />
                        ))}
                      </BoardContainer>
                      <BoardInfoPanel />
                    </div>
                  ) : (
                    <Box p={{ xs: 20, md: 12 }} style={{ aspectRatio: '1' }}>
                      <BoardModal />
                    </Box>
                  )
                }
              </ParentSize>
            </Box>
          </Fade>
        )}
      </BoardFrame>
    </Box>
  );
};

export default DesktopBoard;
