import { Box, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';
import { useDispatch } from 'react-redux';

import { EventAction } from 'constants/analytics';
import { useAnalytics } from 'hooks';
import { useCreateCompetition } from 'queries';
import { gameActions } from 'store/game/game.slice';
import { ViewType } from 'types/game.types';

import Button from 'components/@common/Button';
import ShareLinkInfo from 'components/@competition/ShareLinkInfo';

import { ModalContainer, ModalScrollContent, ModalTitle } from '../Modal';

const MiniCompetitionOverviewModal: React.VFC = () => {
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const { createCompetition, isLoading, competition } = useCreateCompetition();
  const [name, setName] = useState('');

  const handleCreate = () => {
    if (name.length) {
      trackEvent({ eventAction: EventAction.MiniCompetitionsConfirm });
      createCompetition(name);
    }
  };

  const handleBack = () => {
    dispatch(gameActions.SET_VIEW({ type: ViewType.MiniCompetitionOverview }));
  };

  return (
    <ModalContainer onBack={handleBack}>
      <ModalTitle>
        <FormattedMessage id="mini_competition.create.title" />
      </ModalTitle>
      <ModalScrollContent>
        {competition ? (
          <ShareLinkInfo id={competition.id} />
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            maxWidth={300}
            mx="auto"
            mt={10}
          >
            <TextField
              label={
                <FormattedMessage id="mini_competition.create.name.input" />
              }
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              disabled={isLoading}
            />
            <Box mt={4} clone>
              <Button
                disabled={!!!name.length}
                variant="secondary"
                fullWidth
                onClick={handleCreate}
                loading={isLoading}
              >
                <FormattedMessage id="mini_competition.create.confirm.button" />
              </Button>
            </Box>
          </Box>
        )}
      </ModalScrollContent>
    </ModalContainer>
  );
};

export default MiniCompetitionOverviewModal;
