import { Box, ButtonBase, Typography } from '@material-ui/core';
import styled from 'styled-components';

import imgPosition from 'assets/png/img-select-position.png';

import { PREDICTION_COLORS } from 'constants/game';
import { Participant, PredictionType } from 'types/game.types';

import BoardAvatar from 'components/@board/BoardAvatar/BoardAvatar';
import { IcoFlagScore } from 'components/@icons';

interface Props {
  position: number;
  onClick: () => void;
  participant?: Participant;
  type?: PredictionType;
  active?: boolean;
  size?: number;
  titleColor?: string;
  hideName?: boolean;
}

const ButtonStyled = styled(ButtonBase)`
  &:hover {
    opacity: 0.8;
  }

  transition: opacity 200ms;
`;

const AvatarButton = ({
  position,
  onClick,
  participant,
  type,
  active,
  size = 100,
  titleColor = 'global01.600',
  hideName = false,
}: Props) => {
  return (
    <ButtonStyled onClick={onClick}>
      <Box>
        <Box
          display="flex"
          position="relative"
          width={size}
          height={size}
          borderRadius="50%"
          border="1px solid"
          borderColor="global01.500"
          mt="auto"
          bgcolor={active ? 'neutral07.300' : 'neutral07.500'}
        >
          {participant ? (
            <>
              <BoardAvatar imageUrl={participant.imageUrl} />
              {type &&
                type !== PredictionType.Disqualified &&
                type !== PredictionType.Seduced && (
                  <Box
                    color={PREDICTION_COLORS[type]}
                    position="absolute"
                    right={-8}
                    bottom={-8}
                    style={{
                      filter: 'drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.55))',
                    }}
                  >
                    <IcoFlagScore width={32} height={46} />
                    <Box
                      position="absolute"
                      bottom={14}
                      left={0}
                      right={0}
                      color="neutral01.500"
                      textAlign="center"
                    >
                      <Typography variant="body1" style={{ fontSize: 21 }}>
                        <b>{position}</b>
                      </Typography>
                    </Box>
                  </Box>
                )}
            </>
          ) : (
            <>
              <img
                src={imgPosition}
                width="100%"
                height="100%"
                alt={`positie ${position}`}
              />
              <Box
                position="absolute"
                top={0}
                right={0}
                left={0}
                bottom={0}
                display="flex"
                justifyContent="center"
                alignItems="center"
                component="span"
                color="global01.600"
              >
                <Typography
                  variant="h1"
                  component="span"
                  style={{ fontSize: 40 }}
                >
                  {position}
                </Typography>
              </Box>
            </>
          )}
        </Box>
        <Box height={30} pt={2} color={titleColor}>
          {participant && !hideName && (
            <Typography variant="h4">{participant.firstName}</Typography>
          )}
        </Box>
      </Box>
    </ButtonStyled>
  );
};

export default AvatarButton;
