import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl-phraseapp';
import { useDispatch, useSelector } from 'react-redux';

import { EventAction } from 'constants/analytics';
import { useAnalytics } from 'hooks';
import {
  useAuth,
  useCreateTraitorGuess,
  useParticipants,
  useTraitorGuesses,
} from 'queries';
import { getCurrentTraitorsSelecting } from 'store/game/game.selectors';
import { gameActions } from 'store/game/game.slice';

import Button from 'components/@common/Button';

import AvatarButton from '../SelectingState/components/AvatarButton';
import SelectModal from '../SelectingState/components/SelectModal';

interface Props {
  mobile?: boolean;
}

const GuessTraitorState: React.VFC<Props> = ({ mobile }) => {
  const { isLoggedIn, login } = useAuth();
  const dispatch = useDispatch();
  const { traitorGuesses } = useTraitorGuesses();
  const { participants } = useParticipants();
  const traitorsSelecting = useSelector(getCurrentTraitorsSelecting);
  const { createTraitorGuess } = useCreateTraitorGuess();
  const didGuess = traitorGuesses?.length >= 3;
  const intl = useIntl();
  const { trackEvent } = useAnalytics();

  const handlePlayClick = () => {
    trackEvent({ eventAction: EventAction.Play });
    login();
  };

  return (
    <Box
      textAlign="center"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      height="100%"
      width="100%"
      mb={8}
    >
      <Box
        height="100%"
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box
          display="flex"
          flex={1}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          mx={2}
          style={{ gap: 12 }}
        >
          <Box maxWidth={200}>
            <Typography variant="h3" color="textSecondary">
              <FormattedMessage id="game.select_traitors.title" />
            </Typography>
          </Box>

          {isLoggedIn && (
            <Box
              pt={2}
              px={4}
              position="relative"
              width="100%"
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              style={{ gap: 12 }}
            >
              {[...Array(3)].map((_, i) => (
                <AvatarButton
                  key={i}
                  size={75}
                  position={i + 1}
                  participant={participants?.find(
                    (p) =>
                      !!traitorGuesses?.find(
                        (t) => t.position === i + 1 && t.participantId === p.id,
                      ),
                  )}
                  titleColor="global01.400"
                  active={traitorsSelecting?.position === i + 1}
                  hideName={!!traitorsSelecting}
                  onClick={() => {
                    dispatch(gameActions.SET_TRAITORS_SELECTING(undefined));
                    dispatch(
                      gameActions.SET_TRAITORS_SELECTING({
                        position: i + 1,
                      }),
                    );
                  }}
                />
              ))}
              {!!traitorsSelecting && (
                <Box position="absolute" bottom={-4}>
                  <Typography variant="body1" color="textSecondary">
                    <b>
                      <FormattedMessage id="game.select_traitors.select.label" />
                    </b>
                  </Typography>
                </Box>
              )}
            </Box>
          )}
          <Typography variant="body1" color="textSecondary">
            <FormattedMessage
              id={
                didGuess
                  ? 'game.select_traitors.voted.info'
                  : 'game.select_traitors.info'
              }
            />
          </Typography>
          {!isLoggedIn && (
            <Button variant="primary" fullWidth onClick={handlePlayClick}>
              <FormattedMessage id="game.start.play.button" />
            </Button>
          )}
        </Box>
      </Box>
      {mobile && !!traitorsSelecting && (
        <SelectModal
          onClose={() =>
            dispatch(gameActions.SET_TRAITORS_SELECTING(undefined))
          }
          title={intl.formatMessage({
            id: 'game.select_traitors.select.mobile.label',
          })}
          position={traitorsSelecting.position}
          participants={participants ?? []}
          onSelect={(p) =>
            createTraitorGuess({
              participantId: p.id,
              position: traitorsSelecting.position,
            })
          }
        />
      )}
    </Box>
  );
};

export default GuessTraitorState;
