import { ButtonBase, Checkbox, Typography } from '@material-ui/core';
import { transparentize } from 'polished';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { useSeducedPrediction } from 'queries';

const ButtonStyled = styled(ButtonBase)`
  border: 1px solid ${({ theme }) => theme.palette.global01[400]};
  background-color: rgba(255, 255, 255, 0.5);
  padding: ${({ theme }) => theme.spacing(3)}px;

  transition: background-color 200ms;

  &:hover {
    background-color: ${({ theme }) =>
      transparentize(0.8, theme.palette.global01[400])};
  }
`;

const SeducedButton = () => {
  const { isSeduced, setSeduced } = useSeducedPrediction();

  const handleSeduceToggle = (checked: boolean) => {
    setSeduced(checked);
  };

  return (
    <ButtonStyled onClick={() => handleSeduceToggle(!isSeduced)}>
      <Checkbox
        style={{ padding: 0, marginRight: 4 }}
        onChange={(e) => e.target.checked}
        checked={isSeduced}
      />
      <Typography variant="body1">
        <FormattedMessage id="game.select.seduced.label" />
      </Typography>
    </ButtonStyled>
  );
};

export default SeducedButton;
