import { Box, ButtonBaseProps } from '@material-ui/core';
import React from 'react';

import { IcoBonusButton } from 'components/@icons';

import { ButtonBaseStyled } from './BonusButton.style';

const BonusButton: React.FC<ButtonBaseProps> = ({ children, ...rest }) => {
  return (
    <ButtonBaseStyled {...rest}>
      <Box position="absolute" top={-6} right={15}>
        <IcoBonusButton width={34} height={48} />
      </Box>
      <Box width="100%" ml={3} mr={14} my={2}>
        {children}
      </Box>
    </ButtonBaseStyled>
  );
};

export default BonusButton;
