import * as React from 'react';

function SvgIcoSurviveIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M14.542 10.742L12 5.487l-2.542 5.255-6.282-5.109 2.786 10.78h12.076l2.787-10.78-6.283 5.11zM4.496 17.146H19.48v1.467H4.496v-1.466z"
        fill="currentColor"
      />
    </svg>
  );
}
const MemoSvgIcoSurviveIcon = React.memo(SvgIcoSurviveIcon);
export default MemoSvgIcoSurviveIcon;
