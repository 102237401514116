import { Box, Link } from '@material-ui/core';
import { FormattedMessage } from 'react-intl-phraseapp';
import { useDispatch } from 'react-redux';

import { EventAction } from 'constants/analytics';
import { useAnalytics } from 'hooks';
import { useStats } from 'queries';
import { gameActions } from 'store/game/game.slice';
import { PredictionType, ViewType } from 'types/game.types';

import { RenderState } from 'components/@board/useRenderState';
import { IcoArrowLeft, IcoArrowRight } from 'components/@icons';

const StatsButton = () => {
  const { trackEvent } = useAnalytics();
  const { stats } = useStats();

  const dispatch = useDispatch();

  const handleShowStats = () => {
    trackEvent({ eventAction: EventAction.TopPredictions });

    dispatch(
      gameActions.SET_VIEW({
        type: ViewType.Game,
        params: {
          state: RenderState.Stats,
        },
      }),
    );
  };

  return (
    <Box color="blue.400" my={2}>
      {stats &&
        stats[PredictionType.Murdered].length >= 3 &&
        stats[PredictionType.Survivor].length >= 3 &&
        stats[PredictionType.Banished].length >= 3 && (
          <Link component="button" onClick={handleShowStats} color="inherit">
            <IcoArrowRight fontSize={24} />
            <Box mx={2}>
              <FormattedMessage id="game.general.stats.button" />
            </Box>
            <IcoArrowLeft fontSize={24} />
          </Link>
        )}
    </Box>
  );
};

export default StatsButton;
