import * as React from 'react';

function SvgIcoCheck(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.415 11.598l5.683-5.684L11.684 4.5 7.412 8.772 5.028 6.389 3.614 7.803l3.748 3.748.003-.003.05.05z"
        fill="#fff"
      />
    </svg>
  );
}
const MemoSvgIcoCheck = React.memo(SvgIcoCheck);
export default MemoSvgIcoCheck;
