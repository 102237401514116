import { Box, ButtonBase } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const ButtonWrap = styled(ButtonBase)`
  && {
    display: flex;
    pointer-events: auto;
  }
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const AnimationWrap = styled(Box)<{
  $idx: number;
  $disableAnimation: boolean;
}>`
${({ $disableAnimation, $idx }) =>
  $disableAnimation
    ? css`
        opacity: 1;
      `
    : css`
        animation: bounceUser 750ms cubic-bezier(0.175, 0.885, 0.32, 1.275)
          ${$idx * 80}ms both;

        @keyframes bounceUser {
          0% {
            transform: scale(0);
            opacity: 0;
          }
          100% {
            opacity: 1;
            transform: scale(1);
          }
        }
      `})}

`;
