import { Box, Fade } from '@material-ui/core';
import { ParentSize } from '@visx/responsive';
import React from 'react';
import CountUp from 'react-countup';
import { FormattedMessage } from 'react-intl-phraseapp';

import Loader from 'components/@common/Loader';

import {
  AnimationWrap,
  CenterBar,
  LeftBar,
  RightBar,
  SilverBar,
  SilverInactiveBar,
  SilverLabel,
  SilverLostBar,
  SilverValue,
  Title,
} from './SilverCard.style';

interface Props {
  loading?: boolean;
  silverCount?: number;
  lostSilverCount?: number;
}

const MAX_SILVER_COUNT = 5;
const SILVER_PRICE = 1000;

interface SilverProps {
  count: number;
  parentWidth: number;
  barScale: number;
}

const ActiveSilver: React.VFC<SilverProps> = ({
  count,
  parentWidth,
  barScale,
}) => {
  return (
    <Box zIndex={3} position="relative" display="flex" alignItems="center">
      {[...Array(count)].map((_, i) => (
        <AnimationWrap key={i} $idx={i}>
          <Box
            position="relative"
            width={Math.min((parentWidth / MAX_SILVER_COUNT) * barScale, 45)}
            height="100%"
          >
            <Box
              width={
                Math.min((parentWidth / MAX_SILVER_COUNT) * barScale, 45) * 2.2
              }
              style={{ transform: 'translateX(-25%)' }}
            >
              <SilverBar />
            </Box>
          </Box>
        </AnimationWrap>
      ))}
    </Box>
  );
};

const InactiveSilver: React.VFC<SilverProps> = ({
  count,
  parentWidth,
  barScale,
}) => {
  return (
    <Box zIndex={1} position="relative" display="flex" alignItems="center">
      {[...Array(count)].map((_, i) => (
        <Box
          key={i}
          position="relative"
          width={Math.min((parentWidth / MAX_SILVER_COUNT) * barScale, 45)}
        >
          <Box
            width={
              Math.min((parentWidth / MAX_SILVER_COUNT) * barScale, 45) * 1.4
            }
            height="100%"
            style={{ transform: 'translate(-25% )' }}
          >
            <SilverInactiveBar />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const LostSilver: React.VFC<SilverProps> = ({
  count,
  parentWidth,
  barScale,
}) => {
  return (
    <Box zIndex={1} position="relative" display="flex" alignItems="center">
      {[...Array(count)].map((_, i) => (
        <Box
          key={i}
          position="relative"
          width={Math.min((parentWidth / MAX_SILVER_COUNT) * barScale, 45)}
        >
          <Box
            width={
              Math.min((parentWidth / MAX_SILVER_COUNT) * barScale, 45) * 1.4
            }
            height="100%"
            style={{ transform: 'translate(-25% )' }}
          >
            <SilverLostBar />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const SilverCard: React.VFC<Props> = ({
  loading = false,
  silverCount: initialSilverCount = 0,
  lostSilverCount: initialLostSilverCount = 0,
}) => {
  const silverCount = Math.max(
    Math.min(initialSilverCount, MAX_SILVER_COUNT),
    0,
  );
  const lostSilverCount = Math.max(
    Math.min(initialLostSilverCount, MAX_SILVER_COUNT - silverCount),
    0,
  );
  const inactiveSilverCount = MAX_SILVER_COUNT - silverCount - lostSilverCount;

  return (
    <Box
      display="flex"
      flexDirection="row"
      height={{ xs: 80, sm: 100 }}
      width="100%"
    >
      <LeftBar />
      <CenterBar>
        {loading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <Loader fontSize={40} />
          </Box>
        ) : (
          <ParentSize>
            {({ width, height }) => {
              let barScale = 1;
              // Check if our silver bars are not taking up to much space by calculating the size based on the ratio
              if (
                height * 0.6 <
                (width / MAX_SILVER_COUNT) * (81 / 113) * 2.2
              ) {
                barScale = 0.8;
              }

              return (
                <Fade in timeout={500}>
                  <Box
                    position="relative"
                    width="100%"
                    height="100%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="space-evenly"
                  >
                    <Box display="flex" alignItems="baseline" lineHeight={1}>
                      <Box pr={{ xs: 1, sm: 2 }} clone>
                        <Title>
                          <FormattedMessage id="silver.price.label" />
                        </Title>
                      </Box>
                      <SilverLabel>
                        <FormattedMessage id="silver.price.amount.label" />
                      </SilverLabel>
                      <Box px={{ xs: 1, sm: 2 }} clone>
                        <Title>
                          <FormattedMessage id="silver.price.value.label" />
                        </Title>
                      </Box>
                      <Box
                        width={{
                          xs: `${SILVER_PRICE * silverCount}`.length * 10 + 20,
                          sm: `${SILVER_PRICE * silverCount}`.length * 22 + 45,
                        }}
                        display="flex"
                        alignItems="flex-start"
                      >
                        <SilverValue>
                          <CountUp
                            end={SILVER_PRICE * silverCount}
                            prefix="€ "
                            separator="."
                            duration={silverCount * 0.25}
                          />
                        </SilverValue>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      width="100%"
                      alignItems="center"
                      justifyContent="center"
                      height={30}
                      position="absolute"
                      top={-14}
                    >
                      <ActiveSilver
                        count={silverCount}
                        parentWidth={width}
                        barScale={barScale}
                      />
                      <InactiveSilver
                        count={inactiveSilverCount}
                        parentWidth={width}
                        barScale={barScale}
                      />
                      {lostSilverCount > 0 && (
                        <LostSilver
                          count={lostSilverCount}
                          parentWidth={width}
                          barScale={barScale}
                        />
                      )}
                    </Box>
                  </Box>
                </Fade>
              );
            }}
          </ParentSize>
        )}
      </CenterBar>
      <RightBar />
    </Box>
  );
};

export default SilverCard;
