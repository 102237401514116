import { Box, CircularProgress, Link, Typography } from '@material-ui/core';
import { transparentize } from 'polished';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import { FormattedMessage } from 'react-intl-phraseapp';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';

import imgPointsWin from 'assets/png/img-points-win.png';
import scoreLogo from 'assets/png/img-total-score-logo.png';

import { EventAction } from 'constants/analytics';
import { useAnalytics } from 'hooks';
import { useBonusQuestion, useParticipants, useScoreBreakdown } from 'queries';
import { gameActions } from 'store/game/game.slice';
import { ScoreBreakdownEventType, ViewType } from 'types/game.types';

import BonusButton from 'components/@common/BonusButton';
import { IcoArrowRight, IcoShare } from 'components/@icons';
import ScoreBreakdownListItem from 'components/@list/ScoreBreakdownListItem';
import ShareLinkButton from 'components/@share/ShareLinkButton';

import { Background, Borders, Wrap } from './ScoreCard.style';

const ScoreCard: React.VFC = () => {
  const { trackEvent } = useAnalytics();

  const theme = useTheme();
  const dispatch = useDispatch();
  const { participants } = useParticipants();
  const { scoreBreakdown } = useScoreBreakdown();
  const { bonusQuestion, error: bonusError } = useBonusQuestion();
  const score = scoreBreakdown?.totalScore;

  const lastBreakdown = scoreBreakdown?.breakdown[0];

  const handleShowOverview = () => {
    trackEvent({ eventAction: EventAction.ScoreBreakdown });
    dispatch(gameActions.SET_VIEW({ type: ViewType.ScoreBreakdown }));
  };

  const handleShowBonusQuestion = () => {
    trackEvent({ eventAction: EventAction.BonusQuestion });
    dispatch(gameActions.SET_VIEW({ type: ViewType.BonusQuestion }));
  };

  return (
    <Wrap>
      <Background />
      <Borders />
      <Box
        zIndex={10}
        display="flex"
        textAlign="left"
        alignItems="center"
        flexDirection="column"
      >
        <Box
          display="flex"
          textAlign="left"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          mb={2}
        >
          <Box display="flex">
            <img src={scoreLogo} width={50} height={50} alt="score" />
            <Box mx={4}>
              <Typography variant="body2">
                <FormattedMessage id="score.card.my_points" />
              </Typography>
              <Typography variant="h3">
                {score !== undefined && score >= 0 ? (
                  <FormattedNumber value={score} />
                ) : (
                  <CircularProgress size={14} />
                )}
              </Typography>
            </Box>
          </Box>
          <ShareLinkButton variant="score" color="primary" underline="none">
            <Box
              borderRadius={5}
              border="1px solid #DEC086"
              p={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
              fontSize={8}
            >
              <Box mb={1} clone>
                <IcoShare fontSize={14} />
              </Box>
              <b>
                <FormattedMessage id="score.card.share" />
              </b>
            </Box>
          </ShareLinkButton>
        </Box>

        {lastBreakdown &&
          lastBreakdown?.type !== ScoreBreakdownEventType.BonusQuestion &&
          lastBreakdown?.type !== ScoreBreakdownEventType.PollQuestion && (
            <ScoreBreakdownListItem
              breakdown={lastBreakdown}
              participant={participants?.find(
                (p) => p.id === lastBreakdown.event.participantId,
              )}
            />
          )}

        {bonusQuestion && !bonusError && (
          <Box mt={3} width="100%">
            {bonusQuestion.userAnsweredCorrectly === null &&
              (bonusQuestion.pointsScored ?? 0) <= 0 && (
                <BonusButton onClick={handleShowBonusQuestion}>
                  <Box
                    fontSize={11}
                    color={transparentize(0.3, '#FFF5DC')}
                    fontWeight={600}
                  >
                    <FormattedMessage
                      id={`${
                        bonusQuestion.questionType === 'poll_question'
                          ? 'poll'
                          : 'bonus'
                      }.button.top.title`}
                    />
                  </Box>
                  <Box
                    color="neutral01.500"
                    fontFamily="Butler"
                    fontSize={20}
                    display="flex"
                  >
                    <FormattedMessage
                      id={`${
                        bonusQuestion.questionType === 'poll_question'
                          ? 'poll'
                          : 'bonus'
                      }.button.bottom.title`}
                    />
                    <Box pl={1} pt="2px" height={15}>
                      <IcoArrowRight width={27} height={15} />
                    </Box>
                  </Box>
                </BonusButton>
              )}
            {!!bonusQuestion.pointsScored && (
              <Box
                bgcolor={transparentize(0.7, theme.palette.global01[400])}
                borderRadius={5}
                py={2}
                color="global01.600"
                position="relative"
              >
                <Box
                  display="flex"
                  position="absolute"
                  width={55}
                  height={55}
                  top={-10}
                >
                  <img
                    src={imgPointsWin}
                    width="100%"
                    height="100%"
                    alt={`bonus score is ${bonusQuestion.pointsScored}`}
                  />
                  <Box
                    position="absolute"
                    top={0}
                    right={0}
                    left={0}
                    bottom={0}
                    fontSize={14}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="h4" color="textPrimary">
                      {bonusQuestion.pointsScored}
                    </Typography>
                  </Box>
                </Box>
                <Box pl={15} pr={3} clone>
                  <Typography variant="h4">
                    <FormattedMessage
                      id={
                        bonusQuestion.questionType === 'poll_question'
                          ? 'score.card.poll_points'
                          : 'score.card.bonus_points'
                      }
                    />
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        )}

        <Box mt={3} minHeight={20}>
          {score !== undefined && (
            <Link
              component="button"
              onClick={handleShowOverview}
              style={{ fontSize: 14 }}
            >
              <FormattedMessage id="score.card.score_overview" />
            </Link>
          )}
        </Box>
      </Box>
    </Wrap>
  );
};

export default ScoreCard;
