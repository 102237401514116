import * as React from 'react';

function SvgIcoPhraseapp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 32 32"
      width="1em"
      height="1em"
      {...props}
    >
      <defs>
        <linearGradient
          id="ico-phraseapp_svg__a"
          x1={18.968}
          y1={19.819}
          x2={21.935}
          y2={18.739}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.33} stopColor="#f60" />
          <stop offset={1} stopColor="#ffb800" />
        </linearGradient>
        <linearGradient
          id="ico-phraseapp_svg__b"
          x1={18.996}
          y1={13.493}
          x2={30.06}
          y2={12.525}
          xlinkHref="#ico-phraseapp_svg__a"
        />
        <linearGradient
          id="ico-phraseapp_svg__c"
          x1={13.867}
          y1={16.752}
          x2={25.287}
          y2={6.103}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#e7e7e7" />
          <stop offset={0.024} stopColor="#eaeaea" />
          <stop offset={0.178} stopColor="#f5f5f5" />
          <stop offset={0.396} stopColor="#fcfcfc" />
          <stop offset={1} stopColor="#fefefe" />
        </linearGradient>
        <linearGradient
          id="ico-phraseapp_svg__d"
          x1={16.345}
          y1={29.317}
          x2={17.597}
          y2={5.432}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#05588c" />
          <stop offset={1} stopColor="#3db5ff" />
        </linearGradient>
        <linearGradient
          id="ico-phraseapp_svg__e"
          x1={8.012}
          y1={25.566}
          x2={21.637}
          y2={1.967}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.4} stopColor="#005082" />
          <stop offset={1} stopColor="#2d9bdf" />
        </linearGradient>
      </defs>
      <path d="M19.455 6.767a2.241 2.241 0 11-1.16.789 2.248 2.248 0 011.16-.789zm1.036.551a.787.787 0 10.943.491.786.786 0 00-.943-.491z" />
      <path
        d="M22.462 20.323a2.109 2.109 0 00-.327-.87 5.962 5.962 0 00-.721-.906c-.186-.218-.407-.4-.593-.623-.148-.183-.334-.332-.486-.512-.115.252-.229.5-.342.757l-.807 1.789-.091.2a7.258 7.258 0 001.884.368 7.073 7.073 0 00.865 0c.178-.009.356-.026.534-.049a.591.591 0 00.081-.027.312.312 0 00.003-.127z"
        fill="url(#ico-phraseapp_svg__a)"
      />
      <path
        d="M28.15 6.631a9.4 9.4 0 00-2.08-2.045l-.053.12-.518 1.161c-.046.1-.093.208-.139.312l-.131.295-.76 1.7-.654 1.468-.449 1.007q-.182.412-.366.823a2.005 2.005 0 01-.727.875 4.464 4.464 0 01-1.273.482 2.827 2.827 0 00-1.1.516 2.324 2.324 0 00-.733 1.36 2.272 2.272 0 00.17 1.277 5.384 5.384 0 00.995 1.431c.152.18.337.329.486.512.186.218.406.4.593.623a5.962 5.962 0 01.721.906 2.109 2.109 0 01.327.87.312.312 0 010 .133.591.591 0 01-.081.027l.091-.011a1.753 1.753 0 01-.364 1.035 9.418 9.418 0 002.464-.755c.238-.108.469-.231.7-.358.294-.16.57-.352.846-.539v-.014a.582.582 0 00.123-.082 9.446 9.446 0 001.617-1.56 9.418 9.418 0 002.045-4.608 9.423 9.423 0 00-1.75-6.961z"
        fill="url(#ico-phraseapp_svg__b)"
      />
      <path
        d="M24.52 5.672A7.788 7.788 0 0021 4.729a7.618 7.618 0 00-2.116.24q-.327.085-.644.2a8.75 8.75 0 00-.625.26 7.859 7.859 0 00-1.575 1.029 8.292 8.292 0 00-1.1 1.117 8.653 8.653 0 00-.635.888 8.697 8.697 0 00-.316.546 9.013 9.013 0 00-.437.963q-.139.368-.245.747a7.773 7.773 0 00-.255 1.4c0 .043-.008.087-.012.131a6.762 6.762 0 00.125 1.872 9.706 9.706 0 00.445 1.558 7.2 7.2 0 001.379 2.273A6.681 6.681 0 0016.6 19.24a8.4 8.4 0 002.5.922l.091-.2.807-1.789c.114-.253.227-.505.342-.757a5.384 5.384 0 01-.995-1.431 2.272 2.272 0 01-.17-1.277 2.324 2.324 0 01.733-1.36 2.827 2.827 0 011.1-.516 4.464 4.464 0 001.272-.478 2.005 2.005 0 00.72-.878l.366-.823.449-1.007q.326-.735.654-1.468.379-.852.76-1.7c.043-.1.088-.2.131-.295-.281-.172-.551-.358-.84-.511zm-3.073 5.011a2.237 2.237 0 11.829-1.383 2.24 2.24 0 01-.829 1.383z"
        fill="url(#ico-phraseapp_svg__c)"
      />
      <path
        d="M23.636 29.54q-.631-.519-1.212-1.094a13.578 13.578 0 01-2.575-3.465h-.019.018a9.02 9.02 0 01-.745-2.015 6.46 6.46 0 01-.17-1.793 4.814 4.814 0 01.162-1.007 8.4 8.4 0 01-2.5-.922 6.681 6.681 0 01-1.611-1.289 7.2 7.2 0 01-1.379-2.273 9.706 9.706 0 01-.445-1.558 6.762 6.762 0 01-.125-1.872c0-.044.007-.088.012-.131a7.774 7.774 0 01.255-1.4q.107-.378.245-.747a9.013 9.013 0 01.437-.963c.1-.185.2-.369.316-.546a8.653 8.653 0 01.635-.888 8.292 8.292 0 011.1-1.117 7.859 7.859 0 011.578-1.025 2.9 2.9 0 00-.463.16 14.011 14.011 0 00-1.926.953 12.41 12.41 0 00-1.037.694 11.431 11.431 0 00-1.433 1.255c-.173.17-.331.355-.492.537a9.398 9.398 0 00-.27.328c-.164.2-.314.413-.462.626a11.271 11.271 0 00-.617 1.009c-.065.119-.126.241-.187.362-.133.26-.248.53-.358.8-.081.2-.15.4-.22.6l-.056.164a11.221 11.221 0 00-.323 1.247c-.044.208-.077.417-.11.627q-.076.522-.1 1.049c-.02.258-.016.517-.023.775.014.3.019.592.047.887a12.423 12.423 0 00.985 3.876 10.239 10.239 0 00.616 1.235 12.73 12.73 0 001.444 2.026c.182.251.434.439.623.684.071.084.166.141.243.218.231.24.492.448.744.666a14.316 14.316 0 002.781 1.777 17.28 17.28 0 003.184 1.158 19.536 19.536 0 002.953.5 5.535 5.535 0 01.588.064l.068-.008a1.378 1.378 0 00-.176-.164z"
        fill="url(#ico-phraseapp_svg__d)"
      />
      <path
        d="M20.941 2.342a14.35 14.35 0 00-3.335-.328 13.966 13.966 0 00-2.24.217c-.666.122-1.327.276-1.982.449-.773.2-1.539.428-2.3.662-.806.246-1.61.5-2.416.747-.683.2-1.369.4-2.062.574a14.452 14.452 0 01-2 .36A5.927 5.927 0 013.141 5 2.983 2.983 0 012 4.584a6.854 6.854 0 001.254 2.91 6.864 6.864 0 002.065 1.84 14.842 14.842 0 00-.624 1.6 14.254 14.254 0 00-.383 1.419v.025l-.007-.01a13.473 13.473 0 00-.29 2.991 13.643 13.643 0 001.906 6.684 15.409 15.409 0 003.537 4.111 16.888 16.888 0 006.345 3.231 18.478 18.478 0 007.938.319 5.534 5.534 0 00-.588-.064 19.536 19.536 0 01-2.953-.5 17.28 17.28 0 01-3.184-1.158 14.316 14.316 0 01-2.781-1.777c-.252-.218-.513-.426-.744-.666-.078-.077-.173-.134-.243-.218-.189-.245-.441-.433-.623-.684a12.73 12.73 0 01-1.444-2.026 10.239 10.239 0 01-.616-1.235 12.423 12.423 0 01-.978-3.876c-.028-.295-.034-.591-.047-.887.007-.258 0-.517.023-.775q.026-.527.1-1.049c.033-.209.065-.419.11-.627a11.221 11.221 0 01.323-1.247l.056-.164c.07-.2.138-.4.22-.6a10.424 10.424 0 01.545-1.162 11.271 11.271 0 01.617-1.009 10.975 10.975 0 01.732-.954c.161-.182.319-.367.492-.537.228-.234.465-.461.711-.676.236-.2.472-.4.722-.579a12.41 12.41 0 011.037-.694 14.011 14.011 0 011.926-.953 2.9 2.9 0 01.463-.16c.205-.094.413-.183.625-.26s.426-.146.644-.2A7.618 7.618 0 0121 4.729a7.788 7.788 0 013.517.943c.289.153.559.339.839.508.046-.1.093-.208.139-.312l.518-1.161.053-.12a14.306 14.306 0 00-5.125-2.245z"
        fill="url(#ico-phraseapp_svg__e)"
      />
    </svg>
  );
}
const MemoSvgIcoPhraseapp = React.memo(SvgIcoPhraseapp);
export default MemoSvgIcoPhraseapp;
