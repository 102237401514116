import React from 'react';
import styled, { css } from 'styled-components';

import IcoLoading from 'components/@icons/IcoLoading';

interface Props {
  variant?: 'default' | 'white' | 'beige';
}

const Rotate = styled(IcoLoading)<{ $variant: Props['variant'] }>`
  animation: rotating 1500ms linear infinite;

  ${({ $variant }) => {
    if ($variant === 'white') {
      return css`
        filter: brightness(0) invert(1);
        opacity: 0.7;
      `;
    } else if ($variant === 'beige') {
      return css`
        filter: sepia(1);
        opacity: 0.7;
      `;
    }
  }}

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const Loader: React.VFC<React.SVGProps<SVGSVGElement> & Props> = ({
  ref,
  variant = 'default',
  ...rest
}) => {
  return <Rotate $variant={variant} {...rest} />;
};

export default Loader;
