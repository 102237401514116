/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import _reject from 'lodash/reject';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { QueryKeys, useAuth } from 'queries';
import { apiService } from 'services';
import { gameActions } from 'store/game/game.slice';
import { Prediction, PredictionPostData } from 'types/game.types';

const deletePrediction = async (userId: string, data: PredictionPostData) => {
  const { data: response } = await apiService.deletePrediction(userId, data);
  return response;
};

export const useDeletePrediction = (
  config: UseMutationOptions<
    Prediction,
    AxiosError,
    PredictionPostData,
    { previousPredictions: Prediction[] }
  > = {},
) => {
  const queryClient = useQueryClient();
  const { userId } = useAuth();
  const dispatch = useDispatch();

  const { mutate, isLoading } = useMutation(
    (data) => deletePrediction(userId!, data),
    {
      ...config,
      onMutate: async (data) => {
        await queryClient.cancelQueries(QueryKeys.predictions.byId(userId!));

        const previousPredictions =
          queryClient.getQueryData<Prediction[]>(
            QueryKeys.predictions.byId(userId!),
          ) ?? [];

        queryClient.setQueryData<Prediction[] | undefined>(
          QueryKeys.predictions.byId(userId!),
          (old) => {
            let finalData: Prediction[];

            if (!old) {
              finalData = [];
            } else {
              const filteredPredictions = _reject(
                old,
                (p) =>
                  p.predictionType === data.predictionType &&
                  p.position === data.position &&
                  p.participantId === data.participantId,
              );

              finalData = [...filteredPredictions];
            }

            dispatch(gameActions.SET_PREDICTIONS(finalData));

            return finalData;
          },
        );

        return { previousPredictions };
      },
      onError: (_err, _id, ctx) => {
        if (ctx?.previousPredictions) {
          queryClient.setQueryData(
            QueryKeys.predictions.byId(userId!),
            ctx.previousPredictions,
          );
        }
      },
    },
  );

  const handleDeletePrediction = useCallback(
    (data: PredictionPostData) => {
      if (userId) {
        mutate(data);
      }
    },
    [mutate, userId],
  );

  return {
    deletePrediction: handleDeletePrediction,
    isLoading,
  };
};
