import styled from 'styled-components';

import imgSideBarLeft from 'assets/png/img-leaderboard-bar-left.png';
import imgCenterBar from 'assets/png/img-leaderboard-bar-rep.png';
import imgSideBarRight from 'assets/png/img-leaderboard-bar-right.png';

export const BarWrap = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: -1;
`;
export const LeftBar = styled.div`
  background: url(${imgSideBarLeft});
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  aspect-ratio: 89 / 106;

  display: none;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: block;
  }
`;

export const RightBar = styled.div`
  background: url(${imgSideBarRight});
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  aspect-ratio: 89 / 106;

  display: none;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: block;
  }
`;

export const CenterBar = styled.div`
  width: 100%;
  background: url(${imgCenterBar});
  background-size: contain;
  background-repeat: repeat-x;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.neutral01[500]};
`;
