import { QueryCache, QueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { QueryKeys } from 'queries';
import store from 'store';
import { gameActions } from 'store/game/game.slice';

const queryCache = new QueryCache();

export const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount: number, error: unknown) => {
        const status = (error as AxiosError)?.response?.status;
        const code = (error as AxiosError)?.code;
        if (status === 401 || status === 404 || code === 'ECONNABORTED') {
          if (status === 401) {
            store.store.dispatch(gameActions.CLEAR_PREDICTIONS());
            queryClient.setQueryData(QueryKeys.user.me(), false);
          }
          return false;
        }
        return failureCount < 2;
      },
    },
  },
});
