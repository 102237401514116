import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from 'queries';
import { StaleTime } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { Competition } from 'types/competition.types';

const getCompetition = async (competitionId: string) => {
  const { data: response } = await apiService.getCompetition(competitionId);
  return response;
};

export const useCompetition = (
  competitionId?: string,
  config: UseCommonQueryOptions<Competition> = { enabled: true },
) => {
  const { data, status, isInitialLoading, error } = useQuery(
    QueryKeys.competitions.byId(competitionId ?? ''),
    // We can disable it because the query isn't enabled without a valid competitionId
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => getCompetition(competitionId!),
    {
      ...config,
      enabled: !!competitionId?.length && config.enabled,
      staleTime: config?.staleTime ?? StaleTime.MINUTE,
    },
  );

  return {
    competition: data,
    status,
    isLoading: isInitialLoading,
    error,
  };
};
