import styled from 'styled-components';

export const Wrap = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.palette.neutral07[500]};

  padding: ${({ theme }) => theme.spacing(4)}px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    bottom: 8px;
    left: 8px;
    right: 8px;
    border: 1px solid ${({ theme }) => theme.palette.global01[500]};
  }
`;
