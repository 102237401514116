export type Participant = {
  id: string;
  firstName: string;
  lastName: string;
  seatingPosition: number;
  imageUrl: string;
  isTraitor: boolean;
  isBanished: boolean;
  isMurdered: boolean;
  isSurvivor: boolean;
  isSeduced: boolean;
  isDeathRow?: boolean;
  isDisqualified?: boolean;
};

export type GameData = {
  silverCount: number;
  silverLost: number;
  predictionsBanishedAreOpen: boolean;
  predictionsMurderedAreOpen: boolean;
  predictionsSurvivorAreOpen: boolean;
};

export enum PredictionType {
  Banished = 'BANISHED',
  Murdered = 'MURDERED',
  Survivor = 'SURVIVOR',
  Disqualified = 'DISQUALIFIED',
  Seduced = 'SEDUCED',
}

export type Prediction = {
  position: number;
  participantId: string;
  userId: string;
  createdAt: string;
  predictionType: PredictionType;
  isCorrect?: boolean | null;
};

export type PredictionPostData = Pick<
  Prediction,
  'position' | 'participantId' | 'predictionType'
>;

export type TraitorGuess = { position: number; participantId: string };
export type TraitorGuessPostData = TraitorGuess;

export enum ViewType {
  Game = 'game',
  Rules = 'rules',
  MemberGame = 'member-game',
  ScoreBreakdown = 'score-breakdown',
  Leaderboard = 'leaderboard',
  BonusQuestion = 'bonus-question',
  MiniCompetitionOverview = 'mini-competition-overview',
  MiniCompetitionCreate = 'mini-competition-create',
  MiniCompetitionJoin = 'mini-competition-join',
  MiniCompetitionDetail = 'mini-competition-detail',
  MiniCompetitionEdit = 'mini-competition-edit',
  MiniCompetitionEditName = 'mini-competition-edit-name',
  MiniCompetitionMemberDelete = 'mini-competition-member-delete',
}

export enum ScoreBreakdownEventType {
  Banished = 'BANISHED',
  Murdered = 'MURDERED',
  Survivor = 'SURVIVOR',
  Disqualified = 'DISQUALIFIED',
  BonusQuestion = 'BONUS_QUESTION',
  Seduced = 'SEDUCED',
  PollQuestion = 'POLL_QUESTION',
}

export type Stats = {
  firstName: string;
  percentage: number;
};

export type PredictionStats = {
  [PredictionType.Banished]: Stats[];
  [PredictionType.Murdered]: Stats[];
  [PredictionType.Survivor]: Stats[];
};

export type ScoreBreakdownEvent = {
  participantId: string;
  isCorrect: boolean;
  points: number;
  position: number;
  correctVoteStreakInWeeks?: number;
};

export type ScoreBreakdownBonusEvent = {
  points: number;
  isCorrect: number;
};

export type Breakdown =
  | {
      event: ScoreBreakdownEvent;
      type:
        | ScoreBreakdownEventType.Banished
        | ScoreBreakdownEventType.Murdered
        | ScoreBreakdownEventType.Survivor
        | ScoreBreakdownEventType.Disqualified
        | ScoreBreakdownEventType.Seduced;
    }
  | {
      event: ScoreBreakdownBonusEvent;
      type: ScoreBreakdownEventType.BonusQuestion;
    }
  | {
      event: ScoreBreakdownBonusEvent;
      type: ScoreBreakdownEventType.PollQuestion;
    };

export type ScoreBreakdown = {
  breakdown: Breakdown[];
  totalScore: number;
};
