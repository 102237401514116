import type {
  Prediction,
  PredictionPostData,
  ScoreBreakdown,
  TraitorGuess,
  TraitorGuessPostData,
} from 'types/game.types';
import { User } from 'types/user.types';

import RestApiService from './';

export default {
  getUser(this: RestApiService) {
    return this.api.get<User>('users/me');
  },
  getScoreBreakdown(this: RestApiService, userId: string) {
    return this.api.get<ScoreBreakdown>(`users/${userId}/score-breakdown`);
  },
  getPredictions(this: RestApiService, userId: string) {
    return this.api.get<Prediction[]>(`users/${userId}/predictions`);
  },
  getTraitorGuesses(this: RestApiService, userId: string) {
    return this.api.get<TraitorGuess[]>(`users/${userId}/predictions/traitor`);
  },
  postTraitorGuess(
    this: RestApiService,
    userId: string,
    data: TraitorGuessPostData,
  ) {
    return this.api.post<TraitorGuess>(
      `users/${userId}/predictions/traitor`,
      data,
    );
  },
  postPrediction(
    this: RestApiService,
    userId: string,
    data: PredictionPostData,
  ) {
    return this.api.post<Prediction>(`users/${userId}/predictions`, data);
  },
  deletePrediction(
    this: RestApiService,
    userId: string,
    data: PredictionPostData,
  ) {
    return this.api.delete<Prediction>(`users/${userId}/predictions`, { data });
  },
};
