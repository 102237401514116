import * as React from 'react';

function SvgIcoArrowLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 16" fill="none" {...props}>
      <path d="M21.834 7.032H1.12v.869h20.713v-.869z" fill="currentColor" />
      <path
        fill="currentColor"
        d="M7.366 0L.002 7.567.6 8.18 7.963.614zM21.177 3.413l-3.769 3.873.598.614 3.769-3.872zM21.775 7.032l3.769 3.873-.598.614-3.769-3.873z"
      />
      <path
        fill="currentColor"
        d="M24.946 3.413l-3.769 3.873.598.614 3.769-3.872zM18.006 7.032l3.769 3.873-.598.614-3.769-3.873zM7.958 14.518L.598 6.955 0 7.569l7.36 7.563z"
      />
    </svg>
  );
}
const MemoSvgIcoArrowLeft = React.memo(SvgIcoArrowLeft);
export default MemoSvgIcoArrowLeft;
