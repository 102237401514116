import * as React from 'react';

function SvgIcoBanishIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M12 21.284a9.269 9.269 0 01-6.575-2.717c-3.624-3.626-3.624-9.523 0-13.149 3.627-3.624 9.523-3.624 13.15 0 3.624 3.626 3.624 9.523 0 13.15A9.278 9.278 0 0112 21.283zm0-16.52a7.212 7.212 0 00-5.114 2.113c-2.818 2.82-2.818 7.407 0 10.228 2.819 2.818 7.407 2.818 10.228 0 2.818-2.82 2.818-7.407 0-10.228A7.212 7.212 0 0012 4.763z"
        fill="currentColor"
      />
      <path
        d="M17.845 18.869c-.264 0-.528-.102-.731-.302L5.424 6.878a1.034 1.034 0 011.462-1.46l11.688 11.688a1.034 1.034 0 01-.729 1.763z"
        fill="currentColor"
      />
    </svg>
  );
}
const MemoSvgIcoBanishIcon = React.memo(SvgIcoBanishIcon);
export default MemoSvgIcoBanishIcon;
