import * as React from 'react';

function SvgIcoMurderInactive(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 44" fill="none" {...props}>
      <g filter="url(#ico-murder-inactive_svg__filter0_d_131_196481)">
        <path d="M2 .811h28v28l-14.5 11-13.5-11v-28z" fill="#F3F3F3" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.922 19.646v-1.549a5.103 5.103 0 10-5.84 0v1.55h5.84zm-6.063-5.037a1.246 1.246 0 112.49 0 1.246 1.246 0 01-2.49 0zm2.196 2.87a.412.412 0 11.824 0 .412.412 0 01-.824 0zm1.482.412a.412.412 0 110-.824.412.412 0 010 .824zm.117-3.282a1.245 1.245 0 112.49 0 1.245 1.245 0 01-2.49 0zm-7.049 5.872c0 .26.135.487.337.618a.734.734 0 00.4 1.353c.405 0 .735-.33.735-.736h9.851a.736.736 0 101.135-.617.735.735 0 10-1.135-.618h-9.851a.736.736 0 10-1.472 0z"
          fill="#D62E00"
        />
      </g>
      <defs>
        <filter
          id="ico-murder-inactive_svg__filter0_d_131_196481"
          x={0}
          y={0.811}
          width={32}
          height={43}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={1} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.667795 0 0 0 0 0.637751 0 0 0 0 0.590538 0 0 0 0.5 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_131_196481"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_131_196481"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
const MemoSvgIcoMurderInactive = React.memo(SvgIcoMurderInactive);
export default MemoSvgIcoMurderInactive;
