import { Box } from '@material-ui/core';
import _reject from 'lodash/reject';
import React from 'react';
import { useSelector } from 'react-redux';

import { getCurrentSelecting, getViewType } from 'store/game/game.selectors';
import { ViewType } from 'types/game.types';

import { useRenderState } from '../useRenderState';
import { Background, Wrap } from './BoardInfoPanel.style';

const BoardInfoPanel: React.VFC = () => {
  const viewType = useSelector(getViewType);
  const selecting = useSelector(getCurrentSelecting);
  const { component: renderStateComponent } = useRenderState();

  return (
    <Wrap>
      {selecting && viewType !== ViewType.MemberGame && <Background />}
      <Box position="relative" p="10% 10%" width="100%" height="100%">
        {renderStateComponent}
      </Box>
    </Wrap>
  );
};

export default BoardInfoPanel;
