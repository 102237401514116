import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import _isEqual from 'lodash/isEqual';
import { useCallback, useRef } from 'react';

import { isProd } from 'utils/env.utils';

import { EventAction } from 'constants/analytics';
import { useAuth } from 'queries';

interface Params {
  eventAction: EventAction;
  data?: {};
  once?: boolean;
}

interface PageViewParams {
  path: string;
  data?: {};
  once?: boolean;
}

export const useAnalytics = () => {
  const sendDataToGTM = useGTMDispatch();
  const { userId } = useAuth();
  const hasBeenCalled = useRef(false);
  const prevEventData = useRef<object | undefined>();

  const trackPageView = useCallback(
    ({ once = true }: PageViewParams) => {
      if ((once && !hasBeenCalled) || !once) {
        // setHasBeenCalled(true);
        // sendDataToGTM({
        //   event: 'PageView',
        //   userStatus: isLoggedIn ? 'loggedin' : 'anonymous',
        //   page: {
        //     id: '',
        //     title: 'De Verraders',
        //     pubDate: '',
        //     brand: 'vtm',
        //     language: 'nl',
        //     type: 'Interactive',
        //     subType: 'game',
        //     path,
        //   },
        //   program: 'De Verraders',
        //   ...data,
        // });
      }
    },
    [hasBeenCalled],
  );

  const trackEvent = useCallback(
    ({ eventAction, data = {}, once = true }: Params) => {
      const trackData = {
        event: 'Interactive Event',
        eventAction,
        eventLabel: 'de-verraders',
        eventCategory: eventAction.includes('vtm-go')
          ? 'vtm-go-clicks'
          : 'game',
        user: userId
          ? {
              account_id: userId,
            }
          : undefined,
        ...data,
      };

      if (
        (once && !hasBeenCalled.current) ||
        !once ||
        (prevEventData.current && !_isEqual(trackData, prevEventData.current))
      )
        prevEventData.current = trackData;

      if (!isProd()) {
        // eslint-disable-next-line no-console
        console.log(`Tracking event: ${eventAction}`);
      }

      hasBeenCalled.current = true;
      sendDataToGTM(trackData);
    },
    [sendDataToGTM, userId],
  );

  return {
    trackEvent,
    trackPageView,
  };
};
