import * as React from 'react';

function SvgIcoKing(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M12.61 14.323L16 7.315l3.39 7.008 8.376-6.812-3.716 14.374H7.95L4.233 7.51l8.376 6.812zM26.006 22.862H6.026v1.956h19.98v-1.956z"
        fill="currentColor"
      />
    </svg>
  );
}
const MemoSvgIcoKing = React.memo(SvgIcoKing);
export default MemoSvgIcoKing;
