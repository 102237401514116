import * as React from 'react';

function SvgIcoButtonArrowRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 23 24" fill="none" {...props}>
      <path d="M20.22 12.531h-17v-1.378h17v1.378z" fill="currentColor" />
      <path
        fill="currentColor"
        d="M13.57 3.459l8.478 8.477-.935.988-8.478-8.478zM1.215 8.103l3.834 3.833-.935.988L.28 9.09z"
      />
      <path
        fill="currentColor"
        d="M12.593 19.724l8.46-8.694.949.975-8.46 8.694zM.252 14.935l3.8-3.905.948.975-3.8 3.904z"
      />
    </svg>
  );
}
const MemoSvgIcoButtonArrowRight = React.memo(SvgIcoButtonArrowRight);
export default MemoSvgIcoButtonArrowRight;
