import { Box, Typography } from '@material-ui/core';
import React from 'react';
import styled, { css } from 'styled-components';

const Line = styled.div`
  background: linear-gradient(
    270deg,
    rgba(70, 70, 70, 0) 0%,
    #464646 18.75%,
    #464646 81.25%,
    rgba(70, 70, 70, 0) 100%
  );
  opacity: 0.3;
  height: 1px;
  width: 100%;
  margin-top: 12px;
`;

const Title = styled(Typography)<{ $wrap: boolean }>`
  ${({ $wrap }) =>
    !$wrap
      ? css`
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        `
      : undefined}

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 24px;
  }
`;

interface Props {
  wrap?: boolean;
}
const ModalTitle: React.FC<Props> = ({ children, wrap = false }) => (
  <Box textAlign="center" mx={18}>
    <Title variant="h3" $wrap={wrap}>
      {children}
    </Title>
    <Line />
  </Box>
);

export default ModalTitle;
